import React from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import {
  clearSession,
  clearUserSessionData,
  getSessionToken,
  getUserDetails,
} from "../../auth/sessionManager";
import { useDispatch } from "react-redux";
import { logoutUser } from "../actions/adminCommonActions";
import '../../adminstyles/sidebar.css'
import { isUserAdmin } from "../../auth/sessionManager";

const AdminSidebar = (props) => {
  const { window } = props;
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const drawerWidth = 240;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    // sessionStorage.removeItem('token');
    const user = getUserDetails();
    clearUserSessionData();
    clearSession();
    dispatch(logoutUser(user.userId));
    navigate("/admin/login");
  };

  const token = getSessionToken();
  const user = getUserDetails();
  const drawer = (
    <div>
      <nav
        className=" sidebar"
        // style={{position:'fixed', height: '-webkit-fill-available', marginTop:'fixed'}}
      >
        {token ? (
          <div className="sidebar-sticky">
            {/* <ul className="nav flex-column">
              <li className="user_details">
                Welcome, <br />
                <i className="fa fa-user"></i>{" "}
                <span className="sr-only">(current)</span>
                {user.firstName ? user.firstName : ""} |{" "}
                {user.userRole ? user.userRole : ""}
              </li>
            </ul> */}

            <ul className="nav flex-column">
              {/* <li>
                <hr className="dropdown-divider" />
              </li> */}
              <li className="nav-item">
                <NavLink to="add_new_content">
                  <i className="fa fa-plus-circle"></i>Add New Content
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="pending_approval_list">
                  <i className="fa fa-list"></i>Pending Approval List
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="published_content_list">
                  <i className="fa fa-book"></i>Published Content List
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {isUserAdmin() && (
                <>
                  <li className="nav-item">
                    <NavLink to="add_new_menu">
                      <i className="fa fa-plus-circle"></i>Add New Menu
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="manage_menu">
                      <i className="fa fa-edit"></i>Manage Menu
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="manage_header_images">
                      <i className="fa fa-edit"></i>Manage Header Images
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink to="manage_images">
                      <i className="fa fa-edit"></i>Manage Images
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="manage_footer_images">
                      <i className="fa fa-edit"></i>Manage Footer Images
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink to="manage_social_handle_links">
                      <i className="fa fa-edit"></i>Manage Social Media Links
                    </NavLink>
                  </li> */}
              
              </>
              )}
              <li>
                    <hr className="dropdown-divider" />
                  </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  <i className="fa fa-sign-out"></i>
                  Logout <span className="sr-only">(current)</span>
                </a>
              </li> */}
            </ul>
          </div>
        ) : (
          <div
            className="ad_login_sidebar"
          >
            {" "}
          </div>
        )}
      </nav>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Toolbar className="mg_top100" sx={{ display: { sm: "none" } }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <span className="nav-btn-bgimg navbar-toggler-icon"></span>
          CLICK
        </IconButton>
      </Toolbar>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Box>
      </Box>
    </>
  );
};

export default AdminSidebar;
