import { DateRange } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import { Button } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageToDisplay from "../../common/components/ImageToDisplay";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { createCommercials, updateCommercials } from "../actions/manageCommercialsActions";
import { CommercialImg } from "../../../common/constants/urlconst";
import SelectMultipleImages from "./SelectMultipleImages";

const AreaList = [
  "Header",
"Footer",
"Home",
"Banner",
"Social",
"Gallery"
]

const AddCommercials = ({ handleFormState,formState, selectedCommercialDetails, page }) => {
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [title, setTitle] = useState('');
  const [socialHandleUrl, setSocialHandleUrl] = useState('');
  const [area, setArea] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [allImageFiles, setAllImageFiles] = useState([]);

  const [errors, setErrors] = useState({});

  const [isTouched, setIsTouched] = useState({
    title: false,
    socialHandleUrl: false,
  });

  useEffect(()=>{
    // console.log(selectedCommercialDetails, formState)
    if(formState=="edit"){
      setArea(selectedCommercialDetails.area)
      // setSelectedImage(selectedCommercialDetails.name)
      setTitle(selectedCommercialDetails.title)
      setSocialHandleUrl(selectedCommercialDetails.url)
      setFromDate(selectedCommercialDetails.dateFrom? new Date(selectedCommercialDetails.dateFrom): '')
      setToDate(selectedCommercialDetails.dateTo ? new Date(selectedCommercialDetails.dateTo): '')
      setIsActive(selectedCommercialDetails.isActive)
    }
  },[]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name)
    setIsTouched((prevFormData) => ({
      ...prevFormData,
      [name]: true,
    }));
    setErrors((prevErrData) => ({
      ...prevErrData,
      [name]: true,
    }));
  };
  
  const isNotEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };

  const isEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const clearForm = () =>{
        setArea('');
        setSelectedImage('');
        setSocialHandleUrl('');
        setToDate('');
        setFromDate('');
        setTitle('');
        setIsActive(true);
  }

  const handleSubmit= (e) =>{
    e.preventDefault();
    const newErrors = {};
    if (isNotEmptyOrNull(area)&&area!='Gallery') {
      newErrors.area = true;
    }
    if ((!isNotEmptyOrNull(area)&& area=='Social') && isNotEmptyOrNull(socialHandleUrl)&& area!='Gallery') {
      newErrors.socialHandleUrl = true;
    }
    if (isNotEmptyOrNull(title)) {
      newErrors.title = true;
    }
    if ((selectedImage==''||selectedImage.length==0 )&& formState!='edit') {
      newErrors.image = true;
    }
    // console.log(selectedImage=='', selectedImage.length)
    setErrors(newErrors);
    let requestBody = {
      area:area,
      file: selectedImage,
      url: socialHandleUrl,
      dateFrom:fromDate,
      dateTo:toDate,
      title:title,
      // name:english
    }
    // console.log(requestBody)
    // console.log(newErrors)
    if (!Object.values(newErrors).includes(true)) {
      // console.log('submitted')
      let requestBody = {
        area:area,
        file: selectedImage,
        url: socialHandleUrl,
        dateFrom:fromDate,
        dateTo:toDate,
        title:title,
        // name:english
      }
      // console.log(requestBody)
      if(formState=='create'){
        let requestBody = {
          area:area,
          file: selectedImage ,
          url: socialHandleUrl,
          dateFrom:fromDate,
          dateTo:toDate,
          title:title,
          isActive: isActive,
          // name:english
        }
        // console.log('requestBody', requestBody)
        dispatch(createCommercials(requestBody, handleFormState,clearForm, page))

      }else if(formState =='edit'){
        let requestBody = {
          area:area,
          // file: selectedImage,
          url: socialHandleUrl,
          dateFrom:fromDate,
          dateTo:toDate,
          title:title,
          id:selectedCommercialDetails.id,
          isActive: isActive,
          // name:english
        }
        if(selectedImage){
          requestBody.file=selectedImage;
        }
        dispatch(updateCommercials(requestBody, handleFormState,clearForm, page))
      }
    }else {
      toastr.warning("Please enter all required fields.");
    }
  }

  return (
    <div>
      <h2>{`${formState == "edit"? 'Update':'Add' }`} Images</h2>
      <div className="row">
        <div className="col-3">
          <label>Area</label><span className="text-danger">*</span>
          <select 
            className="form-control"
            name="area"
            value={area}
            onChange={(e)=>{handleChange(e);setArea(e.target.value)}}
           >
            <option value={''}>Select</option>
            {AreaList.map((area, index)=>{
                return <option key={index} value={area}>{area}</option>
            })}
            {/* <option>Header</option>
            <option>Footer</option>
            <option>Home</option>
            <option>Banner</option>
            <option>Social</option> */}
          </select>
          {errors.area && isEmptyOrNull(area) && (
              <span className="text-danger">Field is Required</span>
            )}
        </div>
        {/* {area!="Gallery" &&<> */}
        <div className="col-3">
          <label>Title</label><span className="text-danger">*</span>
          <input name="title" value={title} onChange={(e)=>{handleChange(e);setTitle(e.target.value)}} className="form-control" type="text" />
          {errors.title && isEmptyOrNull(title) && (
              <span className="text-danger">Field is Required</span>
            )}
        </div>
        <div className="col-3">
          <label>From Date</label>     
          <ReactDatePicker
            showIcon={true}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            dropdownMode="select"
            customInput={<input
                className={`form-control`}
                id="dateOfPublication"
                name="dateOfPublication"
                placeholder="DD-MM-YYYY"
                minLength="10"
                maxLength="10"
              />
            }
          />{" "}
          
        </div>
        
        <div className="col-3">
          <label>To Date</label>
          <ReactDatePicker
            showIcon
            selected={toDate}
            onChange={(date) => setToDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            dropdownMode="select"
            customInput={
              <input
                className={`form-control`}
                id="dateOfPublication"
                name="dateOfPublication"
                placeholder="DD-MM-YYYY"
                minLength="10"
                maxLength="10"
              />
            }
          />{" "}
        </div>
          {/* </>} */}
        
        
        </div>
        {(area!="Gallery" || formState=="edit") && <div className="row" style={{marginTop:"5px"}}>
        {formState=='edit' &&<div className="col-3">
          <div><label>Previous Image</label></div>
          <div>
             <img style={{width: "100%", height: "100%", color: "black", backgroundColor:'black'}} src={`${CommercialImg}${selectedCommercialDetails&& selectedCommercialDetails.id&& selectedCommercialDetails.id}`} /> 
          </div>
        </div>}
        <div className="col-3">
          <div><label>{formState=='edit' && "Select New "}Image</label>{formState!='edit' && <span className="text-danger">*</span>}</div>
          <div>
            <ImageToDisplay selectedImage = {selectedImage} setSelectedImage = {setSelectedImage} setErrors={setErrors} />
            {errors.image && (
              <span className="text-danger">Please select image file</span>
            )}
          </div>
        </div>
        <div className="col-3">
          <label>URL</label>{area =="Social" && <span className="text-danger">*</span>}
          <input 
            name="socialHandleUrl" 
            value={socialHandleUrl} 
            onChange={(e)=>{
              const inputValue = e.target.value;
              setSocialHandleUrl(inputValue);
            }} 
            onBlur={(e)=>{
              handleChange(e);
              const inputValue = e.target.value;
              const pattern = /\b(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]/g;
              const extractedURLs = inputValue.match(pattern) || '';
              // console.log(extractedURLs)
              if(extractedURLs && extractedURLs.length>0){
                setSocialHandleUrl(extractedURLs[0]);
              }else{
                toastr.warning('Please enter valid url')
                setSocialHandleUrl(extractedURLs);
              }

            }} className="form-control" type="text" />
          {errors.socialHandleUrl && isEmptyOrNull(socialHandleUrl) && area =="Social" && (
              <span className="text-danger">Field is Required</span>
            )}
            {errors.socialHandleUrlwrong && isEmptyOrNull(socialHandleUrl) && (
              <span className="text-danger">Please entre valid url</span>
            )}
        </div>
        <div className="col-1">
          <label>Active</label>
          <Checkbox checked={isActive} onChange={(e)=>setIsActive(e.target.checked)} />
        </div>
    </div>}
    {formState!="edit" && area=="Gallery" &&  <div className="row" style={{marginTop:"5px"}}>
      <div className="col-3">
        <label>Select Imges</label>
        <span className="text-danger">*</span>
      </div>
          <SelectMultipleImages allImageFiles={selectedImage} setAllImageFiles={setSelectedImage} setErrors={setErrors}/>
          {errors.image && (
                <span className="text-danger">Please select at least one image</span>
              )}
    </div>}
    <div className="row">
        <div className="col">
          <Button variant="danger" style={{float:'right', marginLeft: "5px", marginTop:"2rem"}} size="sm" onClick={() => handleFormState("list")}>
            Cancel
          </Button>
          <Button style={{ float:'right', marginTop:"2rem" }} onClick={handleSubmit} size="sm" type="submit">
            {formState=="edit"? "Update": "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddCommercials;
