import React, { useEffect, useState } from 'react';

// import AppMenuList from "../../common/components/AppMenuList";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { toInteger } from "lodash";
import {
  getMenuListByMid,
  getSubMenuListByMid,
} from "../../AddNewContent/actions/addNewContentActions";
import { ReactTransliterate } from 'react-transliterate';
import FormDemoModel from '../../common/components/FormDemoModel';
import { addNewMenuData, editMenuData, getFormFields, getFormTypeList } from '../../AddMenu/actions/addMenuActions';
import { toastr } from 'react-redux-toastr';


const EditMenu = ({ handleFormState, componentType, selectedMenuDetails, page }) => {

  const dispatch = useDispatch();
  const { mainMenuList, subMenuList, subMenuList2 } = useSelector(
    (state) => state.addNewContent,
    shallowEqual
  );
  const { menuFormFields, menuFormTypeList } = useSelector(
    (state) => state.addMenuReducer,
    shallowEqual
  );
  const { menuDetails } = useSelector(
    (state) => state.manageMenuReducer,
    shallowEqual
  );

  const [reload, setReload] = useState(false)
  const [mainMenuValue, setMainMenuValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");
  const [subMenuValue2, setSubMenuValue2] = useState("");
  const [formTitleName, setFormTitleName] = useState("");

  const [descInEnglish, setDescInEnglish] = useState("")
  const [descInMarathi, setDescInMarathi] = useState("")
  const [descInHindi, setDescInHindi] = useState("")
  const [selectedFormContent, setSelectedFormContent] = useState("")

  let formOptions = [
    "common_format_content",
    "office_bearer_content",
    "results-content",
    "common_format2_content"
  ]
  const [tableQualumnList, setTableQualumnList] = useState([])
  const [copyMenuFormFields, setCopyMenuFormFields] = useState([])

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "common_format_content"
  );
  const [showFormModel, setShowFormModel] = useState(false)
  const [selectedMid, setSelectedMid] = useState("")
  const [hello, setHello] = useState("")
  const [isUnderConstruction, setIsUnderConstruction] = useState(false);
  const [showInWebsite, setShowInWebsite] = useState(false);
  const [showInHeader, setShowInHeader] = useState(false);
  const [showColumn, setShowColumn] = useState([]);
  const [selectedMenuItemId, setSelectedMenuItemID] = useState(null);
  const [isMainMenu, setIsMainMenu] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [errors, setErrors] = useState({});

  const isNotEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const isEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const handleMainMenu = (e) => {
    setMainMenuValue(e.target.value);
    const getComponent =
      mainMenuList && mainMenuList.length > 0
        ? mainMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue("");
    setSubMenuValue2("");
    dispatch(getSubMenuListByMid(toInteger(e.target.value)));
  };

  const handleSubMenu = (e) => {
    setSubMenuValue(e.target.value);
    const getComponent =
      subMenuList && subMenuList.length > 0
        ? subMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2("");
    // dispatch(getSubMenuList2ByMid(toInteger(e.target.value)));
  };

  const handleSubMenu2 = (e) => {
    const getComponent =
      subMenuList2 && subMenuList2.length > 0
        ? subMenuList2.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2(e.target.value);
  };

  const handleFormtypeSelect = (e) => {
    setErrors((prevErrData) => ({
      ...prevErrData,
      [e.target.name]: true,
    }));
    setSelectedFormContent(e.target.value)

    dispatch(getFormFields(e.target.value));
  }

  const handleDescMarathiChange = (fieldName, newDisplayName) => {
    const updatedRowList = tableQualumnList.length > 0 ? tableQualumnList.map(row => {
      if (row.fieldName === fieldName) {
        return {
          ...row,
          displayNameMr: newDisplayName,
        };
      }
      return row;
    }) : []

    setTableQualumnList(updatedRowList);

  }

  const handleDescEnglishChange = (fieldName, newDisplayName) => {
    // Update the editedRowList state with the new display name
    const updatedRowList = tableQualumnList.length > 0 ? tableQualumnList.map(row => {
      if (row.fieldName === fieldName) {
        return {
          ...row,
          displayName: newDisplayName,
        };
      }
      return row;
    }) : []

    setTableQualumnList(updatedRowList);
  };

  const handleCancel = () => {
    setReload(prev => !prev);
    setSelectedFormContent("")
    setMainMenuValue("");
    setSubMenuValue("");
    setSubMenuValue2("");
    setShowInHeader(false);
    setShowInWebsite(false);
    setIsUnderConstruction(false);
    setDescInEnglish("")
    setDescInMarathi("")
    setDescInHindi("")
  }


  const submitMenuEdit = () => {

    const newErrors = {};
    // if (isNotEmptyOrNull(selectedFormContent)) {
    //   newErrors.formType = true;
    // }
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }
    // if (isNotEmptyOrNull(publicationDate)) {
    //   newErrors.publicationDate = true;
    // }
    // if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
    //   newErrors.fileExpDate = true;
    // }
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      let editedColumn = tableQualumnList.length > 0 ? tableQualumnList.filter((col, index) => {
        if (showColumn[index]) {
          return col
        }
      }) : []

      let ec = editedColumn.length > 0 ? editedColumn.map((ecol, index) => {
        return { ...ecol, order: index + 1 }
      }) : []
      // if(editedColumn.length >0){
      let payload = {
        mid: selectedMid,
        name: descInEnglish,
        nameMr: descInMarathi,
        nameHin: descInHindi,
        hello: hello,
        // parentId: !isMainMenu? selectedMenuItemId : null,
        parentId: menuDetails.parentId ? menuDetails.parentId : null,
        showInHeader,
        showInWebsite,
        underConstruction: isUnderConstruction,
        showInAdminPortal: true,
        isActive: isActive,
        // component: selectedFormContent,
        fields: [
          ...ec
        ]
      }

      dispatch(editMenuData(payload, page))
      setTableQualumnList([]);
      handleFormState('new')
      // handleCancel();
      // }else{
      //   toastr.warning("Please select minimum 1 column.");
      // }

    } else {
      toastr.warning("Please enter all required fields.");
    }

  }
  const moveRow = (index, direction) => {
    const newPosition = index + direction;

    if (newPosition >= 0 && newPosition < copyMenuFormFields.length) {
      const updatedData = [...copyMenuFormFields];
      const [movedRow] = updatedData.splice(index, 1);
      updatedData.splice(newPosition, 0, movedRow);
      setCopyMenuFormFields(updatedData);
    }
    if (newPosition >= 0 && newPosition < tableQualumnList.length) {
      const updatedDatat = [...tableQualumnList];
      const [movedRow] = updatedDatat.splice(index, 1);
      updatedDatat.splice(newPosition, 0, movedRow);
      setTableQualumnList(updatedDatat);
    }
  };



  useEffect(() => {
    setTableQualumnList(menuFormFields)
    setCopyMenuFormFields(menuFormFields)
  }, [menuFormFields])

  useEffect(() => {
    if (tableQualumnList.length > 0) {
      setShowColumn(Array(tableQualumnList.length).fill(true));
    }
  }, [tableQualumnList.length])

  useEffect(() => {
    dispatch(getMenuListByMid());
    dispatch(getFormTypeList());
    window.scroll(0, 0)
    if (tableQualumnList.length > 0) {
      setShowColumn(Array(tableQualumnList.length).fill(true));
    }
    componentType && setSelectedFormContent(componentType)


  }, [dispatch, reload]);

  useEffect(() => {
    if (menuDetails) {
      setSelectedMid(menuDetails.mid)
      setHello(menuDetails.hello)
      setCopyMenuFormFields(menuDetails.fields?.length > 0 ? menuDetails.fields : []);
      setTableQualumnList(menuDetails.fields?.length > 0 ? menuDetails.fields : []);
      setDescInEnglish(menuDetails.name);
      setDescInMarathi(menuDetails.nameMr);
      setDescInHindi(menuDetails.nameHin)
      setIsUnderConstruction(menuDetails.underConstruction);
      setShowInHeader(menuDetails.showInHeader)
      setShowInWebsite(menuDetails.showInWebsite)
      setIsActive(menuDetails.isActive ? true : false)
      menuDetails.parentId === null ? setIsMainMenu(true) : setIsMainMenu(false)
      setShowColumn(Array(tableQualumnList.length).fill(true));
    }
  }, [menuDetails])

  return (
    <div>
      {/* <div className="card"> */}
      {/* <div className="card-header">
          <strong>Add New Menu</strong>
        </div> */}
      <div>
        <div>{selectedMenuDetails.parentName ? selectedMenuDetails.parentName + ' > ' : ''}  {selectedMenuDetails.mainParentName ? selectedMenuDetails.mainParentName + " > " : ''} {selectedMenuDetails.name}    </div>
      </div>
      {/* <div><TreePath/></div> */}
      <div className="card-body">
        {/* <div className="row">
            <div className="col-2">Add as main menu: </div>
            <div className="col">
              <input
                id="showInWebsite"
                name="showInWebsite"
                checked={isMainMenu}
                onChange={(e) => setIsMainMenu(e.target.checked)}
                type="checkbox"
              />
            </div>
          </div>
          {!isMainMenu && "OR"}
          {!isMainMenu &&<form className='card p-2' noValidate>
            <div className="form-row">
                <div className="col-sm-6 form-group">
                <label htmlFor="select-main-menu">Select Main Menu</label>
                <select
                    className="form-control"
                    id="select-main-menu"
                    name="select-main-menu"
                    placeholder="choose one"
                    required
                    value={mainMenuValue}
                    onChange={handleMainMenu}
                >
                    <option value={""}>Choose one...</option>
                    {mainMenuList &&
                    mainMenuList.length > 0 &&
                    mainMenuList.map((menu, index) => {
                        return (
                        <option key={index} value={menu.mid}>
                            {menu.name}
                        </option>
                        );
                    })}
                </select>
                </div>
                </div>
            <div className="form-row">
                <div className="col-sm-6 form-group">
                {mainMenuValue &&
                    mainMenuValue !== "" &&
                    subMenuList &&
                    subMenuList.length > 0 && (
                    <div>
                        <label htmlFor="select-sub-menu">Select Menu</label>
                        <select
                        className="form-control"
                        id="select-sub-menu"
                        name="select-sub-menu"
                        placeholder="choose one"
                        required
                        value={subMenuValue}
                        onChange={handleSubMenu}
                        >
                        <option value={""}>Choose one...</option>
                        {subMenuList &&
                            subMenuList.length > 0 &&
                            subMenuList.map((menu, index) => {
                            return (
                                <option key={index} value={menu.mid}>
                                {menu.name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                    )}
                </div>
            </div>
            </form>}
          <hr className="dropdown-divider" /> */}

        {/* <div className='row'>
            <div className='col-3  p-2'>
              <label  >Select Form Type </label><span className="text-danger">*</span>:
            </div>
            <div className='col-4 pl-2 pr-2' >  
              <select 
                className="form-control"
                id="formType"
                name="formType"
                placeholder="Choose form type"
                onChange={handleFormtypeSelect}
                value={selectedFormContent}
                onBlur={()=>
                  setErrors((prevErrData) => ({
                      ...prevErrData,
                      formType: true,
                    })
                  )
                }
              >
                <option  value={""}> Choose form type</option>
                {menuFormTypeList.length>0 && menuFormTypeList.map((op, index)=>{
                  return <option key={index} value={op}> {op}</option>
                })}
              </select>
              {errors.formType && isEmptyOrNull(selectedFormContent) && (
              <span className="text-danger">Field is Required</span>
            )}
            </div >
            <div className='col' >
              <button 
                className="btn btn-primary" 
                onClick={()=>{ setShowFormModel(true)}}
              >
              View form
              </button>
            </div >

          </div> */}

        <div className="row">
          <div className="col-3">Show in Website : </div>
          <div className="col">
            <input
              id="showInWebsite"
              name="showInWebsite"
              checked={showInWebsite}
              onChange={(e) => setShowInWebsite(e.target.checked)}
              type="checkbox"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">Show in Header :</div>
          <div className="col">
            <input
              id="showInHeader"
              name="showInHeader"
              checked={showInHeader}
              onChange={(e) => setShowInHeader(e.target.checked)}
              type="checkbox"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">Under Construction :</div>
          <div className="col">
            <input
              id="isUnderConstruction"
              name="isUnderConstruction"
              checked={isUnderConstruction}
              onChange={(e) => setIsUnderConstruction(e.target.checked)}
              type="checkbox"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">Active :</div>
          <div className="col">
            <input
              id="isActive"
              name="isActive"
              type="checkbox"
              onChange={(e) => setIsActive(e.target.checked)}
              checked={isActive}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            <label className="pt-3" >Menu Name (English) </label><span className="text-danger">*</span>:
          </div>
          <div className='col-4 p-2'>
            <input
              className="form-control"
              placeholder='Enter menu name'
              value={descInEnglish}
              onChange={(e) => {
                setDescInEnglish(e.target.value);
              }}
              onBlur={() =>
                setErrors((prevErrData) => ({
                  ...prevErrData,
                  descInEnglish: true,
                })
                )
              }
            ></input>
            {errors.descInEnglish && isEmptyOrNull(descInEnglish) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            <label className="pt-3" >Menu Name (Marathi)</label><span className="text-danger">*</span>:
          </div>
          <div className='col-4 p-2'>
            <ReactTransliterate
              value={descInMarathi}
              name="descInMarathi"
              onChangeText={(e) => {
                setDescInMarathi(e);
              }}
              onBlur={() =>
                setErrors((prevErrData) => ({
                  ...prevErrData,
                  descInMarathi: true,
                })
                )
              }
              lang="mr"
              className={`form-control`}
              placeholder='Enter menu name'
            />
            {errors.descInMarathi && isEmptyOrNull(descInMarathi) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            <label className="pt-3" >Menu Name (Hindi)</label><span className="text-danger">*</span>:
          </div>
          <div className='col-4 p-2'>
            <ReactTransliterate
              value={descInHindi}
              name="descInHindi"
              onChangeText={(e) => {
                setDescInHindi(e);
              }}
              onBlur={() =>
                setErrors((prevErrData) => ({
                  ...prevErrData,
                  descInHindi: true,
                })
                )
              }
              lang="mr"
              className={`form-control`}
              placeholder='Enter menu name'
            />
            {errors.descInHindi && isEmptyOrNull(descInHindi) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        {tableQualumnList.length > 0 && <>
          <label className='pt-2'>Select and adjust Table Column name ( Order of column is top to bottom ) :  </label><br />
          <div className="table-responsive">
            <table className="table dataTable" id="datatable">
              <thead className=' font-weight-bold'>
                <tr>
                  <td>Show/Hide</td>
                  {/* <td>Order</td> */}
                  <td>Previous Name(English)</td>
                  <td>New Name</td>
                  <td>Previous Name(Marathi)</td>
                  <td>New Name</td>
                  {/* <td>Adjust Order</td> */}
                </tr>
              </thead>
              <tbody>
                {tableQualumnList.length > 0 && tableQualumnList.map((qualumn, index) => {
                  return <tr key={index}>

                    <td>
                      <input
                        id={`check${qualumn.fieldName}`}
                        name={`check${qualumn.fieldName}`}
                        className='mt-3 ml-4'
                        checked={showColumn[index]}
                        onChange={(e) => setShowColumn(prev => {
                          prev[index] = e.target.checked
                          return [...prev]
                        })}
                        type="checkbox"
                      />
                    </td>
                    {/* {console.log(showColumn[index])}
              <td > <div className='mt-2'>{index +" "+ showColumn[index]}</div></td> */}
                    <td className=' pt-4'>
                      {copyMenuFormFields.length > 0 && copyMenuFormFields[index] && copyMenuFormFields[index].displayName}
                    </td>
                    <td>
                      <input disabled={!showColumn[index]}
                        value={qualumn.displayName}
                        onChange={(e) => handleDescEnglishChange(qualumn.fieldName, e.target.value)}
                        className="form-control mt-2"
                      // defaultValue={qualumn.displayName}
                      ></input>
                    </td>
                    <td className=' pt-4'>
                      {copyMenuFormFields.length > 0 && copyMenuFormFields[index] && copyMenuFormFields[index].displayNameMr}
                    </td>
                    <td>
                      <ReactTransliterate
                        disabled={!showColumn[index]}
                        value={qualumn.displayNameMr}
                        onChangeText={(e) => {
                          handleDescMarathiChange(qualumn.fieldName, e)
                        }}
                        lang="mr"
                        className={`form-control `}
                      />
                    </td>
                    {/* <td>
                <Button variant="contained" onClick={() => moveRow(index, -1)}><i className="fa fa-arrow-up"
                                                            mtitle="Move Up"></i></Button>
                <Button variant="contained" onClick={() => moveRow(index, 1)}><i className="fa fa-arrow-down"
                                                             title="Move Down"></i></Button>
              </td> */}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </>}


        {/* {tableQualumnList.length>0 && tableQualumnList.map((qualumn, index)=>{
            return <div key={index} className='row'>
                    <div className='col-1'>
                      <input
                        id={`check${qualumn.fieldName}`}
                        name={`check${qualumn.fieldName}`}
                        className='mt-4 ml-4'
                        checked={showColumn[index]}
                        onChange={(e) => setShowColumn(prev=>{ 
                          prev[index]=e.target.checked
                          return [...prev]
                        })}
                        type="checkbox"
                      />
                    </div>
                    <div className='col-2'>
                      <label className="pt-3" >{qualumn.displayName}: </label>
                    </div>
                    <div className='col-3 p-2'>
                      <input disabled={!showColumn[index]}
                       value={qualumn.displayName}
                       onChange={(e) => handleDescEnglishChange(qualumn.fieldName, e.target.value)}
                      className="form-control"  defaultValue={qualumn.displayName}></input>
                    </div>
                    <div className='col-2'>
                      <label className="pt-3" >{qualumn.displayNameMr}: </label>
                    </div>
                    <div className='col-3 p-2'>
                      <ReactTransliterate
                        disabled={!showColumn[index]}
                        value={qualumn.displayNameMr}
                        onChangeText={(e) => {
                          handleDescMarathiChange(qualumn.fieldName, e)
                        }}
                        lang="mr"
                        className={`form-control`}
                      />
                    </div>

                  </div>
          })} */}









        <div className="">
          <div className="float-right ">
            <>
              <button
                className="btn btn-primary "
                onClick={() => handleFormState('new')}
              >
                <i className="fa fa-check-circle">&nbsp;</i>Cancel
              </button> &nbsp;{" "}
              <button
                className="btn btn-primary float-right"
                onClick={submitMenuEdit}
              >
                <i className="fa fa-check-circle">&nbsp;</i>Update Menu
              </button>
              &nbsp;{" "}
            </>
          </div>
        </div>
      </div>
      {/* </div> */}
      <p></p>
      { }
      {/* {noMenuSelectedError !== "" && (
        <div className="alert alert-warning" role="alert">
          {noMenuSelectedError}
        </div>
      )} */}

      <p></p>
      <FormDemoModel showModal={showFormModel} setShowModal={setShowFormModel} selectedFormContent={selectedFormContent}></FormDemoModel>
    </div>
  )
}

export default EditMenu