import React, { useState, useEffect, Suspense, lazy } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import ApplicationHeader from "./ApplicationHeader";
import Loader from "./Loader";
import ApplicationFooter from "./ApplicationFooter";
import { getMenuItems } from "../actions/commonActions";
import { getAllMainContentData } from "../../maincontent/actions/mainContentActions";
import { getHomePageData } from "../actions/commonActions";

const Home = lazy(() => import("../../home/components/Home"));
const ImageGallery = lazy(() => import("../../home/components/ImageGallery"));
const MainContent = lazy(() =>
  import("../../maincontent/components/MainContent")
);
const NotFound = lazy(() => import("./NotFound"));

const AppLayout = (props) => {
  const { allMenuItems } = useSelector((state) => state.common, shallowEqual);
 
  const [allRoutes, setAllRoutes] = useState([]);

  // window.addEventListener("popstate", (e) => {
  //   props.history.go(1);
  // });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMenuItems());
    dispatch(getHomePageData());
    dispatch(getAllMainContentData());
  }, [dispatch]);
  useEffect(() => {
    if (allMenuItems.headers) {
      let nestCombine = allMenuItems.headers
        .map((ami) => {
          return ami.submenus.length > 0 ? ami.submenus : ami.route;
        })
        .flat()
        .map((nc) => {
          if (nc.submenus) {
            return nc.submenus.length > 0
              ? nc.submenus.map((amr) => (amr.route ? amr.route : amr))
              : nc.route;
          } else {
            return nc;
          }
        })
        .flat();

      let nestFooter = allMenuItems.footers
        .map((ami) => {
          return ami.submenus.length > 0 ? ami.submenus : ami.route;
        })
        .flat()
        .map((nc) => {
          if (nc.submenus) {
            return nc.submenus.length > 0
              ? nc.submenus.map((amr) => (amr.route ? amr.route : amr))
              : nc.route;
          } else {
            return nc;
          }
        })
        .flat();

      setAllRoutes([...nestCombine, ...nestFooter]);
    }
  }, [allMenuItems]);

  function Logout() {
    // Perform any necessary logout actions
  
    // Redirect to the login page
    return <Navigate to="/home" />;
  }

  return (
    <>
      <ApplicationHeader {...props} />
      <Suspense fallback={<Loader />}>
        <Routes>
          {allRoutes.length > 0 &&
            allRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={`/${route}/:mid`}
                  element={<MainContent/>}
                />
              );
            })}
            {/* <Route
                  key={2}
                  path={`/contact_us/69`}
                  element={<MainContent/>}
                /> */}
          <Route path="/" element={<Logout />} />
          <Route path="/home" element={<Home/>} />
          <Route path="/gallery" element={<ImageGallery/>} />
          {/* <Route element={NotFound} /> */}
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </Suspense>
      <ApplicationFooter />
      <Loader />
    </>
  );
};

export default AppLayout;
