import * as React from 'react';
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { isUserCreator } from '../../auth/sessionManager';
import { Switch } from '@mui/material';
import { advtActivateDeactivate } from '../../AddMenu/actions/addMenuActions';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {

    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {/* {theme.direction === 'rtl' ? 'last' : 'first'} */}
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {/* {theme.direction === 'rtl' ? "kright" : "kleft"} */}
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



export default function CustomPaginationActionsTable({tableData, totalDataLength, page, handleChangePage, handleFormState, isPendingTable=false, getAllPendingApprovalList}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleIsActiveChange = (isActive, id) =>{
    dispatch(advtActivateDeactivate({isActive:isActive,id:id}, getAllPendingApprovalList, page))
  }

  return (
    <TableContainer component={Paper}>
      <Table  aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sr. No. </StyledTableCell>
            <StyledTableCell>Publcation Date </StyledTableCell>
            <StyledTableCell >Description(English)</StyledTableCell>
            <StyledTableCell >Description(Marathi)</StyledTableCell>
            <StyledTableCell >Description(Hindi)</StyledTableCell>
            {!isPendingTable &&<StyledTableCell align="center">Active</StyledTableCell>}
            {isUserCreator() && isPendingTable && <StyledTableCell >Status</StyledTableCell>}
            <StyledTableCell  style={{ width: 50 }} align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows */}
          {/* {(rowsPerPage > 0
            ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tableData
            ).map((row, index) => ( */}
            {tableData.length>0 && tableData.map((row, index) => (
            
             <StyledTableRow key={index}>
              <TableCell style={{ width: 50 }} component="th" scope="row">
                {(page*10)+index+1}
              </TableCell>
              <TableCell style={{ width: 50 }} component="th" scope="row">
                {row.publicationDate && moment(row.publicationDate).format('DD-MM-YYYY') }
              </TableCell>
              <TableCell style={{ width: 200 }} align="left">
                {row.descInEnglish}
              </TableCell>
              <TableCell style={{ width: 200 }} align="left">
                {row.descMr}
              </TableCell>
              <TableCell style={{ width: 200 }} align="left">
                {row.descHin}
              </TableCell>
              {!isPendingTable &&<TableCell style={{ width: 50 }} align="center">
                <Switch defaultChecked={row.isActive} onChange={(e)=>{handleIsActiveChange(e.target.checked, row.id)}}/>
              </TableCell>}
              {isUserCreator() && isPendingTable &&  <TableCell style={{ width: 200 }} align="left">
                {row.status}
              </TableCell>}
              <TableCell  style={{ width: 50 }} align="right">
                {/* <button onClick={()=>handleFormState('editing', row.id)}>Edit</button> */}
                <div className="text-center" onClick={()=>handleFormState('editing', row.id)} style={{color:'#007bff', cursor:'pointer'}}>Open <i className="fa fa-edit"></i></div>
              </TableCell>
            </StyledTableRow>
          ))}
          {tableData.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} >
               <div className="text-center alert alert-warning" role="alert">
               No data found
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={5}
              count={totalDataLength||0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}