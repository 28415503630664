import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import "../../styles/application_header.css";
import HomePageNav from "./HomePageNav";
import india from "../../images/India.png";
import mGandhi from "../../images/Gandhi150.png";
import nvsp from "../../images/NVSP.png";
import mii from "../../images/mii.png";
import bharat from "../../images/swachBharatlogo.png"
import home from "../../images/home.svg";
import logo from "../../images/logo.png";
import header from "../../images/header.png";
import lion from "../../images/lion.svg";
import xIcon from "../../images/twitter-x-logo-png-white.png";
import { Link } from "react-router-dom";
import { SET_TTS_STATUS, SET_WEB_LANGUAGE } from "../constants/ActionTypes";
import { getLabelByLang, getMenuNameByLang } from "../../helpers/multiLingual";
import { SITE_MAP, A_CHARACTER, SEARCH, SCREEN_READER_ACCESS, SRC_ENABLED, SRC_DISABLED, GALLERY } from '../labels/header.json'
import { MenuNameByLang, LabelByLang } from "./ConvertTextLang";
import { useEffect } from "react";
import { handleStop, handlelSpeak } from "../../helpers/TTS";
import { ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextToSpeech from "./TextToSpeech";
import Carousel from 'react-bootstrap/Carousel';
import { grey } from "@mui/material/colors";
import { CommercialImg } from "../constants/urlconst";
const NavSubmenu = ({
  MenuItem,
  sendKey,
  isOnHover,
  setIsMainNavClicked,
  setIsCollapsed,
}) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  const [isSubOnHover, setIsSubOnHover] = useState([]);
  const [isSubCheck, setIsSubCheck] = useState([]);

  const isExternalLink = (url) => {
    const tmp = document.createElement("a");
    tmp.href = url;
    return tmp.host !== window.location.host;
  };
  return (
    <ul key={sendKey} className={`submenuwrap ${isOnHover ? " open" : ""}`}>
      {MenuItem.map((subitem, index) => {
        return (
          <li
            key={subitem.mid}
            className={
              `${isSubCheck[index] ? "subcheck open" : ""}` +
              `${subitem.submenus.length > 0 && isOnHover ? " open" : ""}` +
              ` ${subitem.submenus.length > 0 ? "has-submenu dropdown" : ""}`
            }
          >
            {subitem.submenus.length > 0 ? (
              <a
                href="true"
                onClick={(e) => {
                  e.preventDefault()
                  let isOnHoverCp = [...isSubOnHover];
                  isOnHoverCp[index] = !isOnHoverCp[index];
                  setIsSubOnHover(isOnHoverCp);
                }}
                onMouseOver={() => {
                  let isOnHoverCp = [...isSubCheck];
                  isOnHoverCp[index] = true;
                  setIsSubCheck(isOnHoverCp);
                  // handlelSpeak(subitem.name)
                }}
              // onMouseLeave={handleStop}
              >
                {/* {getMenuNameByLang(subitem, webLanguage)} */}
                <TextToSpeech text={subitem.name}> <MenuNameByLang obj={subitem} /></TextToSpeech>
              </a>
            ) : isExternalLink(subitem.route) ? (
              <a href={subitem.route} target="_blank" rel="noopener noreferrer"
              // onMouseOver={()=>handlelSpeak(subitem.name)}
              // onMouseLeave={handleStop}
              >
                <TextToSpeech text={subitem.name}> <MenuNameByLang obj={subitem} />{" "}</TextToSpeech>
              </a>
            ) : (
              <Link
                to={`/${subitem.route}/${subitem.mid}`}
                onClick={() => {
                  setIsMainNavClicked((prev) => !prev);
                  setIsCollapsed((prev) => !prev);
                }}
              // onMouseOver={()=>handlelSpeak(subitem.name)}
              // onMouseLeave={handleStop}
              >
                {/* {getMenuNameByLang(subitem, webLanguage)} */}
                <TextToSpeech text={subitem.name}><MenuNameByLang obj={subitem} /></TextToSpeech>
              </Link>
            )}

            {subitem.submenus.length > 0 && (
              <NavSubmenu
                sendKey={subitem.mid}
                MenuItem={subitem.submenus}
                isOnHover={isSubOnHover[index]}
                setIsMainNavClicked={setIsMainNavClicked}
                setIsCollapsed={setIsCollapsed}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const HeaderNavigations = ({ setIsCollapsed }) => {
  const { allMenuItems, webLanguage } = useSelector((state) => state.common, shallowEqual);

  const [isOnHover, setIsOnHover] = useState([]);
  const [isMainNavClicked, setIsMainNavClicked] = useState(false);
  const isExternalLink = (url) => {
    const tmp = document.createElement("a");
    tmp.href = url;
    return tmp.host !== window.location.host;
  };

  return (
    <>
      {allMenuItems.headers &&
        allMenuItems.headers.length > 0 &&
        allMenuItems.headers.map((navItem, index) => {
          return (
            <li
              key={index}
              className={
                `${isMainNavClicked ? "check " : ""}` +
                "" +
                `${isOnHover[index] ? "open" : ""}` +
                ` ${navItem.submenus.length > 0 ? `has-submenu` : ""}`
              }
            // onMouseOver={()=>handlelSpeak(navItem.name)

            // }
            // onMouseLeave={handleStop}
            >
              {navItem.submenus.length === 0 ? (
                isExternalLink(navItem.route) ? (
                  <a
                    href={navItem.route}
                    target="_blank"
                    rel="noopener noreferrer"
                  // onMouseOver={()=>handlelSpeak(navItem.name)

                  // }
                  // onMouseLeave={handleStop}
                  >
                    {/* {getMenuNameByLang(navItem, webLanguage)}{" "} */}
                    <TextToSpeech text={navItem.name}><MenuNameByLang obj={navItem} /></TextToSpeech>
                  </a>
                ) : (
                  <Link
                    to={`/${navItem.route}/${navItem.mid}`}
                    onClick={() => {
                      setIsMainNavClicked(false);
                      setIsCollapsed(false);
                    }}
                  // onMouseOver={()=>handlelSpeak(navItem.name)
                  // }
                  // onMouseLeave={handleStop}
                  >
                    {/* {getMenuNameByLang(navItem, webLanguage)} */}
                    <TextToSpeech text={navItem.name}><MenuNameByLang obj={navItem} /></TextToSpeech>
                  </Link>
                )
              ) : (
                <a
                  href="true"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsMainNavClicked((prev) => !prev);

                    let isOnHoverCp = [];
                    isOnHoverCp[index] = !isOnHover[index];
                    setIsOnHover(isOnHoverCp);
                  }}
                  onMouseOver={() => {
                    setIsMainNavClicked(true);
                    // handlelSpeak(navItem.name)
                  }}
                // onMouseLeave={handleStop}
                >
                  {/* {getMenuNameByLang(navItem, webLanguage)} */}
                  <TextToSpeech text={navItem.name}> <MenuNameByLang obj={navItem} /></TextToSpeech>
                </a>
              )}

              {navItem.submenus.length > 0 && (
                <NavSubmenu
                  sendKey={navItem.mid}
                  MenuItem={navItem.submenus}
                  setIsMainNavClicked={setIsMainNavClicked}
                  isOnHover={isOnHover[index]}
                  setIsOnHover={setIsOnHover}
                  setIsCollapsed={setIsCollapsed}
                />
              )}
            </li>
          );
        })}
    </>
  );
};

export default function ApplicationHeader(props) {
  const { allMenuItems, homePageData, webLanguage, isTtsActive } = useSelector((state) => state.common, shallowEqual);
  const dispatch = useDispatch()
  const [languageToggle, setLanguageToggle] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLanguageChange = (languange) => {
    // setWebLanguage(languange);
    languange == 'Marathi' && dispatch({
      type: SET_TTS_STATUS,
      payload: false,
    })
    dispatch({
      type: SET_WEB_LANGUAGE,
      payload: languange,
    })
    window.sessionStorage.setItem("userLanguage", languange)
  };

  const handleTtsChange = () => {
    handlelSpeak(!isTtsActive ? SRC_ENABLED.descInEnglish : SRC_DISABLED.descInEnglish)
    dispatch({
      type: SET_TTS_STATUS,
      payload: !isTtsActive,
    })
  }

  useEffect(() => {
    const userLanguage = window.sessionStorage.getItem('userLanguage')
    if (userLanguage) {
      dispatch({
        type: SET_WEB_LANGUAGE,
        payload: userLanguage,
      })
    }
  }, []);

  return (
    <>
      <div className="sitwraper">
        <div className="">
          <div className="topheader">
            <div className="thright rhmenu">
              <ul>
              {homePageData && homePageData.social && homePageData.social.length>0 && (
                homePageData.social.map((socialdata, index)=>{
                  return <li>
                    <a 
                      key={index}
                      href={socialdata.url} target="_blank" rel="noopener noreferrer">
                      <img src={`${CommercialImg}${socialdata.id}`} alt={socialdata.title} title={socialdata.title} height="15" />
                    </a>
                  </li>
                })
              )}
                {/*<li>
                  <a href="# "><LabelByLang obj={SITE_MAP}/></a> 
                </li>*/}
                {/*<li>
                 <ToggleButton
                  id="toggle-check"
                  type="checkbox"
                  variant="secondary"
                  checked={isTtsActive}
                  value="1"
                  onChange={(e) => handleTtsChange(e.currentTarget.checked)}
                >
                  Checked
                </ToggleButton> 
                </li>*/}
                <li>
                  {webLanguage === 'English' && <div className="form-check form-switch">
                    <label style={{ margin: "0 3rem 0 0", color: 'white', fontSize: "13px" }} className="" htmlFor="">{SCREEN_READER_ACCESS.descInEnglish}</label>
                    <input className="form-check-input" type="checkbox" value={isTtsActive} checked={isTtsActive} onChange={(e) => handleTtsChange(e.currentTarget.checked)} role="switch" id="flexSwitchCheckDefault" />
                    {/* <VisibilityOffIcon /> */}
                  </div>}
                </li>


                <li className="lang_select">
                  <button
                    className="dropdown-toggle"
                    id="langView"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setLanguageToggle((prev) => !prev)}
                    onMouseOver={() => isTtsActive && handlelSpeak(webLanguage)}
                    onMouseLeave={() => isTtsActive && handleStop}
                  >
                    {webLanguage === 'English' && 'English'}{webLanguage === 'Marathi' && 'मराठी'}{webLanguage === 'Hindi' && 'हिंदी'}
                  </button>
                  <div
                    onClick={() => setLanguageToggle((prev) => !prev)}
                    onMouseLeave={() => setLanguageToggle(false)}
                    className={`top-left-auto dropdown-menu ${languageToggle && "show"
                      }`}
                  >
                    <a
                      onClick={() => {
                        handleLanguageChange("English");
                      }}
                      className="dropdown-item"
                      href="# "
                      data-value="English"
                      onMouseOver={() => isTtsActive && handlelSpeak("English")}
                      onMouseLeave={() => isTtsActive && handleStop}
                    >
                      {webLanguage === 'English' && 'English'}{webLanguage === 'Marathi' && 'इंग्रजी'}{webLanguage === 'Hindi' && 'अंग्रेज़ी'}
                    </a>
                    {/* <a
                      onClick={() => {
                        handleLanguageChange("Marathi");
                      }}
                      className="dropdown-item"
                      href="# "
                      data-value="Marathi"
                      onMouseOver={() => isTtsActive && handlelSpeak("Marathi")}
                      onMouseLeave={() => isTtsActive && handleStop}
                    >
                      {webLanguage === 'English' && 'Marathi'}{webLanguage === 'Marathi' && 'मराठी'}{webLanguage === 'Hindi' && 'मराठी'}
                    </a>
                    <a
                      onClick={() => {
                        handleLanguageChange("Hindi");
                      }}
                      className="dropdown-item"
                      href="# "
                      data-value="Hindi"
                      onMouseOver={()=>handlelSpeak("Hindi")}
                      onMouseLeave={handleStop}
                    >
                      {webLanguage==='English'&& 'Hindi'}{webLanguage==='Marathi' && 'हिंदी'}{webLanguage==='Hindi' && 'हिंदी'}
                    </a> */}
                  </div>
                </li>
                <li className="font_view">
                  {/* <a href="# ">
                    <LabelByLang obj={A_CHARACTER}/><sup>-</sup>
                  </a>
                  <a href="# "><LabelByLang obj={A_CHARACTER}/></a>
                  <a href="# ">
                  <LabelByLang obj={A_CHARACTER}/><sup>+</sup>
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main_header">
        <div className="container">
          <div className="header_wrap">
            <figure className="lion_img">
              <img src={lion} alt="Head" />
            </figure>
            <figure className="mpsc_txt">
              <img src={header} alt="MPSC" />
            </figure>
            <figure className="logo">
              <img src={logo} alt="Logo" />
            </figure>
          </div>
        </div>
      </div>
      {/* {homePageData && homePageData.header && homePageData.header.length>0 && (
        <div className="well well-sm col-sm-12">
          <Carousel indicators={false} controls={false}>
            <Carousel.Item>
                {homePageData.header.map((headerdata, index)=>{
                  if(headerdata.url!==''){
                  return <a href={footerdata.url}
                            target="_blank"
                            key={index}  
                          >
                      <img 
                            key={index}
                              title={headerdata.title}
                              src={`${CommercialImg}${headerdata.id}`} 
                              className="img img-responsive img-rounded custom-img" 
                              style={{ border: "2px solid black" }} alt="header image" />
                  </a>
                }else{
                  return  <img 
                              title={headerdata.title}
                              src={`${CommercialImg}${headerdata.id}`} 
                              className="img img-responsive img-rounded custom-img" 
                              style={{ border: "2px solid black" }} alt="header image" />
                }
              })}
            </Carousel.Item>
          </Carousel>
        </div>
      )} */}
      <HomePageNav isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}>
        <div className="fullmenuwrapper" id="navigation">
          <div className="d-block container mw-full">
            <div className="menuwraper">
              <ul className="mainmenu mainmenubox navigation-menu">
                <li className="homeicon">
                  <Link
                    to="/home"
                    onClick={() => setIsCollapsed((prev) => !prev)}
                    onMouseOver={() => isTtsActive && handlelSpeak("Home Page")}
                    onMouseLeave={() => isTtsActive && handleStop}
                  >
                    <img src={home} alt="homeIcon" />
                  </Link>
                </li>
                
                <HeaderNavigations
                  allMenuItems={allMenuItems}
                  setIsCollapsed={setIsCollapsed}
                />
                <li className="check">
                  <Link
                    to="/gallery"
                    onClick={() => setIsCollapsed((prev) => !prev)}
                    onMouseOver={() => isTtsActive && handlelSpeak("Gallery")}
                    onMouseLeave={() => isTtsActive && handleStop}
                  >
                    {/* <img src={home} alt="homeIcon" /> */}
                    <LabelByLang obj={GALLERY} />
                  </Link>
                </li>
                {/* blocked temporary */}
                <li className="d-none last-elements">
                  <div className="search_wrap">
                    <input type="text" placeholder="Type here" />
                    <button className="btn btn-link"><LabelByLang obj={SEARCH} /> </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </HomePageNav>
      <div className="contruction_title_wrap">
        {/*  <div className="construction_container">
				<b>This Site is under upgradation &amp; maintenance and is made
					available only for latest announcements &amp; Online Application
					System</b>
			</div> */}
      </div>

      {/* <HomePageNav {...props}/> */}
    </>
  );
}
