import React from 'react'
import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const RequireAuth = () => {
    const [user, setUser] = useState(false);
    const token = sessionStorage.getItem('token');
  return (
    token ? <Outlet /> : <Navigate to='/admin/login' />
  )
}

export default RequireAuth