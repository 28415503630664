import { SET_MENU_ITMES, SET_HOME_DATA, SHOW_LOADER, HIDE_LOADER, SET_WEB_LANGUAGE, SET_TTS_STATUS } from "../../common/constants/ActionTypes";

const initialState = { 
    errorMessage: "",
    captchaId: 0,
    loading: false,
    allMenuItems: [],
    homePageData: [],
    webLanguage:"English",
    isTtsActive:false,
}

export default function commonReducer(state = initialState, action) {
    switch(action.type) {
        case SHOW_LOADER: 
            return {
                ...state,
                loading : true
            }
        case HIDE_LOADER: 
            return {
                ...state,
                loading : false
            }
        case SET_MENU_ITMES: 
            return {
                ...state,
                allMenuItems : action.payload
            }
        case SET_HOME_DATA: 
            return {
                ...state,
                homePageData : action.payload
            }
        case SET_WEB_LANGUAGE: 
        return {
            ...state,
            webLanguage : action.payload
        }
        case SET_TTS_STATUS: 
        return {
            ...state,
            isTtsActive : action.payload
        }
        
        default:
            return state;
    }
}