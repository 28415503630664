import { SET_MAIN_CONTENT, SET_Office_bearer_CONTENT } from "../../common/constants/ActionTypes";

const initialState = { 
    mainContentData: [],
    officeBearerData: []

}

export default function mainContentReducer(state = initialState, action) {
    switch(action.type) {
        case SET_MAIN_CONTENT: 
            return {
                ...state,
                mainContentData : action.payload
            }
        case SET_Office_bearer_CONTENT: 
            return {
                ...state,
                officeBearerData : action.payload
            }
        
        default:
            return state;
    }
}