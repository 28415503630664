import moment from 'moment';
import 'moment-precise-range-plugin';

export const dateDifferece = (fromdate, todate) => {
    var date1 = moment(fromdate);
    var date2 = moment(todate).add(1, 'days');
    var difference = moment.preciseDiff(date1, date2, true);

    return difference
}
export const dateDiffereceExServiceMan = (fromdate, todate) => {
    var date1 = moment(fromdate);
    var date2 = moment(todate).add(1, 'days');

    var difference = moment.preciseDiff(date1, date2, true);

    return difference
}

export const toUTCDate = (date) => {
    return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : date;
}

export const fromUTCDate = (date) => {
    return date ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()): date;
}

export const formatDateToYYYYMMDD = (date) =>{
    return moment(date).format('YYYY-MM-DD')
}

export const formatDateToDDMMYY = (date, webLanguage='English') =>{

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    locale: 'mr-IN'
  };
    if(date===null){
        return null
    }
    if(webLanguage === 'Marathi'){
        return  new Date(date).toLocaleString('mr-IN', options)
    }
    if(webLanguage === 'Hindi'){
        return  new Date(date).toLocaleString('mr-IN', options)
    }
    else{
        return  moment(new Date(date)).format('DD-MM-YYYY')
    }
}

export const convertDateMarathiToEnglish = (marathiDate) =>{
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        locale: 'mr-IN'
      };
    const [englishDay, englishMonth, englishYear] = marathiDate.replace(/[\u0966-\u096F]/g, (c) => String.fromCharCode(c.charCodeAt(0) - 0x0966 + 48)).split("-");
    const dateObject = new Date(`${englishMonth}-${englishDay}-${englishYear}`);
    return(dateObject.toLocaleString('hi-IN', options))
}