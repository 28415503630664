export const SHOW_LOADER = "show_loader";
export const HIDE_LOADER = "hide_loader";

//admin login

// add content
export const SET_YEAR_OF_ADVERTISEMENT = "set_year_of_advertisement"

export const SET_USER_DETAILS = "set_user_details"
export const SET_EXAM_TYPE_LIST = "set_exam_type_list"
export const SET_EXAM_NAME_LIST = "set_exam_name_list"
export const SET_DESIGNATION_LIST = "set_designation_list"
export const SET_MONTH_WISE_LIST = "set_month_wise_list"


//app menu list
export const SET_MAIN_MENU_LIST = "set_menu_list";
export const SET_SUB_MENU_LIST = "set_sub_menu_list";
export const SET_SUB_MENU_LIST2 = "set_sub_menu_list2";

// pending approval list
export const RESET_PENDING_APPROVAL_LIST = "reset_pending_approval_list";
export const SET_PENDING_APPROVAL_LIST = "set_pending_approval_list";
export const SET_PENDING_APPROVAL_DETAILS = "set_pending_approval_details";

// published content list
export const RESET_PUBLISHED_APPROVAL_LIST = "reset_published_approval_list";
export const SET_PUBLISHED_APPROVAL_LIST = "set_published_approval_list";
export const SET_PUBLISHED_CONTENT_DETAILS = "set_published_content_details";

// add menu
export const SET_FORM_FIELDS = "set_form_fields"
export const SET_FORM_TYPE_LIST = "set_form_type_list"
export const SET_ALL_MENU_LIST = "set_all_menu_list"
export const SET_MENU_DETAILS = "set_menu_details"

// commercials
export const SET_ALL_COMMERCIALS_LIST = "set_all_commercials_list"

