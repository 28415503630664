export const AUTHENTICATE_USER = "/web/api/v1/admin/user/authenticate";
export const LOGOUT_USER = "/web/api/v1/admin/user/logout/"
export const MunuList = "/web/api/v1/admin/menu/list?parentId=";
export const SESSION_HEARTBEAT = '/web/api/v1/admin/user/sessionheartbeat/';

export const YEAR_OF_ADVERTISEMENT = "/web/api/v1/admin/years"

export const ADD_NEW_CONTENT = "/web/api/v1/admin/content/addnewcontent";
export const UPDATE_CONTENT = "/web/api/v1/admin/content/update";
export const APPROVE_CONTENT = "/web/api/v1/admin/content/approve";
export const REJECT_CONTENT = "/web/api/v1/admin/content/reject";
export const MONTH_WISE_LIST = "/web/api/v1/admin/content/getlist";
export const EXAM_NAME_LIST = "/web/api/v1/admin/exam_name/listAll";
export const EXAM_TYPE_LIST = "/web/api/v1/admin/exam_type/listAll";
export const GET_DESIGNATION_LIST = "/web/api/v1/admin/content/getdesignation";

export const ALL_PENDING_APPROVAL_LIST = "/web/api/v1/admin/content/list_pending_approval";
export const PENDING_APPROVAL_LIST = "/web/api/v1/admin/content/list_pending_approval";
export const PENDING_CONTENT_DETAILS = "/web/api/v1/admin/content/get_approval_details?id=";

export const ADVT_ACTIVATE_DEACTIVATE = "/web/api/v1/admin/content/isactiveadvertisment";
export const MENU_ACTIVATE_DEACTIVATE = "/web/api/v1/admin/menu/isactivemenu";


export const ALL_PUBLISHED_CONTENT_LIST = "/web/api/v1/admin/content/get_all_published_list";
// export const PUBLISHED_CONTENT_LIST = "/web/api/v1/admin/content/get_published_list?mid=";
export const PUBLISHED_CONTENT_LIST = "/web/api/v1/admin/content/get_published_list";
export const PUBLISHED_CONTENT_DETAILS = "/web/api/v1/admin/content/get_approval_details?id=";

export const CREATE_NEW_MENU = "/web/api/v1/admin/menu/createmenu";
export const EDIT_MENU = "/web/api/v1/admin/menu/editmenu";
export const All_MENU_LIST = "/web/api/v1/admin/menu/menulist";
export const GET_FORMS = "/web/api/v1/admin/menu/getforms";
export const GET_FIELDS = "/web/api/v1/admin/menu/getfields";
export const GET_MENU_DETAILS = "/web/api/v1/admin/menu/getmenu";


//commercials
export const GET_ALL_COMMERCIALS_LIST = "/web/api/v1/admin/commercial/getlist";
export const CREATE_COMMERCIAL="/web/api/v1/admin/commercial/addnewcommercialdata"
export const UPDATE_COMMERCIAL="/web/api/v1/admin/commercial/updatecommercialdata"
export const COMMERCIALS_ACTIVATE_DEACTIVATE="/web/api/v1/admin/commercial/flgcommercialdata"




