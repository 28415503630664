import { ADVT_ACTIVATE_DEACTIVATE, CREATE_NEW_MENU, EDIT_MENU, GET_FIELDS, GET_FORMS, MENU_ACTIVATE_DEACTIVATE } from "../../common/constants/endpoints";
import API from "../../../helpers/API";
import { showLoader, hideLoader, getMenuItems } from "../../../common/actions/commonActions";
import { toastr } from "react-redux-toastr";
import { SET_FORM_FIELDS, SET_FORM_TYPE_LIST } from "../../common/constants/ActionTypes";
import { getAllMenuList } from "../../ManageMenu/actions/manageMenuActions";
export const addNewMenuData = (requestBody={}) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token = sessionStorage.getItem("token");
      try {
        response = await API.post(`${CREATE_NEW_MENU}`,requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
          window.scrollTo(0, 0)
          toastr.success("New menu added successfully.");
          dispatch(getMenuItems());
          dispatch(getFormTypeList());
        }
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };

  export const editMenuData = (requestBody={}, page) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token = sessionStorage.getItem("token");
      try {
        response = await API.post(`${EDIT_MENU}`,requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
          window.scrollTo(0, 0)
          toastr.success("Menu updated successfully.");
          dispatch(getAllMenuList(page,10))
        }
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };

  export const getFormFields = (formType) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let tokent = sessionStorage.getItem("token");
      try {
        response = await API.get(`${GET_FIELDS}/${formType}`, {
          headers: {
            Authorization: `Bearer ${tokent}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
        }
        let sortedData = response.data.sort((a, b) => a.order - b.order);
        dispatch({
          type: SET_FORM_FIELDS,
          payload: sortedData,
        });
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };
  export const getFormTypeList = (mid = 0) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let tokent = sessionStorage.getItem("token");
      try {
        response = await API.get(`${GET_FORMS}`, {
          headers: {
            Authorization: `Bearer ${tokent}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
        }
        dispatch({
          type: SET_FORM_TYPE_LIST,
          payload: response.data,
        });
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };

  export const advtActivateDeactivate = (requestData, getAllPendingApprovalList, page) => {
    return async (dispatch) => {
        const session = JSON.parse(sessionStorage.getItem("session"));
        let tokent = sessionStorage.getItem("token");
        let response;
        try {
            response = await API.post(ADVT_ACTIVATE_DEACTIVATE, requestData, {
                headers: { Authorization: `Bearer ${tokent}` },
            });
            dispatch(getAllPendingApprovalList(page))
            let successMessage = (response && response.data) ? response.data.message : response.message
            toastr.success(successMessage);
        } catch (error) {
          dispatch(getAllPendingApprovalList(page))

            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    }
}

export const menuActivateDeactivate = (requestData, getAllMenuList, page) => {
  return async (dispatch) => {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let tokent = sessionStorage.getItem("token");
      let response;
      try {
          response = await API.post(MENU_ACTIVATE_DEACTIVATE, requestData, {
              headers: { Authorization: `Bearer ${tokent}` },
          });
          dispatch(getAllMenuList(page,10))
          let successMessage = (response && response.data) ? response.data.message : response.message
          toastr.success(successMessage);
      } catch (error) {
        dispatch(getAllMenuList(page,10))

          let errorMessage = (error.response && error.response.data) ?
              error.response.data.message : error.message
          toastr.error(errorMessage);
          return;
      }
  }
}