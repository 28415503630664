import React, { useState } from 'react';

const ImageToDisplay = ({selectedImage, setSelectedImage,setErrors}) => {
  const [image, setImage] = useState(null);  

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file)
    
    setErrors(preval =>{return {...preval, "image":false}});
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {image && <img src={image} height="100" width="150" alt="Selected" />}
    </div>
  );
};

export default ImageToDisplay;





