import API from "../../../helpers/API";
import { toastr } from "react-redux-toastr";
import { formatDateToYYYYMMDD } from "../../../helpers/Datetime";
import { showLoader, hideLoader } from "../../../common/actions/commonActions";
import {
    SET_ALL_MENU_LIST, SET_MENU_DETAILS
  } from "../../common/constants/ActionTypes";
import {
    All_MENU_LIST, GET_MENU_DETAILS
  } from "../../common/constants/endpoints";

  
export const getAllMenuList = (pageNo, size=10, searchValue) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token = sessionStorage.getItem("token");
      try {
        response = await API.get(`${All_MENU_LIST}/${pageNo}/${size}?menuname=${searchValue?searchValue:''}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response)
        if (response != null) {
          dispatch(hideLoader());
        }
        dispatch({
          type: SET_ALL_MENU_LIST,
          payload: response.data,
        });
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };
  export const getAllMenuListSearch = (pageNo, size=10, searchValue) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token = sessionStorage.getItem("token");
      try {
        response = await API.get(`${All_MENU_LIST}/${pageNo}/${size}?menuname=${searchValue}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
        }
        dispatch({
          type: SET_ALL_MENU_LIST,
          payload: response.data,
        });
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };
  
export const getMenuDetailsById = (mid, setSelectedMenuDetails) => {
    
  return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token= sessionStorage.getItem('token')
      try {
          response = await API.get(`${GET_MENU_DETAILS}/${mid}`, {
              headers: {
                  'Authorization': `Bearer ${token}`
                }
          });
          if(response!=null)
          {
              dispatch(hideLoader());
          }
          // setSelectedMenuItem(response.data.publishedContentDetails?response.data.publishedContentDetails:'')
          setSelectedMenuDetails(response.data)
          dispatch({
              type: SET_MENU_DETAILS,
              payload: response.data,
          });

      } catch (error) {
          dispatch(hideLoader());
          let errorMessage = (error.response && error.response.data) ?
              error.response.data.message : error.message
          toastr.error(errorMessage);
          return;
      }
  };
}