import { SET_SESSION_TIMEOUT } from "../../../common/constants/ActionTypes"

const initialState = { 
    errorMessage: "",
    userDetails: [],
    sessionTimoutModal: false,
}

export default function adminLoginReducer(state = initialState, action) {
    switch(action.type) {
        // case SET_USER_DETAILS: 
        //     return {
        //         ...state,
        //         userDetails : action.payload
        //     }
        case SET_SESSION_TIMEOUT: 
            return {
                ...state,
                sessionTimoutModal : action.payload
            }
        // case SET_MENU_ITMES: 
        //     return {
        //         ...state,
        //         allMenuItems : action.payload
        //     }
        // case SET_HOME_DATA: 
        //     return {
        //         ...state,
        //         homePageData : action.payload
        //     }
        
        default:
            return state;
    }
}