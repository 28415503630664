import { SET_DESIGNATION_LIST, SET_EXAM_NAME_LIST, SET_EXAM_TYPE_LIST, SET_MAIN_MENU_LIST, SET_MONTH_WISE_LIST, SET_SUB_MENU_LIST, SET_SUB_MENU_LIST2, SET_YEAR_OF_ADVERTISEMENT } from "../../common/constants/ActionTypes"

const initialState = { 
    errorMessage: "",
    mainMenuList: [],
    subMenuList: [],
    subMenuList2: [],
    examTypeList:[],
    examNameList:[],
    yearsOfAdvertisement: [],
    designationList: [],
    monthWiseList: [],
}

export default function addNewContentReducer(state = initialState, action) {
    switch(action.type) {
        case SET_MAIN_MENU_LIST: 
            return {
                ...state,
                mainMenuList : action.payload
            }
        case SET_SUB_MENU_LIST: 
            return {
                ...state,
                subMenuList : action.payload
            }
        case SET_SUB_MENU_LIST2: 
            return {
                ...state,
                subMenuList2 : action.payload
            }
        case SET_YEAR_OF_ADVERTISEMENT:
            return {
                ...state,
                yearsOfAdvertisement: action.payload
            }
        case SET_EXAM_NAME_LIST: 
            return {
                ...state,
                examNameList : action.payload
            }
        case SET_MONTH_WISE_LIST: 
            return {
                ...state,
                monthWiseList : action.payload
            }
        case SET_EXAM_TYPE_LIST: 
            return {
                ...state,
                examTypeList : action.payload
            }
        case SET_DESIGNATION_LIST: 
            return {
                ...state,
                designationList : action.payload
            }
        
        default:
            return state;
    }
}