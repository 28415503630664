import { hideLoader, showLoader } from '../../../common/actions/commonActions';
import API from '../../../helpers/API';
import { LOGOUT_USER } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';

export const logoutUser = (userId) => {

    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.post( `${LOGOUT_USER}${userId}`);
            if(response!=null && response.data === true)
            {
                dispatch(hideLoader());
                toastr.success("User logged out successfully.")
            }
        } catch (error) {
            dispatch(hideLoader());
            toastr.error("Error", error.message);
        }
    }

};