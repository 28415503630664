import React, { useState, useEffect } from "react";
import AppMenuList from "../../common/components/AppMenuList";
import ContentTableView from "../../common/components/ContentTableView";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  getAllPendingApprovalList,
  getMenuListByMid,
  getSubMenuListByMid,
  getSubMenuList2ByMid,
  getPendingApprovalListByMid,
  getPendingApprovalDetailsById,
} from "../actions/pendingApprovalListActions";
import { toInteger } from "lodash";
import { RESET_PENDING_APPROVAL_LIST } from "../../common/constants/ActionTypes";
import CommonFormatContent from "../../common/components/CommonFormatContent";
import CommonFormatContentSecond from "../../common/components/CommonFormatContentSecond";
import ResultContentFormat from "../../common/components/ResultContentFormat";
import { getDesignationList, getExamNameList, getExamTypeList, getYears } from "../../AddNewContent/actions/addNewContentActions";
import OfficeBearerContent from "../../common/components/OfficeBearerContent";

const PendingApprovalList = () => {
  const dispatch = useDispatch();
  const {
    mainMenuList,
    subMenuList,
    subMenuList2,
    pendingApprovalList,
    pendingApprovalDetails,
  } = useSelector((state) => state.pendingApprovalList, shallowEqual);
  const [formState, setFormState] = useState("new");
  const [mainMenuValue, setMainMenuValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");
  const [subMenuValue2, setSubMenuValue2] = useState("");
  const [formTitleName, setFormTitleName] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "common_format_content"
  );
  const [selectedMenuItemId, setSelectedMenuItemID] = useState(0);

  // table states
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    selectedMenuItemId !== 0
      ? dispatch(getPendingApprovalDetailsById(selectedMenuItemId, newPage))
      : dispatch(getAllPendingApprovalList(newPage));
  };
  const resetPageData = () =>{
    dispatch(getMenuListByMid());
    dispatch(getExamNameList());
    dispatch(getExamTypeList());
    dispatch(getYears());
    setSelectedMenuItemID(0)
    setPage(0)
    setFormState('new') 
    setFormTitleName('')
    setMainMenuValue('')
    setSubMenuValue('')
    setSubMenuValue2('')
    dispatch(getMenuListByMid());
    dispatch(getAllPendingApprovalList());
  }

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetPage = () => {
    setPage(0);
  };

  useEffect(() => {
    dispatch(getExamNameList());
    dispatch(getExamTypeList());
    dispatch(getYears());
    dispatch(getDesignationList())
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMenuListByMid());
    dispatch(getAllPendingApprovalList());
  }, [dispatch]);

  const handleMainMenu = (e) => {
    const getComponent =
      mainMenuList && mainMenuList.length > 0
        ? mainMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setMainMenuValue(e.target.value);
    setSubMenuValue("");
    resetPage();
    dispatch({ type: RESET_PENDING_APPROVAL_LIST, payload: [] });
    dispatch(getSubMenuListByMid(toInteger(e.target.value)));
    dispatch(getPendingApprovalListByMid(toInteger(e.target.value)));
  };

  const handleSubMenu = (e) => {
    const getComponent =
      subMenuList && subMenuList.length > 0
        ? subMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue(e.target.value);
    setSubMenuValue2("");
    dispatch(getSubMenuList2ByMid(toInteger(e.target.value)));
    dispatch(getPendingApprovalListByMid(toInteger(e.target.value)));
    resetPage();
  };

  const handleSubMenu2 = (e) => {
    setSubMenuValue2(e.target.value);
    const getComponent =
      subMenuList2 && subMenuList2.length > 0
        ? subMenuList2.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    resetPage();
    dispatch(getPendingApprovalListByMid(toInteger(e.target.value)));
  };

  const handleFormState = (fState, contentId) => {
    dispatch(getPendingApprovalDetailsById(contentId, setSelectedMenuItem));
    setFormState(fState);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormState("new");
  };

  const HandleCreateNewContent = ({ componentType, actionType }) => {
    switch (componentType) {
      case "common_format_content":
        return (
          <CommonFormatContent
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            actionType={actionType}
            publishedContentDetails={pendingApprovalDetails}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      //
      case "common_format2_content":
        return (
          <CommonFormatContentSecond
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            actionType={actionType}
            publishedContentDetails={pendingApprovalDetails}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      case "results-content":
        return (
          <ResultContentFormat
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            actionType={actionType}
            publishedContentDetails={pendingApprovalDetails}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      case "office_bearer_content":
      return (
        <OfficeBearerContent
          formTitle={formTitleName}
          selectedMid={selectedMenuItemId}
          actionType={actionType}
          publishedContentDetails={pendingApprovalDetails}
          handleCancel={handleCancel}
          resetPageData={resetPageData}
        />
      );
      default:
        return <div>No menu selected</div>;
    }
  };

  return (
    <div>
      <p></p>
      <div className="card">
        <div className="card-header">
          <strong>Pending Approval List</strong>
        </div>
        <div className="card-body">
          {/*  */}
          {formState !== "editing" && (
          <AppMenuList
            mainMenuValue={mainMenuValue}
            subMenuValue={subMenuValue}
            subMenuValue2={subMenuValue2}
            mainMenuList={mainMenuList}
            subMenuList={subMenuList}
            subMenuList2={subMenuList2}
            handleMainMenu={handleMainMenu}
            handleSubMenu={handleSubMenu}
            handleSubMenu2={handleSubMenu2}
          />
          )}
          <hr className="dropdown-divider" />
          {/*  */}
          {formState !== "editing" && (
            <ContentTableView
              tableData={
                pendingApprovalList && pendingApprovalList.pendingApprovalList
                  ? pendingApprovalList.pendingApprovalList
                  : []
              }
              totalDataLength={
                pendingApprovalList && pendingApprovalList.totalNoOfRecords
                  ? pendingApprovalList.totalNoOfRecords
                  : 0
              }
              page={page}
              handleChangePage={handleChangePage}
              handleFormState={handleFormState}
              isPendingTable={true}
              getAllPendingApprovalList= {getAllPendingApprovalList}
            />
          )}
          {formState === "editing" && (
            <HandleCreateNewContent
              componentType={selectedMenuItem}
              actionType={"editing"}
            />
          )}
        </div>
      </div>
      <p></p>
      <p></p>
    </div>
  );
};

export default PendingApprovalList;
