import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllMenuList, getAllMenuListSearch, getMenuDetailsById } from "../actions/manageMenuActions";
import MenuListTable from "../../common/components/MenuListTable";
import AddMenu from "../../AddMenu/components/AddMenu";
import EditMenu from "./EditMenu";

const ManageMenu = () => {
  const dispatch = useDispatch();
  const { allMenuList } = useSelector(
    (state) => state.manageMenuReducer,
    shallowEqual
  );
  const [formState, setFormState] = useState("new");
  const [selectedMenuDetails, setSelectedMenuDetails] = useState('');
  const [searchValue, setSearchValue] = useState('');

  // table states
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllMenuList(newPage,10, searchValue))

    // selectedMenuItemId !== 0
    //     ? dispatch(getPendingApprovalDetailsById(selectedMenuItemId, newPage))
    //     : dispatch(getAllallMenuList(newPage));
  };
  const handleFormState = (fState, contentId) => {
    fState=='editing'&&dispatch(getMenuDetailsById(contentId, setSelectedMenuDetails));
    // console.log(fState,'fState')
    // fState=='new'&&dispatch(getAllMenuList(page, 10));
    setFormState(fState);
  };
  const handleSearch = () =>{
    dispatch(getAllMenuListSearch(page,10, searchValue))
  }

  useEffect(()=>{
    dispatch(getAllMenuList(page,10, searchValue))
  },[])
  return (
    <div>
      <p></p>
      <div className="card">
        <div className="card-header">
          <strong>Manage Menu</strong>
        </div>
        <div className="card-body">
        {formState !== "editing" && (<div className='row mb-1'>
            <div className='form-inline ' style={{justifyContent:'end'}}>
              
            <input onChange={(e)=>setSearchValue(e.target.value)} style={{width: "200px"}} className="form-control form-control-sm" ></input>
            {/* </div>
            <div className='col-1'> */}
            <button style={{width:'120px'}} onClick={handleSearch} className=" btn btn-primary btn-sm ml-2" >
              <i className="fa fa-search"></i>
            Search
            </button>
            </div>
          </div>)}
          {formState !== "editing" && (
            <MenuListTable
              tableData={
                // []
                allMenuList && allMenuList.menuOptionList 
                  ? allMenuList.menuOptionList
                  : []
              }
              totalDataLength={
                // 0
                allMenuList && allMenuList.totalNoOfRecords
                  ? allMenuList.totalNoOfRecords
                  : 0
              }
              page={page}
              handleChangePage={handleChangePage}
              handleFormState={handleFormState}
              isPendingTable={true}
              getAllMenuList={getAllMenuList}
            />
          )}
          {formState === "editing" && (
            <EditMenu
              // componentType={selectedMenuItem}
              actionType={"editing"}
              page={page}
              handleFormState={handleFormState}
              selectedMenuDetails= {selectedMenuDetails}
              handleChangePage={handleChangePage}
            />
          )}
        </div>
      </div>
      <p></p>
      <p></p>
    </div>
  );
};

export default ManageMenu;
