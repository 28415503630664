import React from 'react';
import { useSelector } from 'react-redux';
import '../../styles/loader.css'
import logo from '../../images/logo.png'

const Loader = () => {

    let loading = useSelector(state => state.common.loading);

    return loading ?
            <div className="appLoader">
            <div className="preloader">
                <div className="loader_wrap">
                    <figure>
                        <img src={logo} alt="Lion" />
                    </figure>
                    <div className="spinner">
                        <div></div><div></div><div></div><div></div>
                        </div>
                </div>
            </div>
            </div>
            
        : 
            "";
}

export default Loader;