import API from "../../../helpers/API";
import { toastr } from "react-redux-toastr";
import { formatDateToYYYYMMDD } from "../../../helpers/Datetime";
import { showLoader, hideLoader } from "../../../common/actions/commonActions";
import {
    SET_ALL_COMMERCIALS_LIST,
      } from "../../common/constants/ActionTypes";
import {
  COMMERCIALS_ACTIVATE_DEACTIVATE,
     CREATE_COMMERCIAL, GET_ALL_COMMERCIALS_LIST, UPDATE_COMMERCIAL
  } from "../../common/constants/endpoints";

  
export const getAllCommercialsList = (pageNo, size=10) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      let token = sessionStorage.getItem("token");
      try {
        response = await API.get(`${GET_ALL_COMMERCIALS_LIST}/${pageNo}/${size}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
        }
        dispatch({
          type: SET_ALL_COMMERCIALS_LIST,
          payload: response.data,
        });
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };
  

export const createCommercials = (requestBody={}, handleFormState, clearForm, page) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      const formData = new FormData();
      formData.append("area", requestBody.area);
      formData.append("url", requestBody.url);
      formData.append("title", requestBody.title);
      formData.append("dateFrom",formatDateToYYYYMMDD(requestBody.dateFrom));
      formData.append("dateTo",formatDateToYYYYMMDD(requestBody.dateTo));
      formData.append("isActive", requestBody.isActive);

      if(requestBody.area=="Gallery"){
        let tempFiles=[];
        for (var i = 0; i <  requestBody.file.length; i++) {
          tempFiles.push(requestBody.file[0][i])
          formData.append('file',  requestBody.file[i]);
        }
 
      }else{
        formData.append("file", requestBody.file);
      }
      
      
      let token = sessionStorage.getItem("token");
      try {
        response = await API.post(`${CREATE_COMMERCIAL}`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
          window.scrollTo(0, 0)
          toastr.success("New commercial added successfully.");
          handleFormState('list')
          clearForm()
          dispatch(getAllCommercialsList(page, 10));
        //   dispatch(getFormTypeList());
        }
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };
  export const updateCommercials = (requestBody={}, handleFormState, clearForm, page) => {
    return async function (dispatch) {
      dispatch(showLoader());
      let response;
      const formData = new FormData();
      formData.append("id",requestBody.id)
      formData.append("area", requestBody.area);
      formData.append("url", requestBody.url);
      requestBody.file && formData.append("file", requestBody.file);
      formData.append("title", requestBody.title);
      formData.append("dateFrom",formatDateToYYYYMMDD(requestBody.dateFrom));
      formData.append("dateTo",formatDateToYYYYMMDD(requestBody.dateTo));
      formData.append("isActive", requestBody.isActive);
      let token = sessionStorage.getItem("token");
      try {
        response = await API.post(`${UPDATE_COMMERCIAL}`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response != null) {
          dispatch(hideLoader());
          window.scrollTo(0, 0)
          toastr.success("Commercial updated successfully.");
          handleFormState("list")
          clearForm()
          dispatch(getAllCommercialsList(page, 10));
        //   dispatch(getFormTypeList());
        }
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        toastr.error(errorMessage);
        return;
      }
    };
  };

  
export const commercialsActivateDeactivate = (requestData, getAllCommercialsList, page) => {
  return async (dispatch) => {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let tokent = sessionStorage.getItem("token");
      let response;
      try {
          response = await API.post(COMMERCIALS_ACTIVATE_DEACTIVATE, requestData, {
              headers: { Authorization: `Bearer ${tokent}` },
          });
          dispatch(getAllCommercialsList(page,10))
          let successMessage = (response && response.data) ? response.data.message : response.message
          toastr.success(successMessage);
      } catch (error) {
        dispatch(getAllCommercialsList(page,10))

          let errorMessage = (error.response && error.response.data) ?
              error.response.data.message : error.message
          toastr.error(errorMessage);
          return;
      }
  }
}