

import * as React from 'react';
import { useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { isUserCreator } from '../../auth/sessionManager';
import { Switch } from '@mui/material';
import { menuActivateDeactivate } from '../../AddMenu/actions/addMenuActions';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);}

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);}

      const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);}

        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));}

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default function MenuListTable({ tableData, totalDataLength, page, handleChangePage, handleFormState, isPendingTable = false, getAllMenuList }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRows, setOpenRows] = React.useState({});


  const dispatch = useDispatch();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleIsActiveChange = (isActive, id) => {
    dispatch(menuActivateDeactivate({ isActive: isActive, mid: id }, getAllMenuList, page));
  };
  const toggleRow = (index) => {
    setOpenRows((prevOpenRows) => {
      // Create a new state where all rows are false
      let newOpenRows = {};
  
      // Set only the toggled row to true, others remain false
      newOpenRows[index] = !prevOpenRows[index];
  
      return newOpenRows;
    });
  };
  const toggleSubRow = (index, subIndex) => {
    const currentIndex = index + "-" + subIndex;
    setOpenRows(prevOpenRows => ({
      ...prevOpenRows,
      [currentIndex]: !prevOpenRows[currentIndex]
    }));
    Object.keys(openRows).forEach(key => {
      if (key.startsWith(index + "-") && key !== currentIndex) {
        setOpenRows(prevOpenRows => ({
          ...prevOpenRows,
          [key]: false
        }));
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sr. No.</StyledTableCell>
            <StyledTableCell>Description (English)</StyledTableCell>
            <StyledTableCell>Description (Marathi)</StyledTableCell>
            <StyledTableCell>Description (Hindi)</StyledTableCell>
            <StyledTableCell>Display Name</StyledTableCell>
            <StyledTableCell style={{ width: 50 }} align="center">Active</StyledTableCell>
            {isUserCreator() && isPendingTable && <StyledTableCell>Status</StyledTableCell>}
            <StyledTableCell style={{ width: 50 }} align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <React.Fragment key={index}>
                <StyledTableRow>
                  <TableCell style={{ width: 50 }} component="th" scope="row" align="left">
                    {(page * rowsPerPage) + index + 1}
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                    <IconButton size="small" onClick={() => toggleRow(index)}>
                      {openRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {row.name}
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                  <IconButton size="small" onClick={() => toggleRow(index)}>
                      {openRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {row.nameMr}
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                  <IconButton size="small" onClick={() => toggleRow(index)}>
                      {openRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {row.nameHin}
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                    {row.display}
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="center">
                    <Switch defaultChecked={row.isActive} onChange={(e) => handleIsActiveChange(e.target.checked, row.mid)} />
                  </TableCell>
                  {isUserCreator() && isPendingTable && (
                    <TableCell style={{ width: 200 }} align="left">
                      {row.status}
                    </TableCell>
                  )}
                  <TableCell style={{ width: 50 }} align="right">
                    <div className="text-center" onClick={() => handleFormState('editing', row.mid)} style={{ color: '#007bff', cursor: 'pointer' }}>Open <i className="fa fa-edit"></i></div>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="submenus">
                          <TableBody>
                            {row.submenus.length > 0 ? (row.submenus.map((sub, subIndex) => (
                              <React.Fragment key={subIndex}>
                                <StyledTableRow>
                                  <TableCell style={{ width: 50 }} component="th" scope="row" align="left">
                                  {index + 1}.{subIndex + 1}
                                  </TableCell>
                                  <TableCell style={{ width: 200 }} align="left">
                                    <IconButton size="small" onClick={() => toggleSubRow(index, subIndex)}>
                                      {openRows[index + "-" + subIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    {sub.name}
                                  </TableCell>
                                  <TableCell style={{ width: 200 }} align="left">
                                  <IconButton size="small" onClick={() => toggleSubRow(index, subIndex)}>
                                      {openRows[index + "-" + subIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    {sub.nameMr}
                                  </TableCell>
                                  <TableCell style={{ width: 200 }} align="left">
                                  <IconButton size="small" onClick={() => toggleSubRow(index, subIndex)}>
                                      {openRows[index + "-" + subIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    {sub.nameHin}
                                  </TableCell>
                                  <TableCell style={{ width: 200 }} align="left">
                                    {""}
                                  </TableCell>
                                  <TableCell style={{ width: 50 }} align="center">
                                    <Switch defaultChecked={sub.isActive} onChange={(e) => handleIsActiveChange(e.target.checked, sub.mid)} />
                                  </TableCell>
                                  <TableCell style={{ width: 50 }} align="center">
                                    <div onClick={() => handleFormState('editing', sub.mid)} style={{ color: '#007bff', cursor: 'pointer' }}>Open <i className="fa fa-edit"></i></div>
                                  </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                    <Collapse in={openRows[index + "-" + subIndex]} timeout="auto" unmountOnExit>
                                      <Box margin={1}>
                                        <Table size="small" aria-label="subsubmenus">
                                          <TableBody>
                                            {sub.submenus.length > 0 ? (sub.submenus.map((subsub, subsubIndex) => (
                                              <React.Fragment key={subsubIndex}>
                                                <StyledTableRow>
                                                  <TableCell style={{ width: 50 }} component="th" scope="row" align="left">
                                                    {index + 1}.{subIndex + 1}.{subsubIndex+1}
                                                  </TableCell>
                                                  <TableCell style={{ width: 200 }} align="left">
                                                    {subsub.name}
                                                  </TableCell>
                                                  <TableCell style={{ width: 200 }} align="left">
                                                    {subsub.nameMr}
                                                  </TableCell>
                                                  <TableCell style={{ width: 200 }} align="left">
                                                    {subsub.nameHin}
                                                  </TableCell>
                                                  <TableCell style={{ width: 200 }} align="left">
                                                    {""}
                                                  </TableCell>
                                                  <TableCell style={{ width: 50 }} align="center">
                                                    <Switch defaultChecked={subsub.isActive} onChange={(e) => handleIsActiveChange(e.target.checked, subsub.mid)} />
                                                  </TableCell>
                                                  <TableCell style={{ width: 50 }} align="center">
                                                    <div onClick={() => handleFormState('editing', subsub.mid)} style={{ color: '#007bff', cursor: 'pointer' }}>Open <i className="fa fa-edit"></i></div>
                                                  </TableCell>
                                                </StyledTableRow>
                                              </React.Fragment>
                                            ))) : (
                                              <TableRow>
                                                <TableCell colSpan={8}>
                                                  <div className="text-center alert alert-warning" role="alert">
                                                    No data found
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            ))) : (
                              <TableRow>
                                <TableCell colSpan={8}>
                                  <div className="text-center alert alert-warning" role="alert">
                                    No data found
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <div className="text-center alert alert-warning" role="alert">
                  No data found
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={8}
              count={totalDataLength || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
