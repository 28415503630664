import API from '../../../helpers/API';
import { showLoader, hideLoader } from '../../../common/actions/commonActions';
import { SET_MENU_LIST } from '../../common/constants/ActionTypes';
import { AUTHENTICATE_USER } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';
import { useNavigate } from 'react-router-dom';

export const authenticateUser = (payload) => {
    
    return async function (dispatch) {
        // const navigate = useNavigate();
        dispatch(showLoader());
        let response;
        try {
            response = await API.post(`${AUTHENTICATE_USER}/`, payload, {
                headers: {}
            });
            if(response!=null)
            {
                dispatch(hideLoader());
                sessionStorage.setItem('token',response.data.jwtResponse.token)
                sessionStorage.setItem('user',JSON.stringify(response.data.userData))
                // toastr.success('You have been logged out successfully.')
            }
            payload.loginVerified();
            payload.startSessionTimeout(1 * 60 * 1000)
            // navigate('/admin/add_new_content')
            // dispatch({
            //     type: SET_MENU_LIST,
            //     payload: response.data,
            // });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}