
import React, { Suspense, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, NavLink, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import AdminHeader from './AdminHeader'
import Dashboard from '../../Dashboard/components/Dashboard'
import Drawer from '@mui/material/Drawer';
import '../../adminstyles/sidebar.css'
import AdminSidebar from './AdminSidebar';
import Loader from '../../../common/components/Loader';
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import AddNewContent from '../../AddNewContent/components/AddNewContent';
import PendingApprovalList from '../../PendingApprovalList/components/PendingApprovalList';
import PublishedContentList from '../../PublishedContentList/PublishedContentList';
import AdminLogin from '../../Login/components/AdminLogin';
import RequireAuth from './RequireAuth';
import { SET_SESSION_TIMEOUT } from '../../../common/constants/ActionTypes';
import { useIdleTimer } from 'react-idle-timer';
import { clearSession, clearUserSessionData, getSessionToken, getUserDetails } from '../../auth/sessionManager';
import AddMenu from '../../AddMenu/components/AddMenu';
import ManageMenu from '../../ManageMenu/components/ManageMenu';
import UploadImage from '../../ManageImages/components/AddCommercials';
import ManageCommercials from '../../ManageImages/components/ManageCommercials';
import ManageSocailLinks from '../../ManageSocialLinks/components/ManageSocialLinks';
import { logoutUser } from '../actions/adminCommonActions';
// import { RequireAuth, AuthProvider, useAuthUser } from 'react-auth-kit'


const AdminLayout = () => {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState('Active')
    const [count, setCount] = useState(0)
    const [remaining, setRemaining] = useState(0)
const drawerWidth = 240;

  const token = sessionStorage.getItem("token");
  const user = getUserDetails();

  function Login() {
    // Perform any necessary logout actions

  // Redirect to the login page
  return <Navigate to="/login" />;
}
const handleLogout = () => {
  // sessionStorage.removeItem('token');
  const user = getUserDetails();
  clearUserSessionData();
  clearSession();
  dispatch(logoutUser(user.userId));
  navigate("/admin/login");
};
// const logout = ()=>{
//   sessionStorage.removeItem('token')
//   sessionStorage.removeItem('user')
//   navigate('/admin/login')
//   // handleModelShow()

//   dispatch({ type: SET_SESSION_TIMEOUT, payload: true });
//   // console.log('showmodel1', sessionTimoutModal)
// }

// const onIdle = () => {
//   setState('Idle')
//   const isLogin = getSessionToken();
//   const isUser = getUserDetails()
//   if(isLogin && isUser){
//     logout();
//   }
// }

// const onActive = () => {
//   setState('Active')
// }

// const onAction = () => {
//   setCount(count + 1)
// }

// const { getRemainingTime } = useIdleTimer({
//   onIdle,
//   onActive,
//   onAction,
//   timeout: 20_000,
//   throttle: 500
// })

// useEffect(() => {
//   const interval = setInterval(() => {
//     setRemaining(Math.ceil(getRemainingTime() / 1000))
//   }, 500)

//   return () => {
//     clearInterval(interval)
//   }
// })
  return (
    <div>
      <AdminHeader />
      {user ? <div className="d-block position-fixed userProfileCss">
          <Dropdown 
            as={ButtonGroup} size="sm" >
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              className="mt-3 mr-3 text-capitalize"
              style={{ textAlign:'left'}} 
            >
               {user.firstName ? user.firstName : ""} 
               {/* |{" "} */}
                {/* {user.userRole ? user.userRole : ""} */}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: 'small'}}>
              <Dropdown.Item eventKey="1" className="f14 text-center">
                {" "}
                <img
                  className="imgLogoCss"
                  src="../../logos/default_user_profile.png"
                />
              </Dropdown.Item>
                <Dropdown.Item eventKey="2">Role: {user.userRole ? user.userRole : ""}</Dropdown.Item>
                <Dropdown.Divider />
              
              {/* <Dropdown.Item
                className="f14"
                // onClick={() => dispatch(setChangePasswordFlg(true))}
              >
                 {commonLabels.CHANGE_PASSWORD} 
                change pass
              </Dropdown.Item> */}
              <Dropdown.Item
              eventKey="3"
                className="f14"
                onClick={() => handleLogout() }
              >
                {/* {commonLabels.LOGOUT} */}
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>: ''}
      <Box sx={{ display: "flex" }}>
        <AdminSidebar />
        <Box
          component="main"
          className="mg_top100"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/login" element={<AdminLogin />} />
              <Route element={<RequireAuth />}>
                <Route
                  path="/pending_approval_list"
                  element={<PendingApprovalList />}
                />
                <Route
                  path="/published_content_list"
                  element={<PublishedContentList />}
                />
                <Route
                  path={"/add_new_content"}
                  element={
                    // <RequireAuth loginPath={'/login'}>
                    <AddNewContent />
                    // </RequireAuth>
                  }
                />
                <Route
                  path={"/add_new_menu"}
                  element={
                    // <RequireAuth loginPath={'/login'}>
                    <AddMenu />
                    // </RequireAuth>
                  }
                />
                <Route
                  path={"/manage_menu"}
                  element={
                    // <RequireAuth loginPath={'/login'}>
                    <ManageMenu />
                    // </RequireAuth>
                  }
                />
                 <Route
                  path={"/manage_images"}
                  element={
                    // <RequireAuth loginPath={'/login'}>
                    <ManageCommercials />
                    // </RequireAuth>
                  }
                />
                <Route
                  path={"/manage_social_handle_links"}
                  element={
                    // <RequireAuth loginPath={'/login'}>
                    <ManageSocailLinks />
                    // </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </Suspense>
          <Loader />
        </Box>
      </Box>
    </div>
  );
};

export default AdminLayout;
