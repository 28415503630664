import React, { useState, useEffect } from "react";
import AppMenuList from "../../common/components/AppMenuList";
import CommonFormatContent from "../../common/components/CommonFormatContent";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  getMenuListByMid,
  getSubMenuListByMid,
  getSubMenuList2ByMid,
  getExamNameList,
  getExamTypeList,
  getYears,
  getDesignationList,
  getMonthWiseList,
} from "../actions/addNewContentActions";
import { toInteger } from "lodash";
import CommonFormatContentSecond from "../../common/components/CommonFormatContentSecond";
import ResultContentFormat from "../../common/components/ResultContentFormat";
import OfficeBearerContent from "../../common/components/OfficeBearerContent";

const AddNewContent = () => {
  const dispatch = useDispatch();
  const { mainMenuList, subMenuList, subMenuList2 } = useSelector(
    (state) => state.addNewContent,
    shallowEqual
  );
  const [mainMenuValue, setMainMenuValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");
  const [subMenuValue2, setSubMenuValue2] = useState("");
  const [formTitleName, setFormTitleName] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "common_format_content"
  );
  const [selectedMenuItemId, setSelectedMenuItemID] = useState(0);

  const resetPageData = () => {
    dispatch(getMenuListByMid());
    dispatch(getExamNameList());
    dispatch(getExamTypeList());
    dispatch(getMonthWiseList());
    dispatch(getYears());
    dispatch(getDesignationList())
    setSelectedMenuItemID(0);
    setFormTitleName("");
    setMainMenuValue("");
    setSubMenuValue("");
    setSubMenuValue2("");
  };

  useEffect(() => {
    dispatch(getExamNameList());
    dispatch(getExamTypeList());
    dispatch(getYears());
    dispatch(getDesignationList())
    dispatch(getMonthWiseList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMenuListByMid());
  }, [dispatch]);

  const handleCancel = (e) => {
    e.preventDefault();
  };

  const handleMainMenu = (e) => {
    setMainMenuValue(e.target.value);
    const getComponent =
      mainMenuList && mainMenuList.length > 0
        ? mainMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue("");
    setSubMenuValue2("");
    dispatch(getSubMenuListByMid(toInteger(e.target.value)));
  };

  const handleSubMenu = (e) => {
    setSubMenuValue(e.target.value);
    const getComponent =
      subMenuList && subMenuList.length > 0
        ? subMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2("");
    dispatch(getSubMenuList2ByMid(toInteger(e.target.value)));
  };

  const handleSubMenu2 = (e) => {
    const getComponent =
      subMenuList2 && subMenuList2.length > 0
        ? subMenuList2.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2(e.target.value);
  };

  const HandleCreateNewContent = ({ componentType }) => {
    switch (componentType) {
      case "common_format_content":
        return (
          <CommonFormatContent
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      case "common_format2_content":
        return (
          <CommonFormatContentSecond
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      case "results-content":
        return (
          <ResultContentFormat
            formTitle={formTitleName}
            selectedMid={selectedMenuItemId}
            handleCancel={handleCancel}
            resetPageData={resetPageData}
          />
        );
      case "office_bearer_content":
      return (
        <OfficeBearerContent
          formTitle={formTitleName}
          selectedMid={selectedMenuItemId}
          handleCancel={handleCancel}
          resetPageData={resetPageData}
        />
      );
      default:
        return <div>No menu selected</div>;
    }
  };

  let noMenuSelectedError = "";
  if (mainMenuList.length > 0 && mainMenuValue === "") {
    noMenuSelectedError = "Please select main menu";
  }
  if (subMenuList.length > 0 && subMenuValue === "") {
    noMenuSelectedError = "Please select menu";
  }
  if (subMenuList2.length > 0 && subMenuValue2 === "") {
    noMenuSelectedError = "Please select sub menu";
  }

  return (
    <div>
      <p></p>
      <div className="card">
        <div className="card-header">
          <strong>Add New Content</strong>
        </div>
        <div className="card-body">
          {/*  */}
          <AppMenuList
            mainMenuValue={mainMenuValue}
            subMenuValue={subMenuValue}
            subMenuValue2={subMenuValue2}
            mainMenuList={mainMenuList}
            subMenuList={subMenuList}
            subMenuList2={subMenuList2}
            handleMainMenu={handleMainMenu}
            handleSubMenu={handleSubMenu}
            handleSubMenu2={handleSubMenu2}
          />
          <hr className="dropdown-divider" />
          {/*  */}

          {((mainMenuValue !== "" && subMenuList && subMenuList.length === 0) ||
            (mainMenuValue !== "" &&
              subMenuList &&
              subMenuList.length > 0 &&
              subMenuValue !== "" &&
              subMenuList2.length === 0) ||
            (mainMenuValue !== "" &&
              subMenuList &&
              subMenuList.length > 0 &&
              subMenuValue !== "" &&
              subMenuList2.length > 0 &&
              subMenuValue2 !== "")) && (
            <HandleCreateNewContent componentType={selectedMenuItem} />
          )}
        </div>
      </div>
      <p></p>
      {}
      {noMenuSelectedError !== "" && (
        <div className="alert alert-warning" role="alert">
          {noMenuSelectedError}
        </div>
      )}

      <p></p>
    </div>
  );
};

export default AddNewContent;
