import axios from 'axios';
import { BaseURL } from '../common/constants/urlconst';
const API = axios.create({
    baseURL: BaseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: "json"
});

export default API;