import { SET_ALL_COMMERCIALS_LIST, SET_MENU_DETAILS } from "../../common/constants/ActionTypes"

const initialState = { 
    errorMessage: "",
    allCommercialsList: [],
    menuDetails:{},
}

export default function manageCommercialsReducer(state = initialState, action) {
    switch(action.type) {
        case SET_ALL_COMMERCIALS_LIST: 
            return {
                ...state,
                allCommercialsList : action.payload
            }
        case SET_MENU_DETAILS: 
            return {
                ...state,
                menuDetails : action.payload
            }
        
        default:
            return state;
    }
}