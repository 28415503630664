// export const BaseURL = 'https://mpsc-website-api-uat-linux.azurewebsites.net/';
// export const FileURL = 'https://mpsc-website-api-uat-linux.azurewebsites.net/web/api/v1/downloadFileImage/english/';
// export const CommercialImg= 'https://mpsc-website-api-uat-linux.azurewebsites.net/web/api/v1/downloadcommercialsimage/'


// export const BaseURL = 'http://localhost:8080/';
// export const FileURL = 'http://localhost:8080/web/api/v1/downloadFileImage/english/';
// export const CommercialImg= 'http://localhost:8080/web/api/v1/downloadcommercialsimage/'


// export const BaseURL = 'http://3.6.162.3/';
// export const FileURL = 'http://3.6.162.3/web/api/v1/downloadFileImage/english/';
// export const CommercialImg= 'http://3.6.162.3/web/api/v1/downloadcommercialsimage/';

// export const BaseURL = 'https://mpsc.gov.in/';
// export const FileURL = 'https://mpsc.gov.in/web/api/v1/downloadFileImage/english/';
// export const CommercialImg= 'https://mpsc.gov.in/web/api/v1/downloadcommercialsimage/'
export const BaseURL = '';
export const FileURL = '/web/api/v1/downloadFileImage/english/';
export const CommercialImg= '/web/api/v1/downloadcommercialsimage/';
