import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as toastrReducer } from "react-redux-toastr";

import commonReducer from "./common/reducers/commonReducer";
import homeReducer from "./home/reducers/homeReducer";
import mainContentReducer from "./maincontent/reducers/mainContentReducer";
import pendingApprovalListReducer from "./admin/PendingApprovalList/reducers/pendingApprovalListReducer";
import publishedContentListReducer from "./admin/PublishedContentList/reducers/publicshedContentListReducers";
import addNewContentReducer from "./admin/AddNewContent/reducers/addNewContentReducers";
import adminLoginReducer from "./admin/Login/reducers/adminLoginReducer";
import addMenuReducer from "./admin/AddMenu/reducers/addMenuReducer";
import manageMenuReducer from "./admin/ManageMenu/reducers/manageMenuReducers";
import manageCommercialsReducer from "./admin/ManageImages/reducers/manageCommercialsReducers";

const rootReducer = combineReducers({
    toastr: toastrReducer,
    common: commonReducer,
    home:homeReducer,
    adminLogin: adminLoginReducer,
    mainContent: mainContentReducer,
    pendingApprovalList: pendingApprovalListReducer,
    publishedContentList: publishedContentListReducer,
    addNewContent: addNewContentReducer,
    addMenuReducer: addMenuReducer,
    manageMenuReducer: manageMenuReducer,
    manageCommercialsReducer: manageCommercialsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
