import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

const withMouseOver = (WrappedComponent) => {
  const handleMouseOver = (text) => {
    // Your function logic here
    console.log('Mouse over text:', text);
    let utter = new SpeechSynthesisUtterance(text);
      // utter.lang = 'mr-IN';
      utter.rate = 0.7;
     speechSynthesis.speak(utter);
  };

  const WithMouseOver = (props) => {
    useEffect(() => {
      const handleMouseOverEvent = (event) => {
        const hoveredText = event.target.textContent;
        handleMouseOver(hoveredText);
      };
      document.addEventListener('mouseover', handleMouseOverEvent);
      return () => {
        document.removeEventListener('mouseover', handleMouseOverEvent);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  return WithMouseOver;
};
const AppWithMouseOver = withMouseOver(App);

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      {/* <AppWithMouseOver /> */}
      <App />
      <ReduxToastr
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
