import API from '../../helpers/API';
import { SHOW_LOADER, HIDE_LOADER, SET_MENU_ITMES, SET_HOME_DATA } from '../../common/constants/ActionTypes';
import { OTPS, GET_MENU_ITEMS, GET_HOME_DATA } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';

export const generateCaptcha = () => {

    return async (dispatch) => {
        dispatch(showLoader());
        try {

            let response = await API.post(OTPS, {
                "type": "captcha"
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            toastr.error("Error while generating the captcha image:", error.message);
        }
    }

};

export const showLoader = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADER
        })
    }
};

export const hideLoader = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_LOADER
        })
    }
};

export const getMenuItems = () => {
    
    return async function (dispatch) {
        let response;
        try {
            response = await API.get(GET_MENU_ITEMS, {
                headers: '',
            });
            if(response!=null)
            {
                // dispatch(hideLoader());
            }
            dispatch({
                type: SET_MENU_ITMES,
                payload: response.data,
            });

        } catch (error) {
            // dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}
export const getHomePageData = () => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        try {
            response = await API.get(GET_HOME_DATA, {
                headers: '',
            });
            if(response!=null)
            {
                // dispatch(hideLoader());
            }
            dispatch({
                type: SET_HOME_DATA,
                payload: response.data,
            });

        } catch (error) {
            // dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}
