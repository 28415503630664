import { Route, BrowserRouter, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppLayout from "./common/components/AppLayout";
import AdminLayout from "./admin/common/components/AdminLayout";
import { RequireAuth, AuthProvider, useAuthUser } from "react-auth-kit";

import "./App.css";

function App() {
  const history = createBrowserHistory();

  return (
    <AuthProvider
      authType={"cookie"}
      authName={"token"}
      cookieDomain={window.location.hostname}
      // cookieDomain={window.location.hostname}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/*" element={<AppLayout />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
