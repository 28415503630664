import React, { useState } from "react";
import '../../../styles/selectMultipleImages.css'

const IterateUplaod = ({allImageFiles, setAllImageFiles, setErrors}) => {
  var singleFileObj = [];
  var singleFileArray = [];
  const [singleFile, setSingleFile] = useState([]);

  const uploadSingleFiles = (e) => {
    singleFileObj.push(e.target.files);
    const files = e.target.files;
    
    if(files.length>0){
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            singleFileArray.push(URL.createObjectURL(file));
          }
          setSingleFile([...singleFile, ...singleFileArray]);
          setAllImageFiles(preval=>{
      // console.log([...preval, ...e.target.files])
      setErrors(preval =>{return {...preval, "image":false}});
      return [...preval, ...e.target.files]
    })
    }else{  
        singleFileArray.push(URL.createObjectURL(singleFileObj[0][0]));
        setSingleFile([...singleFile, singleFileArray]);
    }
  };

  // console.log(singleFile);


  const uploadFiles = (e) => {
    e.preventDefault();
    // console.log(singleFile, allImageFiles);
    
  };

  const removeImage = (index) => {
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length)
    ]);
    setAllImageFiles([
      ...allImageFiles.slice(0, index),
      ...allImageFiles.slice(index + 1, allImageFiles.length)
    ]);
  };

  return (
    <>
      <form>
        <div className="container">
          {/* <h4 className="mb-3"> Add Image one by one</h4> */}
          <div className="form-group multi-preview mt-3">
            <div className="row">
                {/* {console.log('singleFile',singleFile)} */}
              {singleFile.length !== 0 &&
                singleFile.map((url, index) => (
                  <div key={url} className="col-md-2">
                    <div className="img-block bg-gray">
                      <img className="img-fluid2" src={url} alt="..." />
                      <span
                        className="remove_img"
                        onClick={() => removeImage(index)}
                      >
                        X
                      </span>
                    </div>
                  </div>
                ))}

              {/* {singleFile.length  ? null : ( */}
                <div className="col-md-2">
                  <div className="form-group">
                    <div className="upload-btn-wrapper p-0">
                      <button className="image-btn"> <i className="fa fa-plus file-input"></i><br/><span style={{fontSize:'small'}}>Add Image</span> </button>
                      <input
                        type="file"
                        name="myfile"
                        multiple="multiple"
                        onChange={uploadSingleFiles}
                      />
                    </div>
                  </div>
                </div>
              {/* )} */}
            </div>
          </div>
          {/* <button
            type="button"
            className="btn btn-danger btn-block w-100"
            onClick={uploadFiles}
          >
            Upload
          </button> */}
        </div>
      </form>
    </>
  );
};

export default IterateUplaod;
