import React,{ useEffect, useState} from 'react';
import AppMenuList from "../../common/components/AppMenuList";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { toInteger } from "lodash";
import {
    getMenuListByMid,
    getSubMenuListByMid,
    getSubMenuList2ByMid,
    getExamNameList,
    getExamTypeList,
    getYears,
    getDesignationList,
  } from "../../AddNewContent/actions/addNewContentActions";
import { ReactTransliterate } from 'react-transliterate';
import FormDemoModel from '../../common/components/FormDemoModel';
import { addNewMenuData, getFormFields, getFormTypeList } from '../actions/addMenuActions';
import { toastr } from 'react-redux-toastr';
import { Button } from 'react-bootstrap';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getMenuItems } from '../../../common/actions/commonActions';
const AddMenu = () => {
    const dispatch = useDispatch();
  const { allMenuItems } = useSelector((state) => state.common, shallowEqual);
    const { mainMenuList, subMenuList, subMenuList2 } = useSelector(
      (state) => state.addNewContent,
      shallowEqual
    );
    const { menuFormFields, menuFormTypeList } = useSelector(
      (state) => state.addMenuReducer,
      shallowEqual
    );
    const [reload, setReload] = useState(false)
    const [mainMenuValue, setMainMenuValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");
  const [subMenuValue2, setSubMenuValue2] = useState("");
  const [formTitleName, setFormTitleName] = useState("");

  const [descInEnglish, setDescInEnglish] = useState("")
  const [descInMarathi, setDescInMarathi] = useState("")
  const [descInHindi, setDescInHindi] = useState("")
  const [selectedFormContent, setSelectedFormContent] = useState("")

  let formOptions = [
    "common_format_content",
    "office_bearer_content",
    "results-content",
    "common_format2_content"
]
  const [tableQualumnList, setTableQualumnList] = useState([])
  const [copyMenuFormFields, setCopyMenuFormFields] = useState([])

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "common_format_content"
  );
  const [showFormModel, setShowFormModel] = useState(false)
  const [isUnderConstruction, setIsUnderConstruction] = useState(false);
  const [showInWebsite, setShowInWebsite] = useState(false);
  const [showInHeader, setShowInHeader] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [showColumn, setShowColumn] = useState([]);
  const [selectedMenuItemId, setSelectedMenuItemID] = useState(null);
  const [isMainMenu, setIsMainMenu] = useState(true);
  const [errors, setErrors] = useState({});
  const [sortingMenuList, setSortingMenuList] = useState([])
  
  const isNotEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const isEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const handleMainMenu = (e) => {
    setMainMenuValue(e.target.value);
    const getComponent =
      mainMenuList && mainMenuList.length > 0
        ? mainMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue("");
    setSubMenuValue2("");
    dispatch(getSubMenuListByMid(toInteger(e.target.value)));
  };

  const handleSubMenu = (e) => {
    setSubMenuValue(e.target.value);
    const getComponent =
      subMenuList && subMenuList.length > 0
        ? subMenuList.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2("");
    dispatch(getSubMenuList2ByMid(toInteger(e.target.value)));
  };

  const handleSubMenu2 = (e) => {
    const getComponent =
      subMenuList2 && subMenuList2.length > 0
        ? subMenuList2.find((option) => option.mid === parseInt(e.target.value))
        : {};
    setSelectedMenuItem(
      getComponent && getComponent.component ? getComponent.component : ""
    );
    setSelectedMenuItemID(
      getComponent && getComponent.mid ? getComponent.mid : ""
    );
    setFormTitleName(
      getComponent && getComponent.name ? getComponent.name : ""
    );
    setSubMenuValue2(e.target.value);
  };

  const handleFormtypeSelect = (e)=> {
    setErrors((prevErrData) => ({
      ...prevErrData,
      [e.target.name]: true,
    }));
    setSelectedFormContent(e.target.value)
    
    dispatch(getFormFields(e.target.value));
  }

  const handleDescMarathiChange = (fieldName, newDisplayName) =>{
    const updatedRowList = tableQualumnList.length>0 ? tableQualumnList.map(row => {
      if (row.fieldName === fieldName) {
        return {
          ...row,
          displayNameMr: newDisplayName,
        };
      }
      return row;
    }) :[]

    setTableQualumnList(updatedRowList);

  }

  const handleDescHindiChange = (fieldName, newDisplayName) =>{
    const updatedRowList = tableQualumnList.length>0 ? tableQualumnList.map(row => {
      if (row.fieldName === fieldName) {
        return {
          ...row,
          displayNameHin: newDisplayName,
        };
      }
      return row;
    }) :[]

    setTableQualumnList(updatedRowList);

  }

  const handleDescEnglishChange = (fieldName, newDisplayName) => {
    // Update the editedRowList state with the new display name
    const updatedRowList = tableQualumnList.length>0 ?tableQualumnList.map(row => {
      if (row.fieldName === fieldName) {
        return {
          ...row,
          displayName: newDisplayName,
        };
      }
      return row;
    }):[]

    setTableQualumnList(updatedRowList);
  };

  const handleCancel = () =>{
    setReload(prev=>!prev);
    setSelectedFormContent("")
    setMainMenuValue("");
    setSubMenuValue("");
    setSubMenuValue2("");
    setShowInHeader(false);
    setShowInWebsite(false);
    setIsUnderConstruction(false);
    setDescInEnglish("")
    setDescInMarathi("")
    setDescInHindi("")
  }

  useEffect(()=>{
    if(showInHeader===true && allMenuItems.headers){
      setSortingMenuList([...allMenuItems.headers,{mid:500, name:"current menu"}]);
    }else if(showInHeader===false && allMenuItems.footers){
      setSortingMenuList([...allMenuItems.footers,{mid:500, name:"current menu"}]);
    }
    
  },[showInHeader]);
  // console.log(subMenuList, mainMenuList, subMenuList2)

  useEffect(()=>{
    // console.log(subMenuList, mainMenuList, subMenuList2)
    if(!isMainMenu && mainMenuValue && subMenuValue){
      setSortingMenuList([...subMenuList2,{mid:500, name:"current menu"}])
    }else if(!isMainMenu && mainMenuValue){
      setSortingMenuList([...subMenuList,{mid:500, name:"current menu"}])
    }
  },[subMenuList, subMenuList2]);


  const submitMenuCreate = () =>{
    // console.log('sortingMenuList',sortingMenuList)
    let cm = sortingMenuList.findIndex((sm)=>sm.name=='current menu');
    const newErrors = {};
    if (isNotEmptyOrNull(selectedFormContent)) {
      newErrors.formType = true;
    }
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }
    if (isNotEmptyOrNull(descInHindi)) {
      newErrors.descInHindi = true;
    }
    // if (isNotEmptyOrNull(publicationDate)) {
    //   newErrors.publicationDate = true;
    // }
    // if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
    //   newErrors.fileExpDate = true;
    // }
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      let editedColumn = tableQualumnList.length>0 ? tableQualumnList.filter((col, index)=>{
        if(showColumn[index]){
          return col
        }
      }) :[]

      let ec = editedColumn.length >0 ?editedColumn.map((ecol, index)=>{
        return {...ecol, order: index+1}
      }):[]
      let sortedMenu= sortingMenuList.length >0 ?sortingMenuList.map((ecol, index)=>{
        return {...ecol, rootOrder: index+1}
      }):[]
      let filteredMenu = sortedMenu.filter(item => item.name !== "current menu");
      if(editedColumn.length >0){
         let payload = {
          name: descInEnglish,
          nameMr: descInMarathi,
          nameHin: descInHindi,
          parentId: !isMainMenu? selectedMenuItemId : null,
          showInHeader,
          showInWebsite,
          isActive: isActive,
          underConstruction: isUnderConstruction,
          showInAdminPortal: true,
          component: selectedFormContent,
          rootOrder: cm+1,
          fields: [
              ...ec
          ],
          menuOrder: [...filteredMenu]
      }
      dispatch(addNewMenuData(payload))
      // console.log('payload',payload)
      setTableQualumnList([]);
      handleCancel();
      }else{
        toastr.warning("Please select minimum 1 column.");
      }
     
    } else {
      toastr.warning("Please enter all required fields.");
    }
  
  }
  const moveRow = (index, direction) => {
    const newPosition = index + direction;

    if (newPosition >= 0 && newPosition < copyMenuFormFields.length) {
      const updatedData = [...copyMenuFormFields];
      const [movedRow] = updatedData.splice(index, 1);
      updatedData.splice(newPosition, 0, movedRow);
      setCopyMenuFormFields(updatedData);
    }
    if (newPosition >= 0 && newPosition < tableQualumnList.length) {
      const updatedDatat = [...tableQualumnList];
      const [movedRow] = updatedDatat.splice(index, 1);
      updatedDatat.splice(newPosition, 0, movedRow);
      setTableQualumnList(updatedDatat);
    }
  };

  useEffect(()=>{
    setTableQualumnList(menuFormFields)
    setCopyMenuFormFields(menuFormFields)
  },[menuFormFields])


  const handleMoveUp = (index) => {
    if (index > 0) {
      const newData = [...sortingMenuList];
      [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
      setSortingMenuList(newData);
    }
  };

  const handleMoveDown = (index) => {
    if (index < sortingMenuList.length - 1) {
      const newData = [...sortingMenuList];
      [newData[index], newData[index + 1]] = [newData[index + 1], newData[index]];
      setSortingMenuList(newData);
    }
  };
  useEffect(()=>{
    if(allMenuItems.footers){
      // console.log('allMenuItems',allMenuItems)
      setSortingMenuList([...allMenuItems.footers,{mid:500, name:"current menu"}]);
    }
    // setSortingMenuList([...mainMenuList,{mid:500, name:"current menu"}]);
  },[allMenuItems]);

  useEffect(()=>{
    if(tableQualumnList.length>0){
      setShowColumn(Array(tableQualumnList.length).fill(true)) ;
    }
  },[tableQualumnList.length])

  useEffect(() => {
    dispatch(getMenuItems());
    dispatch(getMenuListByMid());
    dispatch(getFormTypeList());
    if(tableQualumnList.length>0){
      setShowColumn(Array(tableQualumnList.length).fill(true)) ;
    }
    
  }, [dispatch]);

  return (
    <div>
      <p></p>
      <div className="card">
        <div className="card-header">
          <strong>Add New Menu</strong>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-3">Add as main menu: </div>
            <div className="col">
              <input
                id="showInWebsite"
                name="showInWebsite"
                checked={isMainMenu}
                onChange={(e) => {
                  setShowInHeader(!e.target.checked) 
                  setIsMainMenu(e.target.checked)}}
                type="checkbox"
              />
            </div>
          </div>
          {!isMainMenu && "OR"}
          {!isMainMenu &&<form className='card p-2' noValidate>
            <div className="form-row">
                <div className="col-sm-6 form-group">
                {/* {console.log('subMenuList',subMenuList, mainMenuList)} */}
                <label htmlFor="select-main-menu">Select Main Menu</label>
                {/* <span className="text-danger">*</span> */}
                <select
                    className="form-control"
                    id="select-main-menu"
                    name="select-main-menu"
                    placeholder="choose one"
                    required
                    value={mainMenuValue}
                    onChange={handleMainMenu}
                >
                    <option value={""}>Choose one...</option>
                    {mainMenuList &&
                    mainMenuList.length > 0 &&
                    mainMenuList.map((menu, index) => {
                        return (
                        <option key={index} value={menu.mid}>
                            {menu.name}
                        </option>
                        );
                    })}
                </select>
                </div>
                </div>
            <div className="form-row">
                <div className="col-sm-6 form-group">
                {mainMenuValue &&
                    mainMenuValue !== "" &&
                    subMenuList &&
                    subMenuList.length > 0 && (
                    <div>
                        <label htmlFor="select-sub-menu">Select Menu</label>
                        {/* <span className="text-danger">*</span> */}
                        <select
                        className="form-control"
                        id="select-sub-menu"
                        name="select-sub-menu"
                        placeholder="choose one"
                        required
                        value={subMenuValue}
                        onChange={handleSubMenu}
                        >
                        {/* {console.log('subMenuList',subMenuList)} */}
                        <option value={""}>Choose one...</option>
                        {subMenuList &&
                            subMenuList.length > 0 &&
                            subMenuList.map((menu, index) => {
                            return (
                                <option key={index} value={menu.mid}>
                                {menu.name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                    )}
                </div>
            
                {/* <div className="col-sm-6 form-group">
                    {subMenuValue &&
                    subMenuValue !== "" &&
                    subMenuList2 &&
                    subMenuList2.length > 0 && (
                        <div>
                        <label htmlFor="select-sub-menu">Select Sub Menu</label>
                        <select
                            className="form-control"
                            id="select-sub-menu"
                            name="select-sub-menu"
                            placeholder="choose one"
                            required
                            value={subMenuValue2}
                            onChange={handleSubMenu2}
                        >
                            <option value={""}>Choose one...</option>
                            {subMenuList2 &&
                            subMenuList2.length > 0 &&
                            subMenuList2.map((menu, index) => {
                                return (
                                <option key={index} value={menu.mid}>
                                    {menu.name}
                                </option>
                                );
                            })}
                        </select>
                        </div>
                    )}
                </div> */}
            </div>
            </form>}

            
          <hr className="dropdown-divider" />

          <div className='row'>
            <div className='col-3  p-2'>
              <label  >Select Form Type </label><span className="text-danger">*</span>:
            </div>
            <div className='col-3 pl-2 pr-2' >  
              <select 
                className="form-control"
                id="formType"
                name="formType"
                placeholder="Choose form type"
                onChange={handleFormtypeSelect}
                value={selectedFormContent}
                onBlur={()=>
                  setErrors((prevErrData) => ({
                      ...prevErrData,
                      formType: true,
                    })
                  )
                }
              >
                <option  value={""}> Choose form type</option>
                {menuFormTypeList.length>0 && menuFormTypeList.map((op, index)=>{
                  return <option key={index} value={op}> {op}</option>
                })}
              </select>
              {errors.formType && isEmptyOrNull(selectedFormContent) && (
              <span className="text-danger">Field is Required</span>
            )}
            </div >
            <div className='col-1' >
              <button 
                className="btn btn-primary btn-sm m-1" 
                onClick={()=>{ setShowFormModel(true)}}
              >
              View
              </button>
            </div >

          </div>
          
          <div className='row'>
            <div className='col-3'>
              <label className="pt-3" >Menu Name (English) </label><span className="text-danger">*</span>:
            </div>
            <div className='col-4 p-2'>
              <input 
                className="form-control" 
                placeholder='Enter menu name' 
                value={descInEnglish}  
                onChange={(e) => {
                  setDescInEnglish(e.target.value);
                }}
                onBlur={()=>
                  setErrors((prevErrData) => ({
                      ...prevErrData,
                      descInEnglish: true,
                    })
                  )
                }
              ></input>
              {errors.descInEnglish && isEmptyOrNull(descInEnglish) && (
              <span className="text-danger">Field is Required</span>
            )}
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <label className="pt-3" >Menu Name (Marathi)</label><span className="text-danger">*</span>:
            </div>
            <div className='col-4 p-2'>
                      <ReactTransliterate
                        value={descInMarathi}
                        name="descInMarathi"
                        onChangeText={(e) => {
                          setDescInMarathi(e);
                        }}
                        onBlur={()=>
                          setErrors((prevErrData) => ({
                              ...prevErrData,
                              descInMarathi: true,
                            })
                          )
                        }
                        lang="mr"
                        className={`form-control`}
                        placeholder='Enter menu name'
                      />
                      {errors.descInMarathi && isEmptyOrNull(descInMarathi) && (
                        <span className="text-danger">Field is Required</span>
                      )}
                    </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <label className="pt-3" >Menu Name (Hindi)</label><span className="text-danger">*</span>:
            </div>
            <div className='col-4 p-2'>
                      <ReactTransliterate
                        value={descInHindi}
                        name="descInHindi"
                        onChangeText={(e) => {
                          setDescInHindi(e);
                        }}
                        onBlur={()=>
                          setErrors((prevErrData) => ({
                              ...prevErrData,
                              descInHindi: true,
                            })
                          )
                        }
                        lang="mr"
                        className={`form-control`}
                        placeholder='Enter menu name'
                      />
                      {errors.descInHindi && isEmptyOrNull(descInHindi) && (
                        <span className="text-danger">Field is Required</span>
                      )}
                    </div>
          </div>
          <div className="row pt-4">
          <div className="col-2">Show in Website : </div>
          <div className="col-1">
            <input
              id="showInWebsite"
              name="showInWebsite"
              checked={showInWebsite}
              onChange={(e) => setShowInWebsite(e.target.checked)}
              type="checkbox"
            />
          </div>
        {/* </div>
        <div className="row"> */}
          <div className="col-2">Show in Header :</div>
          <div className="col">
            <input
              id="showInHeader"
              name="showInHeader"
              disabled={!isMainMenu}
              checked={showInHeader}
              onChange={(e) => setShowInHeader(e.target.checked)}
              type="checkbox"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2 pr-1">Under Construction :</div>
          <div className="col-1">
            <input
              id="isUnderConstruction"
              name="isUnderConstruction"
              checked={isUnderConstruction}
              onChange={(e) => setIsUnderConstruction(e.target.checked)}
              type="checkbox"
            />
          </div>
        {/* </div>
        <div className="row"> */}
          <div className="col-2">Active :</div>
          <div className="col-1">
            <input
              id="isActive"
              name="isActive"
              type="checkbox"
              onChange={(e) => setIsActive(e.target.checked)}
              checked={isActive}
            />
          </div>
        </div>

          {/* menu order */}
          {isMainMenu && sortingMenuList && sortingMenuList.length>0 && <div className="row">
              <label className='pt-2'>Set and adjust menu order:  </label><br/>
              <div className="table-responsive w-50">
                <table className="table dataTable p-0" id="datatable">
                  <thead className=' font-weight-bold'> 
                    <tr>
                      <th className=''>Order</th>
                      <th className=''>Menu</th>
                      <th className='text-center' style={{width:"80px"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {sortingMenuList.map((ml, index)=>{
                    return <tr key={index}>
                      <td className='pt-2'>{index+1}</td>
                      <td className={`pt-2 ${ml.name=='current menu'? 'text-danger': ''}`}>{ml.name}</td>
                      <td className='p-0'>
                        <div className='p-0' style={{margin:"auto"}}>
                      <IconButton onClick={() => handleMoveUp(index)} disabled={ml.name!=='current menu'} color="primary" aria-label="add an alarm">
                        <ArrowUpward />
                      </IconButton>
                      <IconButton onClick={() => handleMoveDown(index)} disabled={ml.name!=='current menu'} color="primary" aria-label="add an alarm">
                        <ArrowDownward />
                      </IconButton>
                      </div>
                        {/* <button onClick={() => handleMoveUp(index)} disabled={index === 0}>
                          Move Up
                        </button>
                        <button onClick={() => handleMoveDown(index)} disabled={index === sortingMenuList.length - 1}>
                          Move Down
                        </button> */}
                      </td>
                    </tr>
                  })}
                  </tbody>
                </table>
              </div>
            </div>}
            {!isMainMenu && sortingMenuList && sortingMenuList.length>0 && <div className="row">
              <label className='pt-2'>Set and adjust menu order(new):  </label><br/>
              <div className="table-responsive w-50">
                <table className="table dataTable p-0" id="datatable">
                  <thead className=' font-weight-bold'> 
                    <tr>
                      <th className=''>Order</th>
                      <th className=''>Menu</th>
                      <th className=' text-center' style={{width:"80px"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {sortingMenuList.map((ml, index)=>{
                    return <tr key={index}>
                      <td className='pt-2'>{index+1}</td>
                      <td className={`pt-2 ${ml.name=='current menu'? 'text-danger': ''}`}>{ml.name}</td>
                      <td className='p-0'>
                        <div className='p-0' style={{margin:"auto"}}>
                      <IconButton onClick={() => handleMoveUp(index)} disabled={ml.name!=='current menu'} color="primary" aria-label="add an alarm">
                        <ArrowUpward />
                      </IconButton>
                      <IconButton onClick={() => handleMoveDown(index)} disabled={ml.name!=='current menu'} color="primary" aria-label="add an alarm">
                        <ArrowDownward />
                      </IconButton>
                      </div>
                        {/* <button onClick={() => handleMoveUp(index)} disabled={index === 0}>
                          Move Up
                        </button>
                        <button onClick={() => handleMoveDown(index)} disabled={index === sortingMenuList.length - 1}>
                          Move Down
                        </button> */}
                      </td>
                    </tr>
                  })}
                  </tbody>
                </table>
              </div>
            </div>}

{tableQualumnList.length>0 && <>
  <label className='pt-2'>Select and adjust Table Column name ( Order of column is top to bottom ) :  </label><br/>
          <div className="table-responsive">
          <table className="table dataTable" id="datatable">
          <thead className=' font-weight-bold'>
              <tr>
                <td style={{maxWidth:'70px'}}>Show/Hide</td>
                {/* <td>Order</td> */}
                <td>Previous Name(English)</td>
                <td>New Name</td>
                <td>Previous Name(Marathi)</td>
                <td>New Name</td>
                <td>Previous Name(Hindi)</td>
                <td>New Name</td>
                {/* <td>Adjust Order</td> */}
              </tr>
          </thead>
          {tableQualumnList.length>0 && tableQualumnList.map((qualumn, index)=>{
            return <tr key={index}>
              
              <td style={{maxWidth:'70px'}}>
              <input
                        id={`check${qualumn.fieldName}`}
                        name={`check${qualumn.fieldName}`}
                        className='mt-3 ml-2'
                        checked={showColumn[index]}
                        onChange={(e) => setShowColumn(prev=>{ 
                          prev[index]=e.target.checked
                          return [...prev]
                        })}
                        type="checkbox"
                      />
              </td>
              {/* {console.log(showColumn[index])}
              <td > <div className='mt-2'>{index +" "+ showColumn[index]}</div></td> */}
              <td className=' pt-4'>
              {copyMenuFormFields.length > 0 && copyMenuFormFields[index] && copyMenuFormFields[index].displayName}
              </td>
              <td>
              <input disabled={!showColumn[index]}
                       value={qualumn.displayName}
                       onChange={(e) => handleDescEnglishChange(qualumn.fieldName, e.target.value)}
                      className="form-control mt-2"  defaultValue={qualumn.displayName}></input>
              </td>
              <td className=' pt-4'>
              {copyMenuFormFields.length > 0 && copyMenuFormFields[index] && copyMenuFormFields[index].displayNameMr}
              </td>
              <td>
              <ReactTransliterate
                        disabled={!showColumn[index]}
                        value={qualumn.displayNameMr}
                        onChangeText={(e) => {
                          handleDescMarathiChange(qualumn.fieldName, e)
                        }}
                        lang="mr"
                        className={`form-control `}
                      />
              </td>
              {/* hindi */}
              <td className=' pt-4'>
              {copyMenuFormFields.length > 0 && copyMenuFormFields[index] && copyMenuFormFields[index].displayNameHin}
              </td>
              <td>
              <ReactTransliterate
                        disabled={!showColumn[index]}
                        value={qualumn.displayNameHin}
                        onChangeText={(e) => {
                          handleDescHindiChange(qualumn.fieldName, e)
                        }}
                        lang="mr"
                        className={`form-control `}
                      />
              </td>
              {/* <td>
                <Button variant="contained" onClick={() => moveRow(index, -1)}><i className="fa fa-arrow-up"
                                                            mtitle="Move Up"></i></Button>
                <Button variant="contained" onClick={() => moveRow(index, 1)}><i className="fa fa-arrow-down"
                                                             title="Move Down"></i></Button>
              </td> */}
            </tr>
          })}
          </table>
          </div>
</>}
          

          {/* {tableQualumnList.length>0 && tableQualumnList.map((qualumn, index)=>{
            return <div key={index} className='row'>
                    <div className='col-1'>
                      <input
                        id={`check${qualumn.fieldName}`}
                        name={`check${qualumn.fieldName}`}
                        className='mt-4 ml-4'
                        checked={showColumn[index]}
                        onChange={(e) => setShowColumn(prev=>{ 
                          prev[index]=e.target.checked
                          return [...prev]
                        })}
                        type="checkbox"
                      />
                    </div>
                    <div className='col-2'>
                      <label className="pt-3" >{qualumn.displayName}: </label>
                    </div>
                    <div className='col-3 p-2'>
                      <input disabled={!showColumn[index]}
                       value={qualumn.displayName}
                       onChange={(e) => handleDescEnglishChange(qualumn.fieldName, e.target.value)}
                      className="form-control"  defaultValue={qualumn.displayName}></input>
                    </div>
                    <div className='col-2'>
                      <label className="pt-3" >{qualumn.displayNameMr}: </label>
                    </div>
                    <div className='col-3 p-2'>
                      <ReactTransliterate
                        disabled={!showColumn[index]}
                        value={qualumn.displayNameMr}
                        onChangeText={(e) => {
                          handleDescMarathiChange(qualumn.fieldName, e)
                        }}
                        lang="mr"
                        className={`form-control`}
                      />
                    </div>

                  </div>
          })} */}
          
          

         

          


          <div className="">
            <div className="float-right ">
                <>
                <button 
                    className="btn btn-danger " 
                    onClick={()=>handleCancel()}
                  >
                    <i className="fa fa-times-circle">&nbsp;</i>Reset
                  </button> &nbsp;{" "}
                  <button 
                      className="btn btn-primary float-right" 
                      onClick={submitMenuCreate}
                    >
                      <i className="fa fa-check-circle">&nbsp;</i>Create Menu
                    </button>
                  &nbsp;{" "}
                </>
            </div>
          </div>
          {/* <div className="form-row">
              <div className="col-sm-11">
                  <>
                    <button 
                      className="btn btn-primary float-right" 
                      onClick={submitMenuCreate}
                    >
                      <i className="fa fa-check-circle">&nbsp;</i>Create Menu
                    </button>
                    &nbsp;{" "}
                  </>
              </div>
            </div> */}
        </div>
      </div>
      <p></p>
      {}
      {/* {noMenuSelectedError !== "" && (
        <div className="alert alert-warning" role="alert">
          {noMenuSelectedError}
        </div>
      )} */}

      <p></p>
      <FormDemoModel showModal={showFormModel} setShowModal={setShowFormModel} selectedFormContent={selectedFormContent}></FormDemoModel>
    </div>
  )
}

export default AddMenu