import { shallowEqual, useSelector } from "react-redux";
import "../../styles/application_footer.css";
import w2logo from "../../images/w2logo.png";
import india from "../../images/India.png";
import mGandhi from "../../images/Gandhi150.png";
import nvsp from "../../images/NVSP.png";
import mii from "../../images/mii.png";
import bharat from "../../images/swachBharatlogo.png"
import { Link } from "react-router-dom";
import { LAST_UPDATED_ON, DISCLAIMER, DISCLAIMER_TEXT } from "../labels/footer.json"
import { formatDateToDDMMYY } from "../../helpers/Datetime";
import { getLabelByLang, getMenuNameByLang } from "../../helpers/multiLingual";
import ConvertTextLang, { LabelByLang, MenuNameByLang } from "./ConvertTextLang";
import TextToSpeech from "./TextToSpeech";
import Carousel from 'react-bootstrap/Carousel';
import { CommercialImg } from "../constants/urlconst";

export default function ApplicationFooter() {
  const { allMenuItems, homePageData, webLanguage } = useSelector((state) => state.common, shallowEqual);

  // let { accessToken, apiRepoVersion } = useSelector(state => state.loginReducer, shallowEqual);
  // const session = JSON.parse(sessionStorage.getItem("session"));

  return (
    // accessToken || (session && session.jwtResponse.token) ?
    // ""
    // :
    // <div className="footer" style={{display:"flex", justifyContent:"space-between"}}>
    //     <div className=" text-center">
    //       <span>Disclaimer</span>
    //     </div>
    //     <div className="text-center">
    //       <span>This is the official website of Maharashtra Public Service Commission. All Rights Reserved</span>
    //     </div>
    //     <div className="col-sm-1 text-center" style={{ fontSize:"10px", marginTop:"5px" }}>
    //       {process.env.REACT_APP_VERSION} {apiRepoVersion}
    //     </div>
    // </div>
    <div className="footer">
      <div className="middlemnuerap">
        <div className="container">
          <ul>
            {allMenuItems.footers &&
              allMenuItems.footers.length > 0 &&
              allMenuItems.footers.map((menuItem) => {
                return menuItem.submenus.length > 0 ? (
                  menuItem.submenus.map((subitem) => {
                    return (
                      <li key={subitem.mid} >
                        <Link
                          className="text-uppercase"
                          to={`/${subitem.route}/${subitem.mid}`}
                        >
                          <TextToSpeech text={subitem.name}><MenuNameByLang obj={subitem} /></TextToSpeech>
                          {/* {getMenuNameByLang(subitem, webLanguage)} */}
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li key={menuItem.mid}>
                    <Link
                      className="text-uppercase"
                      to={`/${menuItem.route}/${menuItem.mid}`}
                    >
                      {/* {getMenuNameByLang(menuItem, webLanguage)} */}
                      <TextToSpeech text={menuItem.name}><MenuNameByLang obj={menuItem} /></TextToSpeech>
                    </Link>
                  </li>
                )
                //   </>
                // );
              })}
          </ul>
        </div>
      </div>
      {homePageData && homePageData.footer && homePageData.footer.length>0 && (
        <div className="well well-sm col-sm-12">
          <Carousel indicators={false} controls={false}>
            <Carousel.Item>
              {homePageData.footer.map((footerdata, index)=>{

                if(footerdata.url!==''){
                  return <a href={footerdata.url}
                            target="_blank"  
                            key={index}
                            // onclick="return confirmPortalRedirection();"
                          >
                            <img title={footerdata.title} src={`${CommercialImg}${footerdata.id}`} 
                            className="img img-responsive img-rounded custom-img" 
                            style={{ border: "2px solid black" }} alt="India Portal" />
                          </a>
                }else{
                  return  <img 
                            key={index}
                            title={footerdata.title} src={`${CommercialImg}${footerdata.id}`} 
                            className="img img-responsive img-rounded custom-img" 
                            style={{ border: "2px solid black" }} alt="India Portal" />
                }
              })}
            </Carousel.Item>
          </Carousel>
        </div>
      )}
      <div className="upwrapperfull">
        <div className="container">
          <div className="updatewrap">
            <div className="updatebox upleftbox">
              <p>
                <TextToSpeech text={LAST_UPDATED_ON.descInEnglish + formatDateToDDMMYY(allMenuItems.lastUpdatedOn, webLanguage).replace(/-/g, 'dash')}><LabelByLang obj={LAST_UPDATED_ON} /> :{" "}
                  {/* <TextToSpeech text = {formatDateToDDMMYY(allMenuItems.lastUpdatedOn, webLanguage)}> */}
                  {allMenuItems.lastUpdatedOn &&
                    formatDateToDDMMYY(allMenuItems.lastUpdatedOn, webLanguage)
                  }
                </TextToSpeech>
              </p>
            </div>
            <div className="updatebox uprightbox">
              {/* <p>
                          Total No.of Visitors:237247
                      </p> */}
              {/* <p style="display: flex; justify-content: center; align-items: center">
                  <span className="webstitle" style="padding-right: 8px">
                    Total No.of Visitors (Since Dec,21,2020):
                  </span>
                  <span id="CounterVisitor" className="meter">
                    <span>1</span>
                    <span>0</span>
                    <span>0</span>
                  </span>
                </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="bottom_wrap">
            <ul className="bleftbox">
              <li>
                <a href="# "><TextToSpeech text={DISCLAIMER.descInEnglish}><LabelByLang obj={DISCLAIMER} /></TextToSpeech></a>
              </li>
            </ul>
            <p className="bmiddlebox">
              <TextToSpeech text={DISCLAIMER_TEXT.descInEnglish}> <LabelByLang obj={DISCLAIMER_TEXT} /></TextToSpeech>
            </p>
            <ul className="brightbox">
              <li>
                <a href="# ">
                  <img src={w2logo} alt="w2Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
