import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ReactTransliterate } from "react-transliterate";
import { FIELD_REQUIRED } from "../labels/formLabels.json";
import { useNavigate } from "react-router-dom";

import "react-transliterate/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  addNewContent,
  approveContent,
  rejectContent,
  updateContent,
} from "../../AddNewContent/actions/addNewContentActions";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { isUserAdmin, isUserApprover, isUserReviewer } from "../../auth/sessionManager";
import API from "../../../helpers/API";
import { openPdfInNewTab } from "../../../helpers/ShowPdf";
import ImageToDisplay from "./ImageToDisplay";
import { FileURL } from "../../../common/constants/urlconst";
import { hideLoader, showLoader } from "../../../common/actions/commonActions";

const OfficeBearerContent = ({
  formTitle,
  actionType = "create",
  selectedMid,
  publishedContentDetails,
  handleCancel,
  resetPageData,
  isDemo
}) => {
  const { designationList} = useSelector(
    (state) => state.addNewContent,
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mid, setMid] = useState(0);
  const [designation, setDesignation] = useState("")
  const [educationQualification, setEducationQualification] = useState("")
  const [educationQualificationMr, setEducationQualificationMr] = useState("")
  const [educationQualificationHin, setEducationQualificationHin] = useState("")
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [retirementDate, setRetirementDate] = useState(new Date());

  const [descInEnglish, setDescInEnglish] = useState("");
  const [descInMarathi, setDescInMarathi] = useState("");
  const [descInHindi, setDescInHindi] = useState("");
  const [englishFile, setEnglishFile] = useState(null);
  const [hindiFile, setHindiFile] = useState(null);
  const [englishMessageFile, setEnglishMessageFile] = useState(null);
  const [marathiMessageFile, setMarathiMessageFile] = useState(null);
  const [hindiMessageFile, setHindiMessageFile] = useState(null);
  const [fileExpDate, setFileExpDate] = useState(new Date());  
  const [isAdvtActive, setIsAdvtActive] = useState(true);
  const [showInLatestUpdate, setShowInLatestUpdate] = useState(false);
  const [showInImpNotice, setShowInImpNotice] = useState(false);
  const [remark, setRemark] = useState("");

  const [isTouched, setIsTouched] = useState({
    descInMarathi: false,
    descInEnglish: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name)
    setIsTouched((prevFormData) => ({
      ...prevFormData,
      [name]: true,
    }));
    setErrors((prevErrData) => ({
      ...prevErrData,
      [name]: true,
    }));
  };
  const isNotEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };

  const isEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  const handleApprove = (e, id, action) => {
    e.preventDefault();
    dispatch(approveContent(id, action, resetPageData));
  };
  const handleReject = (e, id, action) => {
    e.preventDefault();
    dispatch(rejectContent(id, action, resetPageData));
  };

  const getDownloadLink = (e,fileId, language='english') =>{
    e.preventDefault();
    dispatch(showLoader())
    let tokent = sessionStorage.getItem('token')
    API.get(`web/api/v1/admin/downloadFile/${language}/${fileId}`, {
      headers: {
        'Authorization': `Bearer ${tokent}`
      },
    }).then((res) => {
      if(res){
        openPdfInNewTab(res.data.pdfData,()=>{dispatch(hideLoader())})
      }else{
        dispatch(hideLoader())
        toastr.error(res.message)
      }
    }).catch((err)=>{
      dispatch(hideLoader())
      toastr.error(err.message)
    });
  }
  const getDownloadMessageLink = (e,fileId, language='english', message) =>{
    e.preventDefault();
    dispatch(showLoader())
    let tokent = sessionStorage.getItem('token')
    API.get(`web/api/v1/downloadbearer/file/${language}/${message}/${fileId}`, {
      headers: {
        'Authorization': `Bearer ${tokent}`
      },
    }).then((res) => {
      if(res){
        openPdfInNewTab(res.data.pdfData,()=>{dispatch(hideLoader())})
      }else{
        toastr.error(res.message)
      }
    }).catch((err)=>{
      toastr.error(err.message)
    });
  }


  const handleUpdateClick = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }
    
    if (isNotEmptyOrNull(descInHindi)) {
      newErrors.descInHindi = true;
    }
    if (isNotEmptyOrNull(joiningDate)) {
      newErrors.joiningDate = true;
    }
    if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
      newErrors.fileExpDate = true;
    }
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      dispatch(
        updateContent({
          mid,
          id:publishedContentDetails.id,
          officeBearerJoiningDate: joiningDate,
          officeBearerRetirementDate: retirementDate,
          officeBearerEducationalQualification:educationQualification,
          officeBearerEducationalQualificationMr:educationQualificationMr,
          officeBearerEducationalQualificationHin:educationQualificationHin,
          designation: designation,
          isImageUpdated: englishFile ? 1 : 0,
          englishFileName:  "",
          marathiFileName: "",
          englishMessageFile: englishMessageFile,
          marathiMessageFile: marathiMessageFile,
          hindiMessageFile,
          descInEnglish,
          descInMarathi,
          descInHindi,
          englishFile:englishFile,
          marathiFile:englishFile,
          fileExpDate,
          showInLatestUpdate,
          showInImpNotice,
          isActive : isAdvtActive,
          remark,
        }, resetPageData)
      );
    } else {
      toastr.warning("Please enter all required fields.");
    }
  };

  const handleCreateClick = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }
    
    if (isNotEmptyOrNull(descInHindi)) {
      newErrors.descInHindi = true;
    }
    if (isNotEmptyOrNull(englishFile)) {
      newErrors.englishFile = true;
    }
    if (isNotEmptyOrNull(joiningDate)) {
      newErrors.joiningDate = true;
    }
    if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
      newErrors.fileExpDate = true;
    }
    setErrors(newErrors);

    if (!Object.values(newErrors).includes(true)) {
      dispatch(
        addNewContent({
          mid,
          officeBearerJoiningDate: joiningDate,
          officeBearerRetirementDate: retirementDate,
          officeBearerEducationalQualification:educationQualification,
          officeBearerEducationalQualificationMr:educationQualificationMr,
          officeBearerEducationalQualificationHin:educationQualificationHin,
          designation: designation,
          isImageUpdated: englishFile ? 1 : 0,
          englishFileName:  "",
          marathiFileName: "",
          englishFile:englishFile,
          marathiFile:englishFile,
          englishMessageFile: englishMessageFile,
          marathiMessageFile: marathiMessageFile,
          hindiMessageFile,
          descInEnglish,
          descInMarathi,
          descInHindi,
          fileExpDate,
          showInLatestUpdate,
          showInImpNotice,
          isActive : isAdvtActive,
          remark,
        }, resetPageData)
      );
    } else {
      toastr.warning("Please enter all required fields.");
    }
  };

  useEffect(() => {
    // console.log('ob',publishedContentDetails, actionType);
    setMid(selectedMid);
    {
      if (actionType === "editing" && publishedContentDetails) {
        setMid(publishedContentDetails.mid);
        setJoiningDate(
          publishedContentDetails.officeBearerJoiningDate &&
            new Date(publishedContentDetails.officeBearerJoiningDate)
        );
        setRetirementDate(
          publishedContentDetails.officeBearerRetirementDate &&
            new Date(publishedContentDetails.officeBearerRetirementDate)
        );
        setDescInEnglish(publishedContentDetails.descInEnglish);
        setDescInMarathi(publishedContentDetails.descMr);
        setDescInHindi(publishedContentDetails.descHin);
        setEducationQualification(publishedContentDetails.officeBearerEducationalQualification);
        setEducationQualificationMr(publishedContentDetails.officeBearerEducationalQualificationMr);
        setEducationQualificationHin(publishedContentDetails.officeBearerEducationalQualificationHin);
        setDesignation(publishedContentDetails.designation?.id)
        // setEnglishFileName(publishedContentDetails.englishFileName);
        // setMarathiFileName(publishedContentDetails.marathiFileName);
        setFileExpDate(
          publishedContentDetails.fileExpDate &&
            new Date(publishedContentDetails.fileExpDate)
        );
        setShowInLatestUpdate(publishedContentDetails.showInLatestUpdate);
        setShowInImpNotice(publishedContentDetails.showInImpNotice);
        publishedContentDetails.isActive ? setIsAdvtActive(publishedContentDetails.isActive) :setIsAdvtActive(false)
        setRemark(publishedContentDetails.remark);
        window.scrollTo(0, 0);
      }
    }
  }, [selectedMid]);

  const handleMarathiFileUpload = (e) => {
    setMarathiMessageFile(e.target.files[0]);
  };
  const handleHindiFileUpload = (e) => {
    setHindiMessageFile(e.target.files[0]);
  };
  const handleMarathiEngFileUpload = (e) => {
    setEnglishMessageFile(e.target.files[0]);
  };

  return (
    <>
      <p></p>
      <form noValidate>
        <div className="form-row">
        {!isDemo && <h4>
            <span>
              {actionType !== "editing" ? "Creating new" : "Editing"}{" "}
            </span>
            {actionType !== "editing"
              ? formTitle
              : publishedContentDetails.contentName}
          </h4>}
        </div>
        <p></p>
        
        <div className="form-row">
          <div className="form-group col-5">
            <div>
              <label htmlFor="select-sub-menu">Select Designation</label>
              <span className="text-danger">*</span>
              <select
                className="form-control"
                id="select-sub-menu"
                name="designation"
                placeholder="choose one"
                required
                value={designation}
                onChange={(e)=>{handleChange(e);setDesignation(e.target.value)}}
              >
                <option value={""}>Choose one...</option>
                {designationList &&
                  designationList.length > 0 &&
                  designationList.map((menu, index) => {
                    return (
                      <option key={index} value={menu.id}>
                        {menu.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {errors.designation && isEmptyOrNull(designation) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInEnglish">
              <span>Name (English)</span>
              {/* <span>Name of Examination</span> (English) */}
            </label>
            <span className="text-danger">*</span>
            <input
              className={`form-control ${
                ((isTouched.descInEnglish && isEmptyOrNull(descInEnglish)) ||
                  (errors.descInEnglish && isEmptyOrNull(descInEnglish))) &&
                "is-invalid"
              }`}
              id="descInEnglish"
              name="descInEnglish"
              placeholder="Name in English"
              length="30"
              type="text"
              value={descInEnglish}
              onChange={(e) => {
                setDescInEnglish(e.target.value);
              }}
              onBlur={handleChange}
              required
            />
            {errors.descInEnglish && isEmptyOrNull(descInEnglish) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInMarathi">
              <span>Name (Marathi)</span>
            </label>
            <span className="text-danger">*</span>
            <ReactTransliterate
              name="descInMarathi"
              value={descInMarathi}
              placeholder="Name in Marathi"
              onChangeText={(e) => {
                setDescInMarathi(e);
              }}
              onBlur={handleChange}
              lang="mr"
              className={`form-control ${
                ((isTouched.descInMarathi && isEmptyOrNull(descInMarathi)) ||
                  (errors.descInMarathi && isEmptyOrNull(descInMarathi))) &&
                "is-invalid"
              }`}
            />
            {errors.descInMarathi && isEmptyOrNull(descInMarathi) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInHindi">
              <span>Name (Hindi)</span>
            </label>
            <span className="text-danger">*</span>
            <ReactTransliterate
              name="descInHindi"
              value={descInHindi}
              placeholder="Name in Hindi"
              onChangeText={(e) => {
                setDescInHindi(e);
              }}
              onBlur={handleChange}
              lang="mr"
              className={`form-control ${
                ((isTouched.descInHindi && isEmptyOrNull(descInHindi)) ||
                  (errors.descInHindi && isEmptyOrNull(descInHindi))) &&
                "is-invalid"
              }`}
            />
            {errors.descInHindi && isEmptyOrNull(descInHindi) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInMarathi">
              <span>Education Qualification (English)</span>
              {/* <span>Name of Examination</span> (Marathi) */}
            </label>
            <span className="text-danger">*</span>
            <input
              className={`form-control ${
                ((isTouched.educationQualification && isEmptyOrNull(educationQualification)) ||
                  (errors.educationQualification && isEmptyOrNull(educationQualification))) &&
                "is-invalid"
              }`}
              id="educationQualification"
              name="educationQualification"
              placeholder="Education Qualification"
              length="30"
              type="text"
              value={educationQualification}
              onChange={(e) => {
                setEducationQualification(e.target.value);              }}
              onBlur={handleChange}
              required
            />
            {errors.educationQualification && isEmptyOrNull(educationQualification) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInMarathi">
              <span>Education Qualification (Marathi)</span>
            </label>
            <span className="text-danger">*</span>
            <ReactTransliterate
              name="educationQualification"
              value={educationQualificationMr}
              placeholder="Education Qualification in Marathi"
              onChangeText={(e) => {
                setEducationQualificationMr(e);
              }}
              onBlur={handleChange}
              lang="mr"
              className={`form-control ${
                ((isTouched.educationQualificationMr && isEmptyOrNull(educationQualificationMr)) ||
                  (errors.educationQualificationMr && isEmptyOrNull(educationQualificationMr))) &&
                "is-invalid"
              }`}
            />
            {errors.educationQualificationMr && isEmptyOrNull(educationQualificationMr) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-5">
            <label htmlFor="descInMarathi">
              <span>Education Qualification (Hindi)</span>
            </label>
            <span className="text-danger">*</span>
            <ReactTransliterate
              name="educationQualification"
              value={educationQualificationHin}
              placeholder="Education Qualification in Hindi"
              onChangeText={(e) => {
                setEducationQualificationHin(e);
              }}
              onBlur={handleChange}
              lang="mr"
              className={`form-control ${
                ((isTouched.educationQualificationHin && isEmptyOrNull(educationQualificationHin)) ||
                  (errors.educationQualificationHin && isEmptyOrNull(educationQualificationHin))) &&
                "is-invalid"
              }`}
            />
            {errors.educationQualificationHin && isEmptyOrNull(educationQualificationHin) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-3">
            <label htmlFor="dateOfPublication">Date Of Joining</label>
            <span className="text-danger">*</span>
          </div>
          <div className="input-group col-2">
            <ReactDatePicker
              showIcon
              selected={joiningDate}
              onChange={(date) => setJoiningDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              dropdownMode="select"
              customInput={
                <input
                  className={`form-control ${
                    ((isTouched.joiningDate &&
                      isEmptyOrNull(joiningDate)) ||
                      (errors.joiningDate &&
                        isEmptyOrNull(joiningDate))) &&
                    "is-invalid"
                  }`}
                  id="dateOfPublication"
                  name="dateOfPublication"
                  placeholder="DD-MM-YYYY"
                  required
                  minLength="10"
                  maxLength="10"
                />
              }
            />
            {errors.joiningDate && isEmptyOrNull(joiningDate) && (
              <span className="text-danger">{FIELD_REQUIRED}</span>
            )}
          </div>
        </div>
        <div className="form-row mt-2 mb-2">
          <div className="col-3">
            <label htmlFor="dateOfPublication">Date Of Retirement</label>
          </div>
          <div className="input-group col-2">
            <ReactDatePicker
              showIcon
              selected={retirementDate}
              onChange={(date) => setRetirementDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              dropdownMode="select"
              customInput={
                <input
                  className={`form-control ${
                    ((isTouched.retirementDate &&
                      isEmptyOrNull(retirementDate)) ||
                      (errors.retirementDate &&
                        isEmptyOrNull(retirementDate))) &&
                    "is-invalid"
                  }`}
                  id="dateOfPublication"
                  name="dateOfPublication"
                  placeholder="DD-MM-YYYY"
                  // required
                  minLength="10"
                  maxLength="10"
                />
              }
            />
            {/* {errors.retirementDate && isEmptyOrNull(retirementDate) && (
              <span className="text-danger">{FIELD_REQUIRED}</span>
            )} */}
          </div>
        </div>
        {/* {actionType !== "editing" &&  */}
        {actionType === "editing" && (
          <div className="form-row">
            <div className="col-3">Previous Profile Image:</div>
            <div className="form-group col" style={{height: "100px"}}>
              <div className="custom-file">
                <img 
                  src={`${FileURL}${publishedContentDetails.id}`}
                  alt="profileimg_office_bearers" 
                  height="100" width="150" 
                />
              </div>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="col-3">
          {actionType == "editing" &&  <>Update profile image </>}
            {actionType !== "editing" && <>Select profile image <span className="text-danger">*</span></>}
          </div>
          <div className="form-group col">
            <ImageToDisplay selectedImage = {englishFile} setSelectedImage = {setEnglishFile} />
            {errors.englishFile && !englishFile && (
              <span className="text-danger">Please Select File</span>
            )}
          </div>
        </div>
        {/* } */}
        
       
        {/* message file */}
        {actionType === "editing" && (
          <div className="form-row">
            <div className="col-3">View/Download Previous Message Files:</div>
            <div className="form-group col">
              <div className="custom-file">
                <a
                  href="# "
                  onClick={(e) => {
                    getDownloadMessageLink(e, publishedContentDetails.id, "english", "message");
                  }}
                >
                  English <i className="fa fa-download"></i>
                </a>
                &nbsp;|&nbsp;
                <a
                  href="# "
                  onClick={(e) => {
                    getDownloadMessageLink(e, publishedContentDetails.id, "marathi", "message");
                  }}
                >
                  Marathi <i className="fa fa-download"></i>
                </a>
                &nbsp;|&nbsp;
                <a
                  href="# "
                  onClick={(e) => {
                    getDownloadMessageLink(e, publishedContentDetails.id, "hindi", "message");
                  }}
                >
                  Hindi <i className="fa fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="form-row">
            <div className="col-3">
              Select message file to upload (English)
              {/* <span className="text-danger">*</span> */}
            </div>
            <div className="form-group col">
              <div className="custom-file">
                <input
                  type="file"
                  name="fileUploadEnglish"
                  accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                  onChange={handleMarathiEngFileUpload}
                />
              </div>
              {errors.englishFile && !englishFile && (
                <span className="text-danger">Please Select File</span>
              )}
            </div>
          </div>
        {/* )} */}
        {/* {actionType !== "editing" && ( */}
          <div className="form-row">
            <div className="col-3">
              Select message file to upload (Marathi)
              {/* <span className="text-danger">*</span> */}
            </div>
            <div className="form-group col">
              <div className="custom-file">
                <input
                  type="file"
                  name="marathiFile"
                  accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                  onChange={handleMarathiFileUpload}
                />
              </div>
              {/* {errors.marathiFile && !marathiFile && (
                <span className="text-danger">Please Select File</span>
              )} */}
            </div>
          </div>
          <div className="form-row">
            <div className="col-3">
              Select message file to upload (Hindi)
              {/* <span className="text-danger">*</span> */}
            </div>
            <div className="form-group col">
              <div className="custom-file">
                <input
                  type="file"
                  name="hindiFile"
                  accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                  onChange={handleHindiFileUpload}
                />
              </div>
              {/* {errors.marathiFile && !marathiFile && (
                <span className="text-danger">Please Select File</span>
              )} */}
            </div>
          </div>
        {/* )} */}
       
        <div className="form-row">
          <div className="col-3">Active</div>
          <div className="col">
            <input
              id="isAdvtActive"
              name="isAdvtActive"
              type="checkbox"
              onChange={(e) => setIsAdvtActive(e.target.checked)}
              checked={isAdvtActive}
            />
          </div>
        </div>
        <p></p>
        <div className="form-row">
          <div className="col-sm-12">
            <label htmlFor="remarksText">
              {" "}
              {actionType == "editing" && "Previous "}Remarks:
            </label>
            <textarea
              className="form-control"
              id="remarksText"
              name="remarksText"
              rows="3"
              value={remark}
              onChange={handleRemarkChange}
            ></textarea>
          </div>
        </div>
        <p></p>
        <hr className="dropdown-divider" />
        <p></p>
        <div className="form-row">
          <div className="col-sm-12">
            {(actionType !== "editing" && !isDemo) && (
              <>
                <button className="btn btn-primary" onClick={handleCreateClick}>
                  <i className="fa fa-check-circle">&nbsp;</i>Create
                </button>
                &nbsp;{" "}
              </>
            )}
            {actionType === "editing" && (
              <div>
                <button className="btn btn-primary" onClick={handleUpdateClick}>
                  <i className="fa fa-save">&nbsp;</i>Save
                </button>
                &nbsp;
                {(isUserReviewer() || isUserApprover() || isUserAdmin()) &&
                  publishedContentDetails.status &&
                  publishedContentDetails.status !== "APPROVED" && (
                    <button
                      className="btn btn-success"
                      onClick={(e) =>
                        handleApprove(e, publishedContentDetails.id, "Approve")
                      }
                    >
                      <i className="fa fa-times-circle">&nbsp;</i>Approve
                    </button>
                  )}
                &nbsp;
                {(isUserReviewer() || isUserApprover() || isUserAdmin()) &&
                  publishedContentDetails.status &&
                  publishedContentDetails.status !== "APPROVED" && (
                    <button
                      className="btn btn-danger"
                      onClick={(e) =>
                        handleReject(e, publishedContentDetails.id, "Reject")
                      }
                    >
                      <i className="fa fa-ban">&nbsp;</i>Reject
                    </button>
                  )}
                &nbsp;
                <button className="btn btn-dark" onClick={handleCancel}>
                  <i className="fa fa-times-circle">&nbsp;</i>Close
                </button>
              </div>
            )}
          </div>
        </div>
      </form>

      <div className="cover-spin"></div>
    </>
  );
};

export default OfficeBearerContent;
