import React, { useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authenticateUser } from "../actions/adminLoginActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getSessionToken, getUserDetails } from "../../auth/sessionManager";
import { toastr } from "react-redux-toastr";
import SessionTimeOut from "./SessionTimeOutModal";
import { SET_SESSION_TIMEOUT } from "../../../common/constants/ActionTypes";
import { useIdleTimer } from "react-idle-timer";
import '../../adminstyles/adminContent.css'

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { sessionTimoutModal } = useSelector((state) => state.adminLogin, shallowEqual);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [state, setState] = useState('Active')
    const [count, setCount] = useState(0)
    const [remaining, setRemaining] = useState(0)
    
    let sessionTimeout;

    const handleUserName = (e) =>{
        setUserName(e.target.value)
    }
    const handlePassword = (e) =>{
        setPassword(e.target.value)
    }

    const loginVerified = () =>{
      navigate('/admin/add_new_content')
    }

    const handleModelClose = ()=>{
      dispatch({ type: SET_SESSION_TIMEOUT, payload: false });
    }

    const logout = ()=>{
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user')
      navigate('/admin/login')
      // handleModelShow()

      dispatch({ type: SET_SESSION_TIMEOUT, payload: true });
    }

    function startSessionTimeout() {
      // sessionTimeout = setTimeout(() => {
      // }, 1 * 60 * 1000);
    }


    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(authenticateUser({username:userName, password, loginVerified, startSessionTimeout}))
        // navigate('/admin/add_new_content')
    }
    const onIdle = () => {
      setState('Idle')
      logout();
    }
  
    const onActive = () => {
      setState('Active')
    }
  
    const onAction = () => {
      setCount(count + 1)
    }
  
    // const { getRemainingTime } = useIdleTimer({
    //   onIdle,
    //   onActive,
    //   onAction,
    //   timeout: 30_000,
    //   throttle: 500
    // })

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     setRemaining(Math.ceil(getRemainingTime() / 1000))
    //   }, 500)
  
    //   return () => {
    //     clearInterval(interval)
    //   }
    // })

    useEffect(()=>{
      const isLogin = getSessionToken();
      const isUser = getUserDetails()
      if(isLogin && isUser){
        navigate('/admin/add_new_content')
      }
    },[]);

  return (
    <>
      <p></p>
      <div className="row">
        <div className="col-sm-6 offset-sm-3">
          <div className="card margin_t_150px">
            <div className="card-body">
              <form>
                <div className="form-group row">
                  <label htmlFor="userName" className="col-md-4 col-form-label">
                    User Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={userName}
                      onChange={handleUserName}
                      placeholder="Please enter username"
                      autoComplete="username"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="uPassword" className="col-md-4 col-form-label">
                    Password
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="password"
                      required
                      value={password}
                      onChange={handlePassword}
                      placeholder="Please enter password"
                      autoComplete="current-password"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-4 offset-sm-4">
                    <button
                      type="submit"
                      className="btn btn-primary form-control button-width"
                      name="loginBtn"
                      onClick={handleSubmit}
                      disabled={(userName ===''|| null) || (password ===''|| null)  }
                    >
                      Log In <i className="fa fa-chevron-circle-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="cover-spin"></div>
        </div>
        <SessionTimeOut showModal={sessionTimoutModal} setShowModal={handleModelClose} />
      </div>
    </>
  );
};

export default AdminLogin;
