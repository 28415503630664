import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { InputLabel, MenuItem, Select } from '@mui/material';
import SocailLinkTable from '../../common/components/SocialLinkTable';

const ManageSocailLinks = () => {
    const [formState, setFormState] = useState("new");
    const [selectedMenuDetails, setSelectedMenuDetails] = useState('');
  
    // table states
    const [page, setPage] = useState(0);
    
    const handleChangePage = (event, newPage) => {

        
        setPage(newPage);
        // dispatch(getAllMenuList(newPage,10))
    
        // selectedMenuItemId !== 0
        //     ? dispatch(getPendingApprovalDetailsById(selectedMenuItemId, newPage))
        //     : dispatch(getAllallMenuList(newPage));
      };

    const handleFormState = (fState, contentId) => {
        // fState=='editing'&&dispatch(getMenuDetailsById(contentId, setSelectedMenuDetails));
        // console.log(fState,'fState')
        // fState=='new'&&dispatch(getAllMenuList(page, 10));
        setFormState(fState);
      };
  return (
    <div>
    <p></p>
    <div className="card">
      <div className="card-header">
        <strong>Manage Social Media Links</strong>
      </div>
    
    <div className="card-body">
        <div className= "row">
            <div className='col-2'>
            {/* <InputLabel id="demo-simple-select-label">Select Image Place</InputLabel>
                <Select autoWidth label="Select Image Place" value={''} >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem>Header</MenuItem>
                    <MenuItem>Home</MenuItem>
                    <MenuItem>Footer</MenuItem>
                </Select> */}
            </div>
            <div className='col'>
                <Button size='sm' style={{float:'right', margin: '5px'}}>Add New Social Link</Button>
            </div>
        </div>    
        <SocailLinkTable 
            tableData={
            []
            // allMenuList && allMenuList.menuOptionList 
            //   ? allMenuList.menuOptionList
            //   : []
          }
          totalDataLength={
            0
            // allMenuList && allMenuList.totalNoOfRecords
            //   ? allMenuList.totalNoOfRecords
            //   : 0
          }
          page={page}
          handleChangePage={handleChangePage}
          handleFormState={handleFormState}
          isPendingTable={true}
        //   getAllMenuList={getAllMenuList}
        />
    </div>
    <p></p>
    <p></p>
    </div>
</div>
  )
}

export default ManageSocailLinks