import { SET_FORM_FIELDS, SET_FORM_TYPE_LIST} from "../../common/constants/ActionTypes"

const initialState = { 
    menuFormFields: [],
    menuFormTypeList: [],
}

export default function addMenuReducer(state = initialState, action) {
    switch(action.type) {
        case SET_FORM_FIELDS: 
            return {
                ...state,
                menuFormFields : action.payload
            }
        case SET_FORM_TYPE_LIST: 
            return {
                ...state,
                menuFormTypeList : action.payload
            }
        
        default:
            return state;
    }
}