import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SESSION_OUT_TIME } from '../../common/labels/timeCounter.json'

const SessionTimeOut = ({showModal, setShowModal}) => {
    const dispatch = useDispatch();
    // const [showModal, setShowModal] = useState(false)
    // let accessToken = useSelector(state => state.loginReducer.accessToken);
    // const session = JSON.parse(sessionStorage.getItem("session"));

    // const isLoggedIn = accessToken || (session && session.jwtResponse.token);

    const handleClose = () => {
        setShowModal();
        // window.location.reload();
    }
    const handleOnIdle = event => {
        // if(isLoggedIn) {
            // dispatch(logout(props, false));
            setShowModal();
        // }
    }
    const handleOnActive = event => {

    }
    
    const handleOnAction = event => {

    }
    // const timeoutInMinutes = process.env.REACT_APP_INACTIVE_SESSION_TIMEOUT || 30;

    // const { reset } = useIdleTimer({
    //     timeout: 60 * 1000 * timeoutInMinutes,
    //     onIdle: handleOnIdle,
    //     onActive: handleOnActive,
    //     onAction: handleOnAction,
    //     debounce: 500
    // })

    return (
        <Modal show={showModal} backdrop="static">
            {console.log('showModal',showModal)}
            <Modal.Header>
                <Modal.Title>You have been idle for {SESSION_OUT_TIME} minutes!</Modal.Title>
            </Modal.Header>
            <Modal.Body>The session has timed out. Kindly re-login.</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SessionTimeOut;