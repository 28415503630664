import API from "../../../helpers/API";
import { showLoader, hideLoader } from "../../../common/actions/commonActions";
import {
  SET_DESIGNATION_LIST,
  SET_EXAM_NAME_LIST,
  SET_EXAM_TYPE_LIST,
  SET_MAIN_MENU_LIST,
  SET_MONTH_WISE_LIST,
  SET_SUB_MENU_LIST,
  SET_SUB_MENU_LIST2,
  SET_YEAR_OF_ADVERTISEMENT,
} from "../../common/constants/ActionTypes";
import {
  ADD_NEW_CONTENT,
  APPROVE_CONTENT,
  APPROVE_REJECT_CONTENT,
  EXAM_NAME_LIST,
  EXAM_TYPE_LIST,
  GET_DESIGNATION_LIST,
  MONTH_WISE_LIST,
  MunuList,
  PENDING_APPROVAL_LIST,
  REJECT_CONTENT,
  UPDATE_CONTENT,
  YEAR_OF_ADVERTISEMENT,
} from "../../common/constants/endpoints";
import { toastr } from "react-redux-toastr";
import { getUserDetails } from "../../auth/sessionManager";
import { formatDateToYYYYMMDD } from "../../../helpers/Datetime";

export const addNewContent = (content, resetPageData) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    const formData = new FormData();
    // console.log('content',content)
    // formData.append("englishFile", content.englishFile);
    formData.append("marathiFile", content.marathiFile);
    content.marathiMessageFile &&  formData.append("marathiMessageFile", content.marathiMessageFile);
    content.englishMessageFile && formData.append("englishMessageFile", content.englishMessageFile);
    content.hindiMessageFile && formData.append("hindiMessageFile", content.hindiMessageFile);
    content.englishFileName && formData.append("englishFileName", content.englishFileName);
    content.englishFile && formData.append("englishFile", content.englishFile);
    content.hindiFile && formData.append("hindiFile", content.hindiFile);
    content.monthWiseId && formData.append("monthwiseStatusContentId", content.monthWiseId);
    formData.append("publicationDate",formatDateToYYYYMMDD(content.publicationDate));
    content.meetingDate && formData.append("meetingDate",formatDateToYYYYMMDD(content.meetingDate));
    content.officeBearerJoiningDate && formData.append(
      "officeBearerJoiningDate",
      formatDateToYYYYMMDD(content.officeBearerJoiningDate)
    );
    content.officeBearerRetirementDate && formData.append(
        "officeBearerRetirementDate",
        formatDateToYYYYMMDD(content.officeBearerRetirementDate)
      );
    content.officeBearerEducationalQualification && formData.append(
        "officeBearerEducationalQualification",
        content.officeBearerEducationalQualification
      );
    content.officeBearerEducationalQualificationMr &&
      formData.append(
        "officeBearerEducationalQualificationMr",
        content.officeBearerEducationalQualificationMr
      );
      content.officeBearerEducationalQualificationHin &&
      formData.append(
        "officeBearerEducationalQualificationHin",
        content.officeBearerEducationalQualificationHin
      );
    content.designation && formData.append("designation", content.designation);
    formData.append("descInEnglish", content.descInEnglish);
    formData.append("descHin", content.descInHindi);
    formData.append("descMr", content.descInMarathi);
    content.showInLatestUpdate &&formData.append(
      "fileExpDate", 
      formatDateToYYYYMMDD(content.fileExpDate)
    );
    formData.append("showInLatestUpdate",content.showInLatestUpdate ? "true" : "false");
    formData.append("showInImpNotice",content.showInImpNotice ? "true" : "false");
    formData.append("isActive",content.isActive ? "true" : "false");
    formData.append("remark", content.remark ? content.remark : "");
    formData.append("mid", "" + content.mid);
    content.examType && formData.append("examTypeId", "" + content.examType);
    content.examNameId &&formData.append("examNameId", "" + content.examNameId);
    content.advertisementNumber &&formData.append(
      "advertisementNumber",
      "" + (content.advertisementNumber ? content.advertisementNumber : "")
    );
    formData.append(
      "yearOfAdvertisement",
      "" + (content.yearOfAdvertisement ? content.yearOfAdvertisement : "")
    );
    content.examinationDatePreliminary &&
      formData.append(
        "examinationDatePreliminary",
        formatDateToYYYYMMDD(content.examinationDatePreliminary)
      );
    content.examinationDateMain &&
      formData.append(
        "examinationDateMain",
        formatDateToYYYYMMDD(content.examinationDateMain)
      );
    let tokent = sessionStorage.getItem("token");
    // console.log("formData", formData);
    try {
      response = await API.post(`${ADD_NEW_CONTENT}`, formData, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
        toastr.success(response.data);
        resetPageData();
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const updateContent = (content, resetPageData) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    const formData = new FormData();
    formData.append(
      "publicationDate",
      formatDateToYYYYMMDD(content.publicationDate)
    );
    content.meetingDate && formData.append("meetingDate",formatDateToYYYYMMDD(content.meetingDate));

    formData.append("descInEnglish", content.descInEnglish);
    formData.append("descMr", content.descInMarathi);
    formData.append("descHin", content.descInHindi);
    content.showInLatestUpdate &&
      formData.append("fileExpDate", formatDateToYYYYMMDD(content.fileExpDate));
    formData.append(
      "showInLatestUpdate",
      content.showInLatestUpdate ? "true" : "false"
    );
    formData.append(
      "showInImpNotice",
      content.showInImpNotice ? "true" : "false"
    );
    formData.append("isActive",content.isActive ? "true" : "false");

    formData.append("remark", content.remark ? content.remark : "");
    formData.append("mid", "" + content.mid);
    formData.append("id", "" + content.id);
    content.examType && formData.append("examTypeId", "" + content.examType);
    content.examNameId &&formData.append("examNameId", "" + content.examNameId);
    content.monthWiseId && formData.append("monthwiseStatusContentId", content.monthWiseId);

    content.advertisementNumber &&
      formData.append("advertisementNumber", "" + content.advertisementNumber);
    content.yearOfAdvertisement &&
      formData.append("yearOfAdvertisement", "" + content.yearOfAdvertisement);
    content.examinationDatePreliminary &&
      formData.append(
        "examinationDatePreliminary",
        formatDateToYYYYMMDD(content.examinationDatePreliminary)
      );
    content.examinationDateMain &&
      formData.append(
        "examinationDateMain",
        formatDateToYYYYMMDD(content.examinationDateMain)
      );
    // console.log(content)
    // console.log(content, (content.isImageUpdated === 1 || content.isImageUpdated===0)? 'yes': 'no')
    (content.isImageUpdated === 1 || content.isImageUpdated === 0) &&
      formData.append("isImageUpdated", content.isImageUpdated);
    content.englishFileName &&
      formData.append("englishFileName", content.englishFileName);
    content.englishFile && formData.append("englishFile", content.englishFile);
    content.marathiFile && formData.append("marathiFile", content.marathiFile);
    content.hindiFile && formData.append("hindiFile", content.hindiFile);


    formData.append(
      "publicationDate",
      formatDateToYYYYMMDD(content.publicationDate)
    );
    
    content.marathiMessageFile && formData.append("marathiMessageFile", content.marathiMessageFile);
    content.englishMessageFile && formData.append("englishMessageFile", content.englishMessageFile);
    content.hindiMessageFile && formData.append("hindiMessageFile", content.hindiMessageFile);
    content.officeBearerJoiningDate &&
      formData.append(
        "officeBearerJoiningDate",
        formatDateToYYYYMMDD(content.officeBearerJoiningDate)
      );
    content.officeBearerRetirementDate &&
      formData.append(
        "officeBearerRetirementDate",
        formatDateToYYYYMMDD(content.officeBearerRetirementDate)
      );
    content.officeBearerEducationalQualification &&
      formData.append(
        "officeBearerEducationalQualification",
        content.officeBearerEducationalQualification
      );
    content.officeBearerEducationalQualificationMr &&
      formData.append(
        "officeBearerEducationalQualificationMr",
        content.officeBearerEducationalQualificationMr
      );
    content.officeBearerEducationalQualificationHin &&
    formData.append(
      "officeBearerEducationalQualificationHin",
      content.officeBearerEducationalQualificationHin
    );
    content.designation && formData.append("designation", content.designation);

    let tokent = sessionStorage.getItem("token");
    try {
      response = await API.post(`${UPDATE_CONTENT}`, formData, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
        toastr.success(response.data);
        resetPageData();
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const approveContent = (contentid, status, resetPageData) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    let userId = getUserDetails().userId;
    try {
      response = await API.post(
        `${APPROVE_CONTENT}`,
        { id: contentid, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${tokent}`,
          },
        }
      );
      if (response != null) {
        dispatch(hideLoader());
        resetPageData();
        toastr.success(response.data);
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};
export const rejectContent = (contentid, status, resetPageData) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    let userId = getUserDetails().userId;
    try {
      response = await API.post(
        `${REJECT_CONTENT}`,
        { id: contentid, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${tokent}`,
          },
        }
      );
      if (response != null) {
        dispatch(hideLoader());
        resetPageData();
        toastr.success(response.data);
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};


export const getMonthWiseList = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    try {
      response = await API.get(`${MONTH_WISE_LIST}?mid=19`, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_MONTH_WISE_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getExamNameList = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    try {
      response = await API.get(`${EXAM_NAME_LIST}`, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_EXAM_NAME_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getExamTypeList = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    try {
      response = await API.get(`${EXAM_TYPE_LIST}`, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_EXAM_TYPE_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getMenuListByMid = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    let token =
      "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjcmVhdG9yQG1wc2MuY29tIiwiZXhwIjoxNjg1OTYyMzU3LCJpYXQiOjE2ODU5NDQzNTd9.UAEebBds_-g9TBPVdlJf9d-mEFjqTT9VO93CVwaTymL87Syq_6WkWwM9JUHBiewJorMHZSQ8M-RX32jxK68HTQ";
    try {
      response = await API.get(`${MunuList}`, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_MAIN_MENU_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getSubMenuListByMid = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let token = sessionStorage.getItem("token");
    try {
      response = await API.get(`${MunuList}${mid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_SUB_MENU_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getSubMenuList2ByMid = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let token = sessionStorage.getItem("token");
    try {
      response = await API.get(`${MunuList}${mid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_SUB_MENU_LIST2,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getYears = () => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let token = sessionStorage.getItem("token");
    try {
      response = await API.get(`${YEAR_OF_ADVERTISEMENT}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_YEAR_OF_ADVERTISEMENT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const addNewContentData = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let token = sessionStorage.getItem("token");
    try {
      response = await API.post(`${PENDING_APPROVAL_LIST}${mid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
        toastr.success("New content added successfully.");
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getDesignationList = (mid = 0) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    let tokent = sessionStorage.getItem("token");
    try {
      response = await API.get(`${GET_DESIGNATION_LIST}`, {
        headers: {
          Authorization: `Bearer ${tokent}`,
        },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_DESIGNATION_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};
