
const initialState = {
    isVerifyDetails: false,
    
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
