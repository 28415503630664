import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getDescNameByLang, getLabelByLang, getMenuNameByLang, getTableHeadNameByLang } from "../../helpers/multiLingual";
const ConvertTextLang = ({ obj }) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  return <>{getMenuNameByLang(obj, webLanguage)}</>;
};

export const MenuNameByLang = ({ obj }) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  return <>{getMenuNameByLang(obj, webLanguage)}</>;
};

export const DescNameByLang = ({ obj }) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  return <>{getDescNameByLang(obj, webLanguage)}</>;
};

export const LabelByLang = ({ obj }) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  return <>{getLabelByLang(obj, webLanguage)}</>;
};
export const TableHeadNameByLang = ({ obj }) => {
  const { webLanguage } = useSelector((state) => state.common, shallowEqual);
  return <>{getTableHeadNameByLang(obj, webLanguage)}</>;
};

export default ConvertTextLang;
