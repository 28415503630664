
const handleDownload = (base64Data) => {
  // const base64Data = 'your_base64_encoded_data_here';
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = 'file.pdf';

  document.body.appendChild(link); // Append the link to the DOM
  link.click(); // Trigger the click event
  document.body.removeChild(link); // Remove the link from the DOM
  // Clean up the object URL
  setTimeout(() => URL.revokeObjectURL(url), 100);
};

export const openPdfInNewTab = (base64Data, hideLoaderFun) => {
  const binaryData = atob(base64Data);
  const array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([array], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  const newWindow = window.open(blobUrl, '_blank');
      if (newWindow) {
        // newWindow.location.href = blobUrl
        // window.open(blobUrl, "_blank");
        hideLoaderFun();
      } else {
        // If window.open was blocked, alert the user to allow pop-ups
        // alert('Please allow pop-ups for this site');
        handleDownload(base64Data)
        hideLoaderFun()
      }
  // window.open(blobUrl, "_blank");
};
export const createPdfToBlobUrl = (base64Data) => {
  const binaryData = atob(base64Data);
  const array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([array], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl
};