import API from '../../../helpers/API';
import { showLoader, hideLoader } from '../../../common/actions/commonActions';
import { SET_MAIN_MENU_LIST, SET_SUB_MENU_LIST, SET_SUB_MENU_LIST2, SET_PENDING_APPROVAL_LIST, SET_PENDING_APPROVAL_DETAILS } from '../../common/constants/ActionTypes';
import { ALL_PENDING_APPROVAL_LIST, MunuList, PENDING_APPROVAL_LIST, PENDING_CONTENT_DETAILS } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';

export const getMenuListByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let tokent = sessionStorage.getItem('token')
        let token= "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjcmVhdG9yQG1wc2MuY29tIiwiZXhwIjoxNjg1OTYyMzU3LCJpYXQiOjE2ODU5NDQzNTd9.UAEebBds_-g9TBPVdlJf9d-mEFjqTT9VO93CVwaTymL87Syq_6WkWwM9JUHBiewJorMHZSQ8M-RX32jxK68HTQ"
        try {
            response = await API.get(`${MunuList}`, {
                headers: {
                    'Authorization': `Bearer ${tokent}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_MAIN_MENU_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getSubMenuListByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${MunuList}${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_SUB_MENU_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getSubMenuList2ByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${MunuList}${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_SUB_MENU_LIST2,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getPendingApprovalListByMid = (mid=0, pageNo=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${PENDING_APPROVAL_LIST}/${pageNo}/10?mid=${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PENDING_APPROVAL_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getAllPendingApprovalList = (pageNo=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${ALL_PENDING_APPROVAL_LIST}/${pageNo}/10?mid=`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PENDING_APPROVAL_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getPendingApprovalDetailsById = (contentId=0, setSelectedMenuItem) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${PENDING_CONTENT_DETAILS}${contentId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            // setSelectedMenuItem(response.data.publishedContentDetails?response.data.publishedContentDetails:'')
            setSelectedMenuItem(response.data.component?response.data.component:'')
            dispatch({
                type: SET_PENDING_APPROVAL_DETAILS,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}