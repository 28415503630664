import { SET_MAIN_MENU_LIST, SET_SUB_MENU_LIST, SET_SUB_MENU_LIST2, SET_PUBLISHED_APPROVAL_LIST, RESET_PUBLISHED_APPROVAL_LIST, SET_PUBLISHED_CONTENT_DETAILS } from "../../common/constants/ActionTypes"

const initialState = { 
    errorMessage: "",
    mainMenuList: [],
    subMenuList: [],
    subMenuList2: [],
    publishedContentList: [],
    publishedContentDetails: {},
}

export default function publishedContentListReducer(state = initialState, action) {
    switch(action.type) {
        case SET_PUBLISHED_APPROVAL_LIST: 
            return {
                ...state,
                publishedContentList : action.payload
            }
        case SET_MAIN_MENU_LIST: 
            return {
                ...state,
                mainMenuList : action.payload
            }
        case SET_SUB_MENU_LIST: 
            return {
                ...state,
                subMenuList : action.payload
            }
        case SET_SUB_MENU_LIST2: 
            return {
                ...state,
                subMenuList2 : action.payload
            }
        case SET_PUBLISHED_CONTENT_DETAILS: 
            return {
                ...state,
                publishedContentDetails : action.payload
            }
        case RESET_PUBLISHED_APPROVAL_LIST: 
            return {
                ...state,
                publishedContentList : []
            }
        
        default:
            return state;
    }
}