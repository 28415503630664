import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CommonFormatContent from "./CommonFormatContent";
import ResultContentFormat from "./ResultContentFormat";
import CommonFormatContentSecond from "./CommonFormatContentSecond";
import OfficeBearerContent from "./OfficeBearerContent";

const FormDemoModel = (props) => {

const HandleCreateNewContent = ({ componentType }) => {
    switch (componentType) {
        case "common_format_content":
        return (
            <CommonFormatContent
            formTitle={""}
            selectedMid={1}
            handleCancel={()=>{}}
            resetPageData={()=>{}}
            isDemo={true}
            />
        );
        case "common_format2_content":
        return (
            <CommonFormatContentSecond
            formTitle={""}
            selectedMid={1}
            handleCancel={()=>{}}
            resetPageData={()=>{}}
            isDemo={true}
            />
        );
        case "results-content":
        return (
            <ResultContentFormat
            formTitle={""}
            selectedMid={1}
            handleCancel={()=>{}}
            resetPageData={()=>{}}
            isDemo={true}
            />
        );
        case "office_bearer_content":
        return (
        <OfficeBearerContent
            formTitle={""}
            selectedMid={1}
            handleCancel={()=>{}}
            resetPageData={()=>{}}
            isDemo={true}
        />
        );
        default:
        return <div>No menu selected</div>;
    }
    };
  return (
    <Modal
      size="xl"
      show={props.showModal}
      onHide={() => {
        props.setShowModal(false);
      }}
      aria-labelledby="example-modal-sizes-title-xl"
      backdrop="static"
    >
    <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
           Form Demo Format
          </Modal.Title>
    </Modal.Header>
    <Modal.Body>
       <div className="disabled-div" aria-disabled>
       <HandleCreateNewContent componentType={props.selectedFormContent} /></div>

    </Modal.Body>
    </Modal>
  )
}

export default FormDemoModel