import { SET_MAIN_MENU_LIST, SET_SUB_MENU_LIST, SET_SUB_MENU_LIST2, SET_PENDING_APPROVAL_LIST, RESET_PENDING_APPROVAL_LIST, SET_PENDING_APPROVAL_DETAILS } from "../../common/constants/ActionTypes"

const initialState = { 
    errorMessage: "",
    mainMenuList: [],
    subMenuList: [],
    subMenuList2: [],
    pendingApprovalList: [],
    pendingApprovalDetails: {},
}

export default function pendingApprovalListReducer(state = initialState, action) {
    switch(action.type) {
        case SET_PENDING_APPROVAL_LIST: 
            return {
                ...state,
                pendingApprovalList : action.payload
            }
        case SET_MAIN_MENU_LIST: 
            return {
                ...state,
                mainMenuList : action.payload
            }
        case SET_SUB_MENU_LIST: 
            return {
                ...state,
                subMenuList : action.payload
            }
        case SET_SUB_MENU_LIST2: 
            return {
                ...state,
                subMenuList2 : action.payload
            }
        case SET_PENDING_APPROVAL_DETAILS: 
            return {
                ...state,
                pendingApprovalDetails : action.payload
            }
        case RESET_PENDING_APPROVAL_LIST: 
            return {
                ...state,
                pendingApprovalList : []
        }
        
        default:
            return state;
    }
}