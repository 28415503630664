// import { useSelector } from "react-redux"
// import { Link } from "react-router-dom";


const HomePageNav = (props) => {
    // let accessToken = useSelector(state => state.loginReducer.accessToken);

    // const session = JSON.parse(sessionStorage.getItem("session"));

    // if(accessToken || (session && session.jwtResponse.token)) {
    //     return ""
    // } else {
    //     return (
    //         <div>
    //             <ul className="navbar navbar-expand-lg navbar-dark bg-color-third row" style={{justifyContent:"flex-end", paddingRight:"100px"}}> 
    //                 <div>
    //                     <Link to="/candidate" className="nav-item col-md-1  " onClick={() => window.location.href="/candidate"}>
    //                         <span className="text-third-nav" style={{marginLeft:"4px"}} data-toggle="tab">Home</span>
    //                     </Link>
    //                     {/* <Link to="/candidate/registration" className="nav-item col-md-1" onClick={() => window.location.href="/candidate/registration"}>
    //                         <span className="text-third-nav" style={{marginLeft:"4px"}} data-toggle="tab">Register</span>
    //                     </Link> */}
    //                     <Link to="/candidate/login" className="nav-item col-md-1" onClick={() => window.location.href="/candidate/login"}>
    //                         <span className="text-third-nav" style={{marginLeft:"4px"}} data-toggle="tab">Login</span>
    //                     </Link>
    //                 </div>
    //             </ul>
    //         </div>
    //     )
    // }
    return (
        /*bg-body-tertiary*/
      <nav className="p-0 nav-fix-bgc navbar navbar-expand-lg  navbar-expand-md navbar-expand-md" >
          <button
            className={`b-shadow-none navbar-toggler ${props.isCollapsed ? "collapsed" : "" } `}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={props.isCollapsed ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={()=>props.setIsCollapsed(prev=>!prev)}
          >
            <span className="nav-btn-bgimg navbar-toggler-icon" ></span>
          </button>
          <div className={`collapse navbar-collapse ${props.isCollapsed ? "show" : "" }`} id="">
            {props.children}
          </div>
      </nav>
    );

}

export default HomePageNav