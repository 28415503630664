
export const getDescNameByLang = (fromObj, webLanguage) =>{
    switch(webLanguage){
        case 'English': 
            return fromObj['descInEnglish'];
        case 'Marathi': 
            return fromObj['descMr'];
        case 'Hindi': 
            return fromObj['descHin'];
        default:
            return fromObj['descInEnglish'];
    }
}

export const getMenuNameByLang = (fromObj, webLanguage) =>{
    switch(webLanguage){
        case 'English': 
            return fromObj['name']
        case 'Marathi': 
            return fromObj['nameMr']
        case 'Hindi': 
            return fromObj['nameHin']
        default:
            return fromObj['name']
    }
}

export const getLabelByLang = (fromObj, webLanguage) =>{

    switch(webLanguage){
        case 'English': 
            return fromObj['descInEnglish']
        case 'Marathi': 
            return fromObj['descInMarathi']
        case 'Hindi': 
            return fromObj['descInHindi']
        default:
            return fromObj['descInEnglish']
    }
}

export const getTableHeadNameByLang = (fromObj, webLanguage) =>{

    switch(webLanguage){
        case 'English': 
            return fromObj['displayName']
        case 'Marathi': 
            return fromObj['displayNameMr']
        case 'Hindi': 
            return fromObj['displayNameHin']
        default:
            return fromObj['displayName']
    }
}

export const getMenuPathByLang = (fromObj, webLanguage) =>{

    switch(webLanguage){
        case 'English': 
            return fromObj['menuFullPath']
        case 'Marathi': 
            return fromObj['menuFullPathInMarathi']
        case 'Hindi': 
            return fromObj['menuFullPathInHindi']
        default:
            return fromObj['menuFullPath']
    }
}

export const getStaticContentByLang = (fromObj, webLanguage) =>{

    switch(webLanguage){
        case 'English': 
            return fromObj['htmlContent']
        case 'Marathi': 
            return fromObj['htmlContentMr']
        case 'Hindi': 
            return fromObj['htmlContentHin']
        default:
            return fromObj['htmlContent']
    }
}

export const getOfficeBearerDesignationByLang = (fromObj=[], webLanguage) =>{

    switch(webLanguage){
        case 'English': 
            return fromObj['officeBearer']
        case 'Marathi': 
            return fromObj['officeBearerInMarathi'] ? fromObj['officeBearerInMarathi']: fromObj['officeBearer']
        case 'Hindi': 
            return fromObj['officeBearerInHindi'] ? fromObj['officeBearerInHindi']: fromObj['officeBearer']
        default:
            return fromObj['htmlContent']
    }
}
export const getChairmanMessageByLang = (fromObj=[], webLanguage) =>{
    switch(webLanguage){
        case 'English': 
            return fromObj['chairmanMessage']
        case 'Marathi': 
            return fromObj['chairmanMessageInMarathi'] ? fromObj['chairmanMessageInMarathi']: fromObj['chairmanMessage']
        case 'Hindi': 
            return fromObj['chairmanMessageInHindi'] ? fromObj['chairmanMessageInHindi']: fromObj['chairmanMessage']
        default:
            return fromObj['htmlContent']
    }
}

export const getOfficeBearerNameByLang = (fromObj, webLanguage) =>{
    switch(webLanguage){
        case 'English': 
            return fromObj['name']
        case 'Marathi': 
            return fromObj['name']
        case 'Hindi': 
            return fromObj['name']
        default:
            return fromObj['name']
    }
}