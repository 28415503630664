export const setSessionToken = (newToken) => {
  sessionStorage.setItem("token", newToken);
};

export const clearSession = () => {
  sessionStorage.removeItem("token");
};

export const setUserSessionData = (newToken) => {
  sessionStorage.setItem("user", JSON.stringify(newToken));
};

export const clearUserSessionData = () => {
  sessionStorage.removeItem("user");
};

export const getSessionToken = () =>{
    return sessionStorage.getItem('token')
} 

export const getUserDetails = () =>{
    return JSON.parse(sessionStorage.getItem('user'))
} 

export const isUserCreator =() => {
  return getSessionToken() && getUserDetails().userRole === "CREATOR";
}

export const isUserReviewer =() => {
  return getSessionToken() && getUserDetails().userRole === "REVIEWER";
}

export const isUserApprover =() => {
  return getSessionToken() && getUserDetails().userRole === "APPROVER";
}

export const isUserAdmin =() => {
  return getSessionToken() && getUserDetails().userRole === "SUPER";
}
