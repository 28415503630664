import React from 'react'
import { shallowEqual, useSelector } from 'react-redux';

const TextToSpeech = (props) => {
  const { isTtsActive } = useSelector((state) => state.common, shallowEqual);

    const handlelSpeak = (text) => {
      let utter = new SpeechSynthesisUtterance(text);
      // utter.lang = 'mr-IN';
      utter.rate = 0.7;
      isTtsActive && speechSynthesis.speak(utter);
    };
    const handleStop = () => {
      speechSynthesis.cancel();
    };
  return (
    <span
      onMouseOver={() => handlelSpeak(props.text)}
      onMouseLeave={handleStop}
    >
      {props.children}
    </span>
  );
}

export default TextToSpeech