import API from '../../../helpers/API';
import { showLoader, hideLoader } from '../../../common/actions/commonActions';
import { SET_MAIN_MENU_LIST, SET_SUB_MENU_LIST, SET_SUB_MENU_LIST2, SET_PUBLISHED_APPROVAL_LIST, SET_PUBLISHED_CONTENT_DETAILS } from '../../common/constants/ActionTypes';
import { MunuList, PUBLISHED_CONTENT_LIST, ALL_PUBLISHED_CONTENT_LIST, PUBLISHED_CONTENT_DETAILS } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';

export const getMenuListByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${MunuList}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_MAIN_MENU_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getSubMenuListByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${MunuList}${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_SUB_MENU_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getSubMenuList2ByMid = (mid=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${MunuList}${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_SUB_MENU_LIST2,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getAllPublishedContentList = (pageNo=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${PUBLISHED_CONTENT_LIST}/${pageNo}/10?mid=`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PUBLISHED_APPROVAL_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getPublishedContentListByMid = (mid=0, pageNo=0) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${PUBLISHED_CONTENT_LIST}/${pageNo}/10?mid=${mid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PUBLISHED_APPROVAL_LIST,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}

export const getPublishedContentDetailsById = (contentId=0, setSelectedMenuItem) => {
    
    return async function (dispatch) {
        dispatch(showLoader());
        let response;
        let token= sessionStorage.getItem('token')
        try {
            response = await API.get(`${PUBLISHED_CONTENT_DETAILS}${contentId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            setSelectedMenuItem(response.data.component?response.data.component:'')
            dispatch({
                type: SET_PUBLISHED_CONTENT_DETAILS,
                payload: response.data,
            });

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    };
}