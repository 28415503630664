export const SHOW_LOADER = "show_loader";
export const HIDE_LOADER = "hide_loader";
export const SET_MENU_ITMES = "set_menu_itmes";
export const SET_HOME_DATA = "set_home_data";
export const SET_WEB_LANGUAGE = "set_web_language";
export const SET_TTS_STATUS = "set_tts_status";

// main content
export const SET_MAIN_CONTENT = "set_main_content";
export const SET_Office_bearer_CONTENT = "set_office_bearer_content";
//adminLogin
export const SET_SESSION_TIMEOUT = "set_session_timout";


