import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addNewContent,
  approveContent,
  rejectContent,
  updateContent,
} from "../../AddNewContent/actions/addNewContentActions";
import { FIELD_REQUIRED, PLEASE_SELECT_FILE } from "../labels/formLabels.json";
import { MenuItem, Select } from "@mui/material";
import { ReactTransliterate } from "react-transliterate";
import { isUserAdmin, isUserApprover, isUserReviewer } from "../../auth/sessionManager";
import { toastr } from "react-redux-toastr";
import API from "../../../helpers/API";
import { openPdfInNewTab } from "../../../helpers/ShowPdf";
import '../../adminstyles/adminContent.css'
import { hideLoader, showLoader } from "../../../common/actions/commonActions";

const ResultContentFormat = ({
  formTitle,
  actionType = "create",
  selectedMid,
  publishedContentDetails,
  handleCancel,
  resetPageData,
  isDemo
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    examTypeList,
    examNameList,
    yearsOfAdvertisement,
    subMenuList2,
    subMenuList,
  } = useSelector((state) => state.addNewContent, shallowEqual);
  const [mid, setMid] = useState(0);
  const [publicationDate, setPublicationDate] = useState(new Date());
  const [englishFileName, setEnglishFileName] = useState("");
  const [marathiFileName, setMarathiFileName] = useState("");
  const [descInEnglish, setDescInEnglish] = useState("");
  const [descInMarathi, setDescInMarathi] = useState("");
  const [descInHindi, setDescInHindi] = useState("");
  const [englishFile, setEnglishFile] = useState(null);
  const [marathiFile, setMarathiFile] = useState(null);
  const [hindiFile, setHindiFile] = useState(null);
  const [fileExpDate, setFileExpDate] = useState(new Date());
  const [isAdvtActive, setIsAdvtActive] = useState(true);
  const [showInLatestUpdate, setShowInLatestUpdate] = useState(false);
  const [showInImpNotice, setShowInImpNotice] = useState(false);
  const [remark, setRemark] = useState("");
  const [examNameId, setExamNameId] = useState("");
  const [examType, setExamType] = useState("");
  const [advertisementNumber, setAdvertisementNumber] = useState("");
  const [yearOfAdvertisement, setYearOfAdvertisement] = useState("");
  const [isTouched, setIsTouched] = useState({
    descInMarathi: false,
    descInEnglish: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsTouched((prevFormData) => ({
      ...prevFormData,
      [name]: true,
    }));
    setErrors((prevErrData) => ({
      ...prevErrData,
      [name]: true,
    }));
  };

  const handleMarathiFileUpload = (e) => {
    setMarathiFile(e.target.files[0]);
  };
  const handleHindiFileUpload = (e) => {
    setHindiFile(e.target.files[0]);
  };
  const handleEngFileUpload = (e) => {
    setEnglishFile(e.target.files[0]);
  };

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };
  const isNotEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };

  const isEmptyOrNull = (variable) => {
    return variable === null || variable === undefined || variable === "";
  };
  const getDownloadLink = (e, fileId, language = "english") => {
    e.preventDefault();
    dispatch(showLoader())
    let tokent = sessionStorage.getItem("token");
    API.get(`web/api/v1/admin/downloadFile/${language}/${fileId}`, {
      headers: {
        Authorization: `Bearer ${tokent}`,
      },
    })
      .then((res) => {
        if (res) {
          openPdfInNewTab(res.data.pdfData,()=>{dispatch(hideLoader())});
        } else {
          dispatch(hideLoader())
          toastr.error(res.message);
        }
      })
      .catch((err) => {
        dispatch(hideLoader())
        toastr.error(err.message);
      });
  };

  const handleCreateContent = (e) => {
    const newErrors = {};
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }
    if (isNotEmptyOrNull(descInHindi)) {
      newErrors.descInHindi = true;
    }
    if (isNotEmptyOrNull(marathiFile)) {
      newErrors.marathiFile = true;
    }
    if (isNotEmptyOrNull(hindiFile)) {
      newErrors.hindiFile = true;
    }
    if (isNotEmptyOrNull(englishFile)) {
      newErrors.englishFile = true;
    }
    if (isNotEmptyOrNull(examType)) {
      newErrors.examType = true;
    }
    if (isNotEmptyOrNull(advertisementNumber)) {
      newErrors.advertisementNumber = true;
    }
    if (isNotEmptyOrNull(examNameId)) {
      newErrors.examNameId = true;
    }
    if (isNotEmptyOrNull(examType)) {
      newErrors.examType = true;
    }
    if (isNotEmptyOrNull(publicationDate)) {
      newErrors.publicationDate = true;
    }
    if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
      newErrors.fileExpDate = true;
    }
    setErrors(newErrors);
    dispatch(
      addNewContent(
        {
          mid,
          publicationDate,
          englishFile,
          marathiFile,
          hindiFile,
          descInEnglish,
          descInMarathi,
          descInHindi,
          fileExpDate,
          showInLatestUpdate,
          showInImpNotice,
          isActive : isAdvtActive,
          remark,
          examNameId,
          examType,
          advertisementNumber,
          yearOfAdvertisement,
        },
        resetPageData
      )
    );
    e.preventDefault();
  };

  const handleApprove = (e, id, action) => {
    e.preventDefault();
    dispatch(approveContent(id, action, resetPageData));
  };
  const handleReject = (e, id, action) => {
    e.preventDefault();
    dispatch(rejectContent(id, action, resetPageData));
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (isNotEmptyOrNull(yearOfAdvertisement)) {
      newErrors.yearOfAdvertisement = true;
    }
    if (isNotEmptyOrNull(descInEnglish)) {
      newErrors.descInEnglish = true;
    }
    if (isNotEmptyOrNull(descInMarathi)) {
      newErrors.descInMarathi = true;
    }    
    if (isNotEmptyOrNull(descInHindi)) {
      newErrors.descInHindi = true;
    }
    if (isNotEmptyOrNull(examType)) {
      newErrors.examType = true;
    }
    if (isNotEmptyOrNull(advertisementNumber)) {
      newErrors.advertisementNumber = true;
    }
    if (isNotEmptyOrNull(examNameId)) {
      newErrors.examNameId = true;
    }
    if (isNotEmptyOrNull(publicationDate)) {
      newErrors.publicationDate = true;
    }
    if (showInLatestUpdate && isNotEmptyOrNull(fileExpDate)) {
      newErrors.fileExpDate = true;
    }
    setErrors(newErrors);

    if (!Object.values(newErrors).includes(true)) {
      dispatch(
        updateContent(
          {
            mid,
            id: publishedContentDetails.id,
            publicationDate,
            englishFileName,
            marathiFileName,
            englishFile,
            marathiFile,
            hindiFile,
            descInEnglish,
            descInMarathi,
            descInHindi,
            fileExpDate,
            showInLatestUpdate,
            showInImpNotice,
            isActive : isAdvtActive,
            remark,
            examNameId,
            examType,
            advertisementNumber,
            yearOfAdvertisement,
          },
          resetPageData
        )
      );
    } else {
      toastr.warning("Please enter all required fields.");
    }
  };

  useEffect(() => {
    setMid(selectedMid);
    {
      if (actionType === "editing" && publishedContentDetails) {
        setMid(publishedContentDetails.mid);
        setPublicationDate(
          publishedContentDetails.publicationDate &&
            new Date(publishedContentDetails.publicationDate)
        );
        setDescInEnglish(publishedContentDetails.descInEnglish);
        setDescInMarathi(publishedContentDetails.descMr);
        setDescInMarathi(publishedContentDetails.descHin);
        setEnglishFileName(publishedContentDetails.englishFileName);
        setMarathiFileName(publishedContentDetails.marathiFileName);
        setYearOfAdvertisement(publishedContentDetails.yearOfAdvertisement);
        setFileExpDate(
          publishedContentDetails.fileExpDate &&
            new Date(publishedContentDetails.fileExpDate)
        );
        setExamNameId(publishedContentDetails.examNameId);
        setExamType(publishedContentDetails.examTypeId);
        setShowInLatestUpdate(publishedContentDetails.showInLatestUpdate);
        setShowInImpNotice(publishedContentDetails.showInImpNotice);
        publishedContentDetails.isActive ? setIsAdvtActive(publishedContentDetails.isActive) :setIsAdvtActive(false)
        setRemark(publishedContentDetails.remark);
        setAdvertisementNumber(publishedContentDetails.advertisementNumber);
        window.scrollTo(0, 0);
      }
    }
  }, []);

  return (
    <form noValidate>
      <div className="form-row">
      {!isDemo && <h4>
          <span>{actionType !== "editing" ? "Creating new" : "Editing"} </span>
          {actionType !== "editing"
            ? formTitle
            : publishedContentDetails.contentName}
        </h4>}
      </div>
      <p></p>

      <div className="form-row">
        <div className="col-3">
          <label htmlFor="dateOfPublication">Date Of Publication</label>
          <span className="text-danger">*</span>
        </div>
        <div className="input-group col-2">
          <ReactDatePicker
            showIcon
            selected={publicationDate}
            onChange={(date) => setPublicationDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            dropdownMode="select"
            customInput={
              <input
                className={`form-control ${
                  ((isTouched.publicationDate &&
                    isEmptyOrNull(publicationDate)) ||
                    (errors.publicationDate &&
                      isEmptyOrNull(publicationDate))) &&
                  "is-invalid"
                }`}
                id="dateOfPublication"
                name="dateOfPublication"
                placeholder="DD-MM-YYYY"
                required
                minLength="10"
                maxLength="10"
              />
            }
          />
          {errors.publicationDate && isEmptyOrNull(publicationDate) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>
      <p></p>
      <div className="form-row">
        <div className="col-3">
          <label htmlFor="select-exam-type">Examination Type</label>
          <span className="text-danger">*</span>
        </div>
        <div className="input-group col-6">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            placeholder="select exam type"
            name="examType"
            className={`select_custom form-control ${
              ((isTouched.examType && isEmptyOrNull(examType)) ||
                (errors.examType && isEmptyOrNull(examType))) &&
              "is-invalid"
            }`}
            value={examType}
            onChange={(e) => {
              setExamType(e.target.value);
            }}
            onBlur={handleChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            {examTypeList.length > 0 &&
              examTypeList.map((exam, index) => (
                <MenuItem key={index} value={exam.id}>
                  {exam.name}
                </MenuItem>
              ))}
          </Select>
          {errors.examType && isEmptyOrNull(examType) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>
      <p></p>

      <div className="form-row">
        <div className="col-3">
          <label htmlFor="select-adv-year">Year of Advertisement</label>
        </div>
        <div className="input-group col-2">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            placeholder="select year of advertisement"
            value={yearOfAdvertisement}
            name="yearOfAdvertisement"
            className={`select_custom form-control ${
              ((isTouched.yearOfAdvertisement &&
                isEmptyOrNull(yearOfAdvertisement)) ||
                (errors.yearOfAdvertisement &&
                  isEmptyOrNull(yearOfAdvertisement))) &&
              "is-invalid"
            }`}
            onBlur={handleChange}
            onChange={(e) => setYearOfAdvertisement(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            {yearsOfAdvertisement.length > 0 &&
              yearsOfAdvertisement.map((yearofad, index) => (
                <MenuItem key={index} value={yearofad.year}>
                  {yearofad.year}
                </MenuItem>
              ))}
          </Select>
          {errors.yearOfAdvertisement && isEmptyOrNull(yearOfAdvertisement) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>
      <p></p>

      <div className="form-row">
        <div className="col-3">
          <label htmlFor="advNumber">Advertisement Number</label>
          <span className="text-danger">*</span>
        </div>
        <div className="input-group col-2">
          <input
            length="25"
            type="text"
            value={advertisementNumber}
            name="advertisementNumber"
            className={`form-control ${
              ((isTouched.advertisementNumber &&
                isEmptyOrNull(advertisementNumber)) ||
                (errors.advertisementNumber &&
                  isEmptyOrNull(advertisementNumber))) &&
              "is-invalid"
            }`}
            onBlur={handleChange}
            onChange={(e) => setAdvertisementNumber(e.target.value)}
            required
          />
        </div>
        {errors.advertisementNumber && isEmptyOrNull(advertisementNumber) && (
          <span className="text-danger">{FIELD_REQUIRED}</span>
        )}
      </div>
      <p></p>

      <div className="form-row">
        <div className="col-3">
          <label htmlFor="select-exam-name">Examination Name</label>
          <span className="text-danger">*</span>
        </div>
        <div className="input-group col-6">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            placeholder="select exam name"
            value={examNameId}
            onChange={(e) => setExamNameId(e.target.value)}
            name="examNameId"
            className={`select_custom form-control ${
              ((isTouched.examNameId && isEmptyOrNull(examNameId)) ||
                (errors.examNameId && isEmptyOrNull(examNameId))) &&
              "is-invalid"
            }`}
            onBlur={handleChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            {examNameList.length > 0 &&
              examNameList.map((exam, index) => (
                <MenuItem key={index} value={exam.id}>
                  {exam.name}
                </MenuItem>
              ))}
          </Select>
          {errors.examNameId && isEmptyOrNull(examNameId) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>
      <p></p>

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="descInEnglish">
            <span>Description (English)</span>
          </label>
          <span className="text-danger">*</span>
          <input
            className={`form-control ${
              ((isTouched.descInEnglish && isEmptyOrNull(descInEnglish)) ||
                (errors.descInEnglish && isEmptyOrNull(descInEnglish))) &&
              "is-invalid"
            }`}
            id="descInEnglish"
            name="descInEnglish"
            placeholder="Description in English"
            length="30"
            type="text"
            value={descInEnglish}
            onChange={(e) => setDescInEnglish(e.target.value)}
            onBlur={handleChange}
            required
          />
          {errors.descInEnglish && isEmptyOrNull(descInEnglish) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="descInMarathi">
            <span>Description (Marathi)</span>
          </label>
          <span className="text-danger">*</span>
          <ReactTransliterate
            value={descInMarathi}
            placeholder="Description in Marathi"
            onChangeText={(e) => setDescInMarathi(e)}
            lang="mr"
            className={`form-control ${
              ((isTouched.descInMarathi && isEmptyOrNull(descInMarathi)) ||
                (errors.descInMarathi && isEmptyOrNull(descInMarathi))) &&
              "is-invalid"
            }`}
          />
          {errors.descInMarathi && isEmptyOrNull(descInMarathi) && (
            <span className="text-danger">{FIELD_REQUIRED}</span>
          )}
        </div>
      </div>
      <div className="form-row">
          <div className="form-group col">
            <label htmlFor="descInHindi">
              <span>Description (Hindi)</span>
            </label>
            <span className="text-danger">*</span>
            <ReactTransliterate
              name="descInHindi"
              value={descInHindi}
              placeholder="Description in Hindi"
              onChangeText={(e) => {
                setDescInHindi(e);
              }}
              onBlur={handleChange}
              lang="mr"
              className={`form-control ${
                ((isTouched.descInHindi && isEmptyOrNull(descInHindi)) ||
                  (errors.descInHindi && isEmptyOrNull(descInHindi))) &&
                "is-invalid"
              }`}
            />
            {errors.descInHindi && isEmptyOrNull(descInHindi) && (
              <span className="text-danger">Field is Required</span>
            )}
          </div>
        </div>
      {/* {actionType !== "editing" && ( */}
        <div className="form-row">
          <div className="col-3">
            Select file to upload (English)
            <span className="text-danger">*</span>
          </div>
          <div className="form-group col">
            <div className="custom-file">
              <input
                type="file"
                name="fileUploadEnglish"
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                onChange={handleEngFileUpload}
                required
              />
            </div>
            {errors.englishFile && !englishFile && (
              <span className="text-danger">{PLEASE_SELECT_FILE}</span>
            )}
          </div>
        </div>
      {/* )}
      {actionType !== "editing" && ( */}
        <div className="form-row">
          <div className="col-3">
            Select file to upload (Marathi)
            <span className="text-danger">*</span>
          </div>
          <div className="form-group col">
            <div className="custom-file">
              <input
                type="file"
                name="fileUploadMarathi"
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                onChange={handleMarathiFileUpload}
                required
              />
            </div>
            {errors.marathiFile && !marathiFile && (
              <span className="text-danger">{PLEASE_SELECT_FILE}</span>
            )}
          </div>
        </div>
        <div className="form-row">
            <div className="col-3">
              Select file to upload (Hindi)
              <span className="text-danger">*</span>
            </div>
            <div className="form-group col">
              <div className="custom-file">
                <input
                  type="file"
                  name="hindiFile"
                  accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.csv"
                  onChange={handleHindiFileUpload}
                  required
                />
              </div>
              {errors.hindiFile && !hindiFile && (
                <span className="text-danger">Please Select File</span>
              )}
            </div>
          </div>
      {/* )} */}

      {actionType === "editing" && (
        <div className="form-row">
          <div className="col-3">View/Download Previous files:</div>
          <div className="form-group col">
            <div className="custom-file">
              <a
                href="# "
                onClick={(e) => {
                  getDownloadLink(e, publishedContentDetails.id, "english");
                }}
              >
                English <i className="fa fa-download"></i>
              </a>
              &nbsp;|&nbsp;
              <a
                href="# "
                onClick={(e) => {
                  getDownloadLink(e, publishedContentDetails.id, "marathi");
                }}
              >
                Marathi <i className="fa fa-download"></i>
              </a>
              &nbsp;|&nbsp;
              <a
                href="# "
                onClick={(e) => {
                  getDownloadLink(e, publishedContentDetails.id, "hindi");
                }}
              >
                Hindi <i className="fa fa-download"></i>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="form-row">
        <div className="col-3">Active</div>
        <div className="col">
          <input
            id="isAdvtActive"
            name="isAdvtActive"
            type="checkbox"
            onChange={(e) => setIsAdvtActive(e.target.checked)}
            checked={isAdvtActive}
          />
        </div>
      </div>
      <p></p>
      <div className="form-row">
        <div className="col-3">Show in Latest update</div>
        <div className="col">
          <input
            id="showInLatestUpdate"
            name="showInLatestUpdate"
            type="checkbox"
            onChange={(e) => setShowInLatestUpdate(e.target.checked)}
            checked={showInLatestUpdate}
          />
        </div>
      </div>
      <p></p>
      {showInLatestUpdate && (
        <div className="form-row">
          <div className="col-3">
            <label htmlFor="fileExpDate">
              File Valid till Date in Latest update
            </label>
            <span className="text-danger">*</span>
          </div>
          <div className="input-group col-2">
            <ReactDatePicker
              showIcon
              selected={fileExpDate}
              onChange={(date) => setFileExpDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              dropdownMode="select"
              customInput={
                <input
                  className={`form-control ${
                    ((isTouched.fileExpDate && isEmptyOrNull(fileExpDate)) ||
                      (errors.fileExpDate && isEmptyOrNull(fileExpDate))) &&
                    "is-invalid"
                  }`}
                  id="fileExpDate"
                  name="fileExpDate"
                  placeholder="DD-MM-YYYY"
                  type="text"
                  required
                  minLength="10"
                  maxLength="10"
                />
              }
            />
            {errors.fileExpDate && isEmptyOrNull(fileExpDate) && (
              <span className="text-danger">{FIELD_REQUIRED}</span>
            )}
          </div>
        </div>
      )}
      <p></p>
      <div className="form-row">
        <div className="col-3">Show in important Notices</div>
        <div className="col">
          <input
            id="showInImportantNotice"
            name="showInImportantNotice"
            checked={showInImpNotice}
            onChange={(e) => setShowInImpNotice(e.target.checked)}
            type="checkbox"
          />
        </div>
      </div>
      <p></p>
      <p></p>
      <div className="form-row">
        <div className="col-sm-12">
          <label htmlFor="remarksText">
            {actionType == "editing" && "Previous "}Remarks:
          </label>
          <textarea
            className="form-control"
            id="remarksText"
            name="remarksText"
            rows="3"
            value={remark}
            onChange={handleRemarkChange}
          ></textarea>
        </div>
      </div>
      <p></p>
      <hr className="dropdown-divider" />
      <p></p>
      <div className="form-row">
        <div className="col-sm-12">
          {(actionType !== "editing" && !isDemo) && (
            <>
              <button className="btn btn-primary" onClick={handleCreateContent}>
                <i className="fa fa-check-circle">&nbsp;</i>Create
              </button>
              &nbsp;{" "}
            </>
          )}
          {actionType === "editing" && (
            <div>
              <button className="btn btn-primary" onClick={handleUpdateClick}>
                <i className="fa fa-save">&nbsp;</i>Save
              </button>
              &nbsp;
              {(isUserReviewer() || isUserApprover() || isUserAdmin()) &&
                publishedContentDetails.status &&
                publishedContentDetails.status !== "APPROVED" && (
                  <button
                    className="btn btn-success"
                    onClick={(e) =>
                      handleApprove(e, publishedContentDetails.id, "Approve")
                    }
                  >
                    <i className="fa fa-times-circle">&nbsp;</i>Approve
                  </button>
                )}
              &nbsp;
              {(isUserReviewer() || isUserApprover() || isUserAdmin()) &&
                publishedContentDetails.status &&
                publishedContentDetails.status !== "APPROVED" && (
                  <button
                    className="btn btn-danger"
                    onClick={(e) =>
                      handleReject(e, publishedContentDetails.id, "Reject")
                    }
                  >
                    <i className="fa fa-ban">&nbsp;</i>Reject
                  </button>
                )}
              &nbsp;
              <button className="btn btn-dark" onClick={handleCancel}>
                <i className="fa fa-times-circle">&nbsp;</i>Close
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ResultContentFormat;
