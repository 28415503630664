import React, { useState } from "react";

const DUMMY_MENU = [
  {
    mid: 25,
    name: "ABOUT MPSC",
    parentId: null,
    route: "about_mpsc",
    desc: null,
    displayOrder: 0,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 1,
    name: "Candidate Information",
    parentId: null,
    route: "candidate_info",
    desc: null,
    displayOrder: 1,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 2,
    name: "IMPORTANT GOVT.ORDERS",
    parentId: null,
    route: "imp_gov_orders",
    desc: null,
    displayOrder: 1,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 71,
    name: "DOWNLOADS",
    parentId: null,
    route: "downloads",
    desc: null,
    displayOrder: 2,
    showInAdminPortal: true,
    createdDate: "2021-04-06 21:22:51",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 3,
    name: "TENDER/PURCHASE REQUISITION",
    parentId: null,
    route: "tender_purchase_req",
    desc: null,
    displayOrder: 3,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 81,
    name: "Advisor Portal",
    parentId: null,
    route: "http://13.127.59.127/mpsconline/public/expertLogin",
    desc: null,
    displayOrder: 3,
    showInAdminPortal: true,
    createdDate: "2022-07-27 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 62,
    name: "FAQ",
    parentId: null,
    route: "faq",
    desc: null,
    displayOrder: 4,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 64,
    name: "CITIZENS CHARTER",
    parentId: null,
    route: "citizens_charter",
    desc: null,
    displayOrder: 6,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 65,
    name: "RIGHT TO SERVICES",
    parentId: null,
    route: "right_to_services",
    desc: null,
    displayOrder: 7,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 66,
    name: "RTI",
    parentId: null,
    route: "rti",
    desc: null,
    displayOrder: 8,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
  {
    mid: 68,
    name: "RELATED LINKS",
    parentId: null,
    route: "relatedlinks",
    desc: null,
    displayOrder: 10,
    showInAdminPortal: true,
    createdDate: "2021-02-28 17:37:43",
    createdBy: null,
    updatedDate: null,
    updatedBy: null,
  },
];

const AppMenuList = ({mainMenuValue, subMenuValue, mainMenuList, subMenuList, subMenuList2, subMenuValue2, handleMainMenu, handleSubMenu, handleSubMenu2}) => {
  

  return (
    <form noValidate>
      <div className="form-row">
        <div className="col-sm-6 form-group">
          <label htmlFor="select-main-menu">Select Main Menu</label>
          <span className="text-danger">*</span>
          <select
            className="form-control"
            id="select-main-menu"
            name="select-main-menu"
            placeholder="choose one"
            required
            value={mainMenuValue}
            onChange={handleMainMenu}
          >
            <option value={""}>Choose one...</option>
            {mainMenuList &&
              mainMenuList.length > 0 &&
              mainMenuList.map((menu, index) => {
                return (
                  <option key={index} value={menu.mid}>
                    {menu.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6 form-group">
          {/* {console.log(mainMenuValue)} */}
          {mainMenuValue &&
            mainMenuValue !== "" &&
            subMenuList &&
            subMenuList.length > 0 && (
              <div>
                <label htmlFor="select-sub-menu">Select Menu</label>
                <span className="text-danger">*</span>
                <select
                  className="form-control"
                  id="select-sub-menu"
                  name="select-sub-menu"
                  placeholder="choose one"
                  required
                  value={subMenuValue}
                  onChange={handleSubMenu}
                >
                  <option value={""}>Choose one...</option>
                  {subMenuList &&
                    subMenuList.length > 0 &&
                    subMenuList.map((menu, index) => {
                      return (
                        <option key={index} value={menu.mid}>
                          {menu.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
        </div>
        <div className="col-sm-6 form-group">
            {subMenuValue &&
              subMenuValue !== "" &&
              subMenuList2 &&
              subMenuList2.length > 0 && (
                <div>
                  <label htmlFor="select-sub-menu">Select Sub Menu</label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-control"
                    id="select-sub-menu"
                    name="select-sub-menu"
                    placeholder="choose one"
                    required
                    value={subMenuValue2}
                    onChange={handleSubMenu2}
                  >
                    <option value={""}>Choose one...</option>
                    {subMenuList2 &&
                      subMenuList2.length > 0 &&
                      subMenuList2.map((menu, index) => {
                        return (
                          <option key={index} value={menu.mid}>
                            {menu.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
          </div>
      </div>
          
    </form>
  );
};

export default AppMenuList;
