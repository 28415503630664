import React, { useEffect, useState } from 'react'
import CommercialsListTable from '../../common/components/CommercialsListTable'
import { Button } from 'react-bootstrap';
import { InputLabel, MenuItem, Select } from '@mui/material';
import AddCommercials from './AddCommercials';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllCommercialsList } from '../actions/manageCommercialsActions';

const ManageHomeImages = () => {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState("list");
    const [selectedCommercialDetails, setSelectedCommercialDetails] = useState('');
    const { allCommercialsList } = useSelector(
      (state) => state.manageCommercialsReducer,
      shallowEqual
    );
  
    // table states
    const [page, setPage] = useState(0);
    
    const handleChangePage = (event, newPage) => {

        
        setPage(newPage);
        dispatch(getAllCommercialsList(newPage, 10));
    
        // selectedMenuItemId !== 0
        //     ? dispatch(getPendingApprovalDetailsById(selectedMenuItemId, newPage))
        //     : dispatch(getAllallMenuList(newPage));
      };

    const handleFormState = (fState, contentId, content) => {
        // fState=='editing'&&dispatch(getMenuDetailsById(contentId, setSelectedMenuDetails));
        // console.log(fState,'fState')
        // fState=='new'&&dispatch(getAllCommercialsList(page, 10));
        formState=='list'&& dispatch(getAllCommercialsList(page, 10));
        setSelectedCommercialDetails(content);
        setFormState(fState);
      };
  useEffect(()=>{
    dispatch(getAllCommercialsList(0,10))
    window.scrollTo(0, 0);

  },[]);
  return (
    <div>
    <p></p>
    <div className="card">
      <div className="card-header">
        <strong>Manage Images</strong>
      </div>
    
      {formState !='create' && formState != 'edit' && <div className="card-body">
        <div className= "row">
            <div className='col-2'>
            {/* <InputLabel id="demo-simple-select-label">Select Image Place</InputLabel>
                <Select autoWidth label="Select Image Place" value={''} >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem>Header</MenuItem>
                    <MenuItem>Home</MenuItem>
                    <MenuItem>Footer</MenuItem>
                </Select> */}
            </div>
            <div className='col'>
                <Button size='sm' 
                style={{float:'right', margin: '5px'}}
                onClick={()=>handleFormState("create")}
                >Add Images</Button>
            </div>
        </div>    
        <CommercialsListTable 
            tableData={
            // []
             (allCommercialsList && allCommercialsList.commercials) && allCommercialsList.commercials
              ? allCommercialsList.commercials
              : []
          }
          totalDataLength={
            // 0
            allCommercialsList && allCommercialsList.totalNumberOfRecords
              ? allCommercialsList.totalNumberOfRecords
              : 0
          }
          page={page}
          handleChangePage={handleChangePage}
          handleFormState={handleFormState}
          isPendingTable={true}
        //   getAllMenuList={getAllMenuList}
        />
    </div>}
    {(formState =='create' || formState == 'edit') && <div className="card-body">
        <AddCommercials 
          handleFormState={handleFormState}
          formState={formState}
          selectedCommercialDetails={selectedCommercialDetails}
          page={page}
          />
        </div>
    }
    <p></p>
    <p></p>
    </div>
</div>
  )
}

export default ManageHomeImages