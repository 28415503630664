// import React from 'react'
import logo from "../../../images/logo.png";
import header from "../../../images/header.png";
import lion from "../../../images/lion.svg";
import '../../adminstyles/adminHeader.css'
import React, { Suspense, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, NavLink, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from "react-redux";

import { SET_SESSION_TIMEOUT } from '../../../common/constants/ActionTypes';
import { useIdleTimer } from 'react-idle-timer';
import { clearSession, clearUserSessionData, getSessionToken, getUserDetails } from '../../auth/sessionManager';
import { SESSION_HEARTBEAT } from "../constants/endpoints";
import { toastr } from "react-redux-toastr";
import API from "../../../helpers/API";
import { logoutUser } from "../actions/adminCommonActions";
const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const logout = ()=>{
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    navigate('/admin/login')
    // handleModelShow()
  
    dispatch({ type: SET_SESSION_TIMEOUT, payload: true });
    // console.log('showmodel1', sessionTimoutModal)
  }
  const handleLogout = () =>{
    // sessionStorage.removeItem('token');
    const user = getUserDetails()
    clearUserSessionData();
    clearSession();
    dispatch(logoutUser(user.userId))
    navigate('/admin/login')
    dispatch({ type: SET_SESSION_TIMEOUT, payload: true });
    
    // console.log('hit')
    // alert('User Session Expired');
  }
  
  const onIdle = () => {
    setState('Idle')
    const isLogin = getSessionToken();
    const isUser = getUserDetails()
    if(isLogin && isUser){
      handleLogout();
    }
  }
  
  const onActive = () => {
    setState('Active')
  }
  
  const onAction = () => {
    setCount(count + 1)
  }
  
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1800_000,
    throttle: 500
  })


  useEffect(() => {
    const intervalId = setInterval(() =>  {
      // console.log('hit interval 2')
      const sessionToken = getSessionToken();
      const isUser = getUserDetails()
      try {
        if(sessionToken && isUser) {
          API.post(SESSION_HEARTBEAT + `${isUser.userId}`, {},  { headers: { 'Authorization': `Bearer ${sessionToken}` } });
        }
      } catch (error) {
        toastr.error("Error", error.message);
      }
    },  5 * 60 * 1000); // 300000 milliseconds = 5 minutes
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)
  
    return () => {
      clearInterval(interval)
    }
  })
  return (
    <>
    {/* <div style={{  display: 'block', background: "#fff",  width: "100%", zIndex: "1" }}>
        <nav style={{  height: "50px"}} className="navbar navbar-expand-lg navbar-dark bg-color-first row"></nav>
        <div className=" row" >
        </div>
      </div> */}
    <div className="main_header position-fixed w-100 z-index-set-10 " >
      
        <div className="container">
          <div className="header_wrap">
            <figure className="lion_img">
              <img src={lion} alt="Head" />
            </figure>
            <figure className="mpsc_txt">
              <img src={header} alt="MPSC" />
            </figure>
            <figure className="logo">
              <img src={logo} alt="Logo" />
            </figure>
          </div>
        </div>
      </div>
      
      </>
  )
}

export default AdminHeader