import API from "../../helpers/API";
import { showLoader, hideLoader } from "../../common/actions/commonActions";
import { GET_MAIN_CONTENT_DATA, GET_MENU_DATA } from "../../common/constants/endpoints";
import { GET_TOTAL_MAIN_CONTENT_DATA } from "../../common/constants/endpoints";
import { SET_MAIN_CONTENT, SET_Office_bearer_CONTENT } from "../../common/constants/ActionTypes";
import { toastr } from "react-redux-toastr";

export const getSortMainContentData = (
  pagenumber,
  pagesize,
  mid,
  isasc,
  label,
  searchkey = ""
) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    try {
      response = await API.get(
        `${GET_MAIN_CONTENT_DATA}/${pagenumber}/${pagesize}?mid=${mid}&isasc=${isasc}&label=${label}&keyword=${searchkey}`,
        {
          headers: "",
        }
      );
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_MAIN_CONTENT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getAllMainContentData = (
  pagenumber,
  pagesize,
  mid,
  stortStatus,
  label,
  searchkey = ""
) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;

    try {
      // response = await API.get(`${GET_MAIN_CONTENT_DATA}/${pagenumber}/${pagesize}?mid=${mid}&isasc=${stortStatus}&label=${label}&keyword=${searchkey}`, {
      //     headers: '',
      // });

      response = await API.get(`${GET_TOTAL_MAIN_CONTENT_DATA}`, {
        headers: "",
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_Office_bearer_CONTENT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getContentDataById = (
  mid=0
) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    try {
      // response = await API.get(`${GET_MAIN_CONTENT_DATA}/${pagenumber}/${pagesize}?mid=${mid}&isasc=${stortStatus}&label=${label}&keyword=${searchkey}`, {
        //     headers: '',
        // });
        
        response = await API.get(`${GET_MENU_DATA}/${mid}`, {
          headers: "",
        });
        if (response != null) {
          dispatch(hideLoader());
        }
        dispatch({
          type: SET_MAIN_CONTENT,
          payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const getSearchData = (
  pagenumber,
  pagesize,
  mid,
  stortStatus,
  label,
  searchkey = ""
) => {
  return async function (dispatch) {
    dispatch(showLoader());
    let response;
    try {
      response = await API.get(
        `${GET_MAIN_CONTENT_DATA}/${pagenumber}/${pagesize}?mid=${mid}&isasc=${stortStatus}&label=${label}&keyword=${searchkey}`,
        {
          headers: "",
        }
      );
      if (response != null) {
        dispatch(hideLoader());
      }
      dispatch({
        type: SET_MAIN_CONTENT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};
